export function seperateSignatureAndContent(text) {
    if (!text.includes('<div id="signature:')) {
        return [text, ''];
    }
    let signatureDivider;
    let reAttachTag;
    [signatureDivider, reAttachTag] = text.includes('<br></p><div id="signature:')
        ? ['<br></p><div id="signature:', '</p>']
        : ['<div id="signature:', ''];
    const sentContent = text.slice(0, Math.max(0, text.indexOf(signatureDivider))) + reAttachTag;
    const sentSignature = text.slice(Math.max(0, text.indexOf('<div id="signature:')));
    return [sentContent, sentSignature];
}

// If signature is signature is applied: return signature object, if not: return null
export function findSignature(caseId, signatureTemplates, isForwarding) {
    const hiddenSignatureName = localStorage.getItem('__caseHiddenSignatureId_' + caseId);
    if (hiddenSignatureName) {
        const hiddenSignature = signatureTemplates.filter((elem) => elem.ID == hiddenSignatureName);
        if (hiddenSignature.length > 0) {
            return hiddenSignature[0];
        }
    }
    const caseStringLocalStorage = isForwarding ? '__caseContentForward_' : '__caseContent_';

    const txt = localStorage.getItem(caseStringLocalStorage + caseId);
    if (txt?.includes('<div id="signature:')) {
        const index1 = txt.indexOf('<div id="signature:') + 19;
        const index2 = txt.indexOf('">', index1);
        const signatureName = txt.substring(index1, index2);
        const visibleSignature = signatureTemplates.filter((elem) => elem.Name == signatureName);
        if (visibleSignature.length > 0) {
            return visibleSignature[0];
        }
    }
    return null;
}
