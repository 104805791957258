module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "presenceIcon": {
          "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
          "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Away"])},
          "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation"])},
          "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
          "busyDontDisturb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busy - Do not disturb"])},
          "busyCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busy in conversation"])},
          "showOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show as Offline"])}
        }
      },
      "sv": {
        "presenceIcon": {
          "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillgänglig"])},
          "away": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borta"])},
          "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ledig"])},
          "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
          "busyDontDisturb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upptagen - Stör ej"])},
          "busyCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upptagen i samtal"])},
          "showOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa som Offline"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"presenceIcon":{"available":"Available","away":"Away","vacation":"Vacation","offline":"Offline","busyDontDisturb":"Busy - Do not disturb","busyCall":"Busy in conversation","showOffline":"Show as Offline"}},"sv":{"presenceIcon":{"available":"Tillgänglig","away":"Borta","vacation":"Ledig","offline":"Offline","busyDontDisturb":"Upptagen - Stör ej","busyCall":"Upptagen i samtal","showOffline":"Visa som Offline"}}}')
  delete Component.options._Ctor
  
}
