import { render, staticRenderFns } from "./CaseActivity.vue?vue&type=template&id=288f372a&scoped=true"
import script from "./CaseActivity.vue?vue&type=script&lang=js"
export * from "./CaseActivity.vue?vue&type=script&lang=js"
import style0 from "./CaseActivity.vue?vue&type=style&index=0&id=288f372a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.96.1__ejs@3.1.10_hand_ebxcklwljnzhsqmemdellbdqly/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "288f372a",
  null
  
)

/* custom blocks */
import block0 from "./CaseActivity.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports