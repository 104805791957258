import ad from '@/assets/Flags/4x3/ad.svg';
import ae from '@/assets/Flags/4x3/ae.svg';
import af from '@/assets/Flags/4x3/af.svg';
import ag from '@/assets/Flags/4x3/ag.svg';
import ai from '@/assets/Flags/4x3/ai.svg';
import al from '@/assets/Flags/4x3/al.svg';
import am from '@/assets/Flags/4x3/am.svg';
import ao from '@/assets/Flags/4x3/ao.svg';
import aq from '@/assets/Flags/4x3/aq.svg';
import ar from '@/assets/Flags/4x3/ar.svg';
import as from '@/assets/Flags/4x3/as.svg';
import at from '@/assets/Flags/4x3/at.svg';
import au from '@/assets/Flags/4x3/au.svg';
import aw from '@/assets/Flags/4x3/aw.svg';
import ax from '@/assets/Flags/4x3/ax.svg';
import az from '@/assets/Flags/4x3/az.svg';
import ba from '@/assets/Flags/4x3/ba.svg';
import bb from '@/assets/Flags/4x3/bb.svg';
import bd from '@/assets/Flags/4x3/bd.svg';
import be from '@/assets/Flags/4x3/be.svg';
import bf from '@/assets/Flags/4x3/bf.svg';
import bg from '@/assets/Flags/4x3/bg.svg';
import bh from '@/assets/Flags/4x3/bh.svg';
import bi from '@/assets/Flags/4x3/bi.svg';
import bj from '@/assets/Flags/4x3/bj.svg';
import bl from '@/assets/Flags/4x3/bl.svg';
import bm from '@/assets/Flags/4x3/bm.svg';
import bn from '@/assets/Flags/4x3/bn.svg';
import bo from '@/assets/Flags/4x3/bo.svg';
import bq from '@/assets/Flags/4x3/bq.svg';
import br from '@/assets/Flags/4x3/br.svg';
import bs from '@/assets/Flags/4x3/bs.svg';
import bt from '@/assets/Flags/4x3/bt.svg';
import bv from '@/assets/Flags/4x3/bv.svg';
import bw from '@/assets/Flags/4x3/bw.svg';
import by from '@/assets/Flags/4x3/by.svg';
import bz from '@/assets/Flags/4x3/bz.svg';
import ca from '@/assets/Flags/4x3/ca.svg';
import cc from '@/assets/Flags/4x3/cc.svg';
import cd from '@/assets/Flags/4x3/cd.svg';
import cf from '@/assets/Flags/4x3/cf.svg';
import cg from '@/assets/Flags/4x3/cg.svg';
import ch from '@/assets/Flags/4x3/ch.svg';
import ci from '@/assets/Flags/4x3/ci.svg';
import ck from '@/assets/Flags/4x3/ck.svg';
import cl from '@/assets/Flags/4x3/cl.svg';
import cm from '@/assets/Flags/4x3/cm.svg';
import cn from '@/assets/Flags/4x3/cn.svg';
import co from '@/assets/Flags/4x3/co.svg';
import cr from '@/assets/Flags/4x3/cr.svg';
import cu from '@/assets/Flags/4x3/cu.svg';
import cv from '@/assets/Flags/4x3/cv.svg';
import cw from '@/assets/Flags/4x3/cw.svg';
import cx from '@/assets/Flags/4x3/cx.svg';
import cy from '@/assets/Flags/4x3/cy.svg';
import cz from '@/assets/Flags/4x3/cz.svg';
import de from '@/assets/Flags/4x3/de.svg';
import dj from '@/assets/Flags/4x3/dj.svg';
import dk from '@/assets/Flags/4x3/dk.svg';
import dm from '@/assets/Flags/4x3/dm.svg';
import do_ from '@/assets/Flags/4x3/do.svg';
import dz from '@/assets/Flags/4x3/dz.svg';
import ec from '@/assets/Flags/4x3/ec.svg';
import ee from '@/assets/Flags/4x3/ee.svg';
import eg from '@/assets/Flags/4x3/eg.svg';
import eh from '@/assets/Flags/4x3/eh.svg';
import en from '@/assets/Flags/4x3/en.svg';
import er from '@/assets/Flags/4x3/er.svg';
import es_ct from '@/assets/Flags/4x3/es-ct.svg';
import es_ga from '@/assets/Flags/4x3/es-ga.svg';
import es from '@/assets/Flags/4x3/es.svg';
import et from '@/assets/Flags/4x3/et.svg';
import eu from '@/assets/Flags/4x3/eu.svg';
import fi from '@/assets/Flags/4x3/fi.svg';
import fj from '@/assets/Flags/4x3/fj.svg';
import fk from '@/assets/Flags/4x3/fk.svg';
import fm from '@/assets/Flags/4x3/fm.svg';
import fo from '@/assets/Flags/4x3/fo.svg';
import fr from '@/assets/Flags/4x3/fr.svg';
import ga from '@/assets/Flags/4x3/ga.svg';
import gb_eng from '@/assets/Flags/4x3/gb-eng.svg';
import gb_nir from '@/assets/Flags/4x3/gb-nir.svg';
import gb_sct from '@/assets/Flags/4x3/gb-sct.svg';
import gb from '@/assets/Flags/4x3/gb.svg';
import gb_wls from '@/assets/Flags/4x3/gb-wls.svg';
import gd from '@/assets/Flags/4x3/gd.svg';
import ge from '@/assets/Flags/4x3/ge.svg';
import gf from '@/assets/Flags/4x3/gf.svg';
import gg from '@/assets/Flags/4x3/gg.svg';
import gh from '@/assets/Flags/4x3/gh.svg';
import gi from '@/assets/Flags/4x3/gi.svg';
import gl from '@/assets/Flags/4x3/gl.svg';
import gm from '@/assets/Flags/4x3/gm.svg';
import gn from '@/assets/Flags/4x3/gn.svg';
import gp from '@/assets/Flags/4x3/gp.svg';
import gq from '@/assets/Flags/4x3/gq.svg';
import gr from '@/assets/Flags/4x3/gr.svg';
import gs from '@/assets/Flags/4x3/gs.svg';
import gt from '@/assets/Flags/4x3/gt.svg';
import gu from '@/assets/Flags/4x3/gu.svg';
import gw from '@/assets/Flags/4x3/gw.svg';
import gy from '@/assets/Flags/4x3/gy.svg';
import hk from '@/assets/Flags/4x3/hk.svg';
import hm from '@/assets/Flags/4x3/hm.svg';
import hn from '@/assets/Flags/4x3/hn.svg';
import hr from '@/assets/Flags/4x3/hr.svg';
import ht from '@/assets/Flags/4x3/ht.svg';
import hu from '@/assets/Flags/4x3/hu.svg';
import id from '@/assets/Flags/4x3/id.svg';
import ie from '@/assets/Flags/4x3/ie.svg';
import il from '@/assets/Flags/4x3/il.svg';
import im from '@/assets/Flags/4x3/im.svg';
import in_ from '@/assets/Flags/4x3/in.svg';
import io from '@/assets/Flags/4x3/io.svg';
import iq from '@/assets/Flags/4x3/iq.svg';
import ir from '@/assets/Flags/4x3/ir.svg';
import is from '@/assets/Flags/4x3/is.svg';
import it from '@/assets/Flags/4x3/it.svg';
import je from '@/assets/Flags/4x3/je.svg';
import jm from '@/assets/Flags/4x3/jm.svg';
import jo from '@/assets/Flags/4x3/jo.svg';
import jp from '@/assets/Flags/4x3/jp.svg';
import ke from '@/assets/Flags/4x3/ke.svg';
import kg from '@/assets/Flags/4x3/kg.svg';
import kh from '@/assets/Flags/4x3/kh.svg';
import ki from '@/assets/Flags/4x3/ki.svg';
import km from '@/assets/Flags/4x3/km.svg';
import kn from '@/assets/Flags/4x3/kn.svg';
import kp from '@/assets/Flags/4x3/kp.svg';
import kr from '@/assets/Flags/4x3/kr.svg';
import kw from '@/assets/Flags/4x3/kw.svg';
import ky from '@/assets/Flags/4x3/ky.svg';
import kz from '@/assets/Flags/4x3/kz.svg';
import la from '@/assets/Flags/4x3/la.svg';
import lb from '@/assets/Flags/4x3/lb.svg';
import lc from '@/assets/Flags/4x3/lc.svg';
import li from '@/assets/Flags/4x3/li.svg';
import lk from '@/assets/Flags/4x3/lk.svg';
import lr from '@/assets/Flags/4x3/lr.svg';
import ls from '@/assets/Flags/4x3/ls.svg';
import lt from '@/assets/Flags/4x3/lt.svg';
import lu from '@/assets/Flags/4x3/lu.svg';
import lv from '@/assets/Flags/4x3/lv.svg';
import ly from '@/assets/Flags/4x3/ly.svg';
import ma from '@/assets/Flags/4x3/ma.svg';
import mc from '@/assets/Flags/4x3/mc.svg';
import md from '@/assets/Flags/4x3/md.svg';
import me from '@/assets/Flags/4x3/me.svg';
import mf from '@/assets/Flags/4x3/mf.svg';
import mg from '@/assets/Flags/4x3/mg.svg';
import mh from '@/assets/Flags/4x3/mh.svg';
import mk from '@/assets/Flags/4x3/mk.svg';
import ml from '@/assets/Flags/4x3/ml.svg';
import mm from '@/assets/Flags/4x3/mm.svg';
import mn from '@/assets/Flags/4x3/mn.svg';
import mo from '@/assets/Flags/4x3/mo.svg';
import mp from '@/assets/Flags/4x3/mp.svg';
import mq from '@/assets/Flags/4x3/mq.svg';
import mr from '@/assets/Flags/4x3/mr.svg';
import ms from '@/assets/Flags/4x3/ms.svg';
import mt from '@/assets/Flags/4x3/mt.svg';
import mu from '@/assets/Flags/4x3/mu.svg';
import mv from '@/assets/Flags/4x3/mv.svg';
import mw from '@/assets/Flags/4x3/mw.svg';
import mx from '@/assets/Flags/4x3/mx.svg';
import my from '@/assets/Flags/4x3/my.svg';
import mz from '@/assets/Flags/4x3/mz.svg';
import na from '@/assets/Flags/4x3/na.svg';
import nc from '@/assets/Flags/4x3/nc.svg';
import ne from '@/assets/Flags/4x3/ne.svg';
import nf from '@/assets/Flags/4x3/nf.svg';
import ng from '@/assets/Flags/4x3/ng.svg';
import ni from '@/assets/Flags/4x3/ni.svg';
import nl from '@/assets/Flags/4x3/nl.svg';
import no from '@/assets/Flags/4x3/no.svg';
import np from '@/assets/Flags/4x3/np.svg';
import nr from '@/assets/Flags/4x3/nr.svg';
import nu from '@/assets/Flags/4x3/nu.svg';
import nz from '@/assets/Flags/4x3/nz.svg';
import om from '@/assets/Flags/4x3/om.svg';
import pa from '@/assets/Flags/4x3/pa.svg';
import pe from '@/assets/Flags/4x3/pe.svg';
import pf from '@/assets/Flags/4x3/pf.svg';
import pg from '@/assets/Flags/4x3/pg.svg';
import ph from '@/assets/Flags/4x3/ph.svg';
import pk from '@/assets/Flags/4x3/pk.svg';
import pl from '@/assets/Flags/4x3/pl.svg';
import pm from '@/assets/Flags/4x3/pm.svg';
import pn from '@/assets/Flags/4x3/pn.svg';
import pr from '@/assets/Flags/4x3/pr.svg';
import ps from '@/assets/Flags/4x3/ps.svg';
import pt from '@/assets/Flags/4x3/pt.svg';
import pw from '@/assets/Flags/4x3/pw.svg';
import py from '@/assets/Flags/4x3/py.svg';
import qa from '@/assets/Flags/4x3/qa.svg';
import re from '@/assets/Flags/4x3/re.svg';
import ro from '@/assets/Flags/4x3/ro.svg';
import rs from '@/assets/Flags/4x3/rs.svg';
import ru from '@/assets/Flags/4x3/ru.svg';
import rw from '@/assets/Flags/4x3/rw.svg';
import sa from '@/assets/Flags/4x3/sa.svg';
import sb from '@/assets/Flags/4x3/sb.svg';
import sc from '@/assets/Flags/4x3/sc.svg';
import sd from '@/assets/Flags/4x3/sd.svg';
import se from '@/assets/Flags/4x3/se.svg';
import sg from '@/assets/Flags/4x3/sg.svg';
import sh from '@/assets/Flags/4x3/sh.svg';
import si from '@/assets/Flags/4x3/si.svg';
import sj from '@/assets/Flags/4x3/sj.svg';
import sk from '@/assets/Flags/4x3/sk.svg';
import sl from '@/assets/Flags/4x3/sl.svg';
import sm from '@/assets/Flags/4x3/sm.svg';
import sn from '@/assets/Flags/4x3/sn.svg';
import so from '@/assets/Flags/4x3/so.svg';
import sr from '@/assets/Flags/4x3/sr.svg';
import ss from '@/assets/Flags/4x3/ss.svg';
import st from '@/assets/Flags/4x3/st.svg';
import sv from '@/assets/Flags/4x3/sv.svg';
import sx from '@/assets/Flags/4x3/sx.svg';
import sy from '@/assets/Flags/4x3/sy.svg';
import sz from '@/assets/Flags/4x3/sz.svg';
import tc from '@/assets/Flags/4x3/tc.svg';
import td from '@/assets/Flags/4x3/td.svg';
import tf from '@/assets/Flags/4x3/tf.svg';
import tg from '@/assets/Flags/4x3/tg.svg';
import th from '@/assets/Flags/4x3/th.svg';
import tj from '@/assets/Flags/4x3/tj.svg';
import tk from '@/assets/Flags/4x3/tk.svg';
import tl from '@/assets/Flags/4x3/tl.svg';
import tm from '@/assets/Flags/4x3/tm.svg';
import tn from '@/assets/Flags/4x3/tn.svg';
import to from '@/assets/Flags/4x3/to.svg';
import tr from '@/assets/Flags/4x3/tr.svg';
import tt from '@/assets/Flags/4x3/tt.svg';
import tv from '@/assets/Flags/4x3/tv.svg';
import tw from '@/assets/Flags/4x3/tw.svg';
import tz from '@/assets/Flags/4x3/tz.svg';
import ua from '@/assets/Flags/4x3/ua.svg';
import ug from '@/assets/Flags/4x3/ug.svg';
import um from '@/assets/Flags/4x3/um.svg';
import un from '@/assets/Flags/4x3/un.svg';
import us from '@/assets/Flags/4x3/us.svg';
import uy from '@/assets/Flags/4x3/uy.svg';
import uz from '@/assets/Flags/4x3/uz.svg';
import va from '@/assets/Flags/4x3/va.svg';
import vc from '@/assets/Flags/4x3/vc.svg';
import ve from '@/assets/Flags/4x3/ve.svg';
import vg from '@/assets/Flags/4x3/vg.svg';
import vi from '@/assets/Flags/4x3/vi.svg';
import vn from '@/assets/Flags/4x3/vn.svg';
import vu from '@/assets/Flags/4x3/vu.svg';
import wf from '@/assets/Flags/4x3/wf.svg';
import ws from '@/assets/Flags/4x3/ws.svg';
import xk from '@/assets/Flags/4x3/xk.svg';
import ye from '@/assets/Flags/4x3/ye.svg';
import yt from '@/assets/Flags/4x3/yt.svg';
import za from '@/assets/Flags/4x3/za.svg';
import zm from '@/assets/Flags/4x3/zm.svg';
import zw from '@/assets/Flags/4x3/zw.svg';

const flags = {
    ad,
    ae,
    af,
    ag,
    ai,
    al,
    am,
    ao,
    aq,
    ar,
    as,
    at,
    au,
    aw,
    ax,
    az,
    ba,
    bb,
    bd,
    be,
    bf,
    bg,
    bh,
    bi,
    bj,
    bl,
    bm,
    bn,
    bo,
    bq,
    br,
    bs,
    bt,
    bv,
    bw,
    by,
    bz,
    ca,
    cc,
    cd,
    cf,
    cg,
    ch,
    ci,
    ck,
    cl,
    cm,
    cn,
    co,
    cr,
    cu,
    cv,
    cw,
    cx,
    cy,
    cz,
    de,
    dj,
    dk,
    dm,
    do: do_,
    dz,
    ec,
    ee,
    eg,
    eh,
    en,
    er,
    'es-ct': es_ct,
    'es-ga': es_ga,
    es,
    et,
    eu,
    fi,
    fj,
    fk,
    fm,
    fo,
    fr,
    ga,
    'gb-eng': gb_eng,
    'gb-nir': gb_nir,
    'gb-sct': gb_sct,
    gb,
    'gb-wls': gb_wls,
    gd,
    ge,
    gf,
    gg,
    gh,
    gi,
    gl,
    gm,
    gn,
    gp,
    gq,
    gr,
    gs,
    gt,
    gu,
    gw,
    gy,
    hk,
    hm,
    hn,
    hr,
    ht,
    hu,
    id,
    ie,
    il,
    im,
    in: in_,
    io,
    iq,
    ir,
    is,
    it,
    je,
    jm,
    jo,
    jp,
    ke,
    kg,
    kh,
    ki,
    km,
    kn,
    kp,
    kr,
    kw,
    ky,
    kz,
    la,
    lb,
    lc,
    li,
    lk,
    lr,
    ls,
    lt,
    lu,
    lv,
    ly,
    ma,
    mc,
    md,
    me,
    mf,
    mg,
    mh,
    mk,
    ml,
    mm,
    mn,
    mo,
    mp,
    mq,
    mr,
    ms,
    mt,
    mu,
    mv,
    mw,
    mx,
    my,
    mz,
    na,
    nc,
    ne,
    nf,
    ng,
    ni,
    nl,
    no,
    np,
    nr,
    nu,
    nz,
    om,
    pa,
    pe,
    pf,
    pg,
    ph,
    pk,
    pl,
    pm,
    pn,
    pr,
    ps,
    pt,
    pw,
    py,
    qa,
    re,
    ro,
    rs,
    ru,
    rw,
    sa,
    sb,
    sc,
    sd,
    se,
    sg,
    sh,
    si,
    sj,
    sk,
    sl,
    sm,
    sn,
    so,
    sr,
    ss,
    st,
    sv,
    sx,
    sy,
    sz,
    tc,
    td,
    tf,
    tg,
    th,
    tj,
    tk,
    tl,
    tm,
    tn,
    to,
    tr,
    tt,
    tv,
    tw,
    tz,
    ua,
    ug,
    um,
    un,
    us,
    uy,
    uz,
    va,
    vc,
    ve,
    vg,
    vi,
    vn,
    vu,
    wf,
    ws,
    xk,
    ye,
    yt,
    za,
    zm,
    zw,
};

/**
 * @param {string} countryCode
 */
export function getFlag(countryCode) {
    return flags[countryCode];
}
