module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "comment": {
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To:"])},
          "cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cc:"])},
          "bcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bcc:"])},
          "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])},
          "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
          "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending"])},
          "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled"])},
          "continueTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will continue to: "])},
          "leaveSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to open the link?"])},
          "tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tries: "])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
          "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
          "retryTooltipFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry sending the email"])},
          "retryMinutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minute"]), _normalize([_interpolate(_named("n")), " minutes"])])},
          "retryTooltipTrying": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Next attempt to send is in ", _interpolate(_named("time")), ", press to retry now"])},
          "retryError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couldn't reset tries, please try again later"])},
          "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couldn't delete the comment, please try again later"])},
          "switchAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch address"])},
          "switchAddressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is marked to be sent from an address that doesnt exist, please choose an new address to send from:"])},
          "showRestOfThread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show rest of the email thread"])},
          "hideRestOfThread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide rest of the email thread"])}
        }
      },
      "sv": {
        "comment": {
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till:"])},
          "cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopia:"])},
          "bcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemlig kopia:"])},
          "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utgående"])},
          "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkommande"])},
          "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skickar"])},
          "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schemalagd"])},
          "continueTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kommer att fortsätta till: "])},
          "leaveSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vill du öppna länken?"])},
          "tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök: "])},
          "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misslyckades"])},
          "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök igen"])},
          "retryTooltipFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försök att skicka e-postmeddelandet igen"])},
          "retryMinutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " minut"]), _normalize([_interpolate(_named("n")), " minuter"])])},
          "retryTooltipTrying": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nästa försök att skicka är om ", _interpolate(_named("time")), ", tryck för att försöka nu"])},
          "retryError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde inte återställa försök, försök igen senare"])},
          "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde inte ta bort kommentaren, försök igen senare"])},
          "switchAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt adress"])},
          "switchAddressText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta e-postmeddelande är vald att skickas från en adress som inte finns, välj en ny adress att skicka från:"])},
          "showRestOfThread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa resten av mail-tråden"])},
          "hideRestOfThread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj resten av mail-tråden"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"comment":{"to":"To:","cc":"Cc:","bcc":"Bcc:","outgoing":"Outgoing","incoming":"Incoming","sending":"Sending","scheduled":"Scheduled","continueTo":"You will continue to: ","leaveSite":"Do you want to open the link?","tries":"Tries: ","failed":"Failed","retry":"Retry","retryTooltipFailed":"Retry sending the email","retryMinutes":"{n} minute | {n} minutes","retryTooltipTrying":"Next attempt to send is in {time}, press to retry now","retryError":"Couldn\u0027t reset tries, please try again later","deleteError":"Couldn\u0027t delete the comment, please try again later","switchAddress":"Switch address","switchAddressText":"This email is marked to be sent from an address that doesnt exist, please choose an new address to send from:","showRestOfThread":"Show rest of the email thread","hideRestOfThread":"Hide rest of the email thread"}},"sv":{"comment":{"to":"Till:","cc":"Kopia:","bcc":"Hemlig kopia:","outgoing":"Utgående","incoming":"Inkommande","sending":"Skickar","scheduled":"Schemalagd","continueTo":"Du kommer att fortsätta till: ","leaveSite":"Vill du öppna länken?","tries":"Försök: ","failed":"Misslyckades","retry":"Försök igen","retryTooltipFailed":"Försök att skicka e-postmeddelandet igen","retryMinutes":"{n} minut | {n} minuter","retryTooltipTrying":"Nästa försök att skicka är om {time}, tryck för att försöka nu","retryError":"Kunde inte återställa försök, försök igen senare","deleteError":"Kunde inte ta bort kommentaren, försök igen senare","switchAddress":"Byt adress","switchAddressText":"Detta e-postmeddelande är vald att skickas från en adress som inte finns, välj en ny adress att skicka från:","showRestOfThread":"Visa resten av mail-tråden","hideRestOfThread":"Dölj resten av mail-tråden"}}}')
  delete Component.options._Ctor
  
}
