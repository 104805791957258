import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500px"},model:{value:(_vm.openState),callback:function ($$v) {_vm.openState=$$v},expression:"openState"}},[_c(VCard,[_c(VCardTitle,[_c('span',[_vm._v(_vm._s(_vm.$t('externalLimeQueues')))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('ExternalQueuesList',{attrs:{"source":"lime"},on:{"close":_vm.close}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }