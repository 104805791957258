<template>
    <button :class="attrs" @click="$emit('click')">
        <i v-if="isStringIcon" class="icon mdi" :class="icon"></i>

        <span v-else-if="$slots.icon" class="icon svg-icon">
            <slot name="icon"></slot>
        </span>

        <span v-if="isTextVisible" class="button-text"><slot></slot></span>
    </button>
</template>

<script>
    export default {
        name: 'NavLink',

        props: {
            dense: {
                type: Boolean,
                default: false,
            },

            icon: {
                type: String,
                default: '',
            },

            isTextVisible: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            attrs() {
                return {
                    dense: this.dense,
                };
            },

            isStringIcon() {
                return typeof this.icon === 'string' && this.icon !== '';
            },
        },
    };
</script>

<style scoped>
    button {
        display: flex;
        align-items: center;
        padding: 8px;
        gap: 16px;
        border-radius: 4px;
        color: var(--v-gray2-darken1);
        background-color: white;
        height: 48px;
        font-size: 16px;
        flex: 1;
        transition:
            background-color 0.2s,
            color 0.2s;
    }

    button:hover {
        background-color: var(--v-gray3-darken1);
        color: var(--v-gray2-darken1);
    }

    button:active {
        background-color: var(--v-gray5-darken3);
        color: black;
    }

    .dense {
        max-height: 40px;
        font-size: 14px;
    }

    .icon {
        font-size: 24px;
        display: flex;
        align-items: center;
    }

    .svg-icon svg {
        fill: currentColor;
        width: 24px;
        height: 24px;
    }

    .button-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
    }
</style>
