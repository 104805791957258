import axios from 'axios';
import DedupeRequest from '../../entity/DedupeRequest';
import helper from '@/helpers/cases/cases.helper';

function startVideoMeeting(payload) {
    return axios.post('/cases/video-meeting', payload);
}

function getCases(q) {
    // Implementing this atm only on search, no not break anything else, to keep scope small
    if (q.query && q.query.length > 0) {
        return axios.get('/v2/cases', {
            params: {
                statuses: q.statuses,
                limit: q.items,
                page: q.page,
                userIds: q.userIds,
                queues: q.queues,
                channels: q.channels,
                categories: q.categories,
                showAnswered: q.showUnansweredOnly ? 0 : 1,
                sortBy: q.sortBy,
                direction: q.direction,
                query: encodeURIComponent(q.query),
                searchType: q.searchType,
            },
        });
    }

    return axios.get(
        `/v3/cases?statuses=${q.statuses}&userIds=${q.userIds}&queues=${q.queues.join(
            ',',
        )}&channels=${q.channels}&categories=${q.categories}&showAnswered=${q.showUnansweredOnly ? 0 : 1}&query=${encodeURIComponent(q.query)}&searchType=${q.searchType}&sortBy=${
            q.sortBy
        }&direction=${q.direction}&limit=${q.items}&page=${q.page}`,
    );
}

function getCasesCount(q) {
    return axios.get(
        `/v3/cases/count?statuses=${q.statuses}&userIds=${q.userIds}&queues=${q.queues.join(',')}&channels=${q.channels}&categories=${q.categories}&showAnswered=${q.showUnansweredOnly ? 0 : 1}&query=${encodeURIComponent(q.query)}&searchType=${q.searchType}`,
    );
}

function setDivert(q) {
    return axios.post('/queue/diversion', q);
}

function getSpecificCase(payload) {
    return axios.get('/comments', {
        params: {
            caseId: payload.caseId,
            commentId: payload.commentId,
            search: payload.search,
            itemsPerPage: payload.itemsPerPage,
            dateTime: payload.dateTime,
            showSystemNotifications: payload.showSystemNotifications,
        },
    });
}

function getCaseAttachments(caseId) {
    return axios.get(`/cases/${caseId}/attachments`);
}

function sendComment(q) {
    return axios.post('/comments', q);
}

function getSystemEmails() {
    return axios.get('/cases/system/emails');
}

function getSystemPhoneNumbers() {
    return axios.get('/sms/numbers');
}

function attempt(q) {
    return axios.post(`/cmot/callbacks/${q[0]}/attempt`, q[1]);
}

const clientCardDedupe = new DedupeRequest((q) => {
    return axios.get(`/client/${q}`);
}, 1000);

function clientCard(q) {
    return clientCardDedupe.send(q);
}
function getAllClients(payload) {
    return axios.get(
        `/client/list/all?itemsPerPage=${payload?.itemsPerPage}&page=${payload?.page}&search=${payload?.search}&getAll=${payload?.getAll}`,
    );
}
function changeCaseOwnerAndClientConnection(payload) {
    return axios.post('/client/change', payload);
}
function changeCaseOwner(payload) {
    return axios.put('/change/client', payload);
}

const getClientContactPersonsDedupe = new DedupeRequest((q) => {
    return axios.get(`/client/${q}/contacts`);
}, 1000);

function getClientContactPersons(id) {
    return getClientContactPersonsDedupe.send(id);
}
function deleteContactPerson(id) {
    return axios.delete(`/client/${id}`);
}

function moveCase(payload) {
    return axios.put('/cases/move', payload);
}

function updateClientInfo(payload) {
    return axios.put(`/client/${payload.id}`, payload.body);
}

function updateCaseFields(q) {
    return axios.put(`/cases/${q.caseId}/fields`, q.data);
}

function updateCase(q) {
    return axios.patch(`/cases/${q.caseId}`, q.changed);
}

function deleteCase(q) {
    return axios.delete(`/cases/${q.caseId}`);
}
function deleteCases(q) {
    return axios.delete('/cases', { data: q });
}

function getCaseTravel(caseId) {
    return axios.get(`/cases/case-travel/${caseId}`);
}

function applyActionToMultipleCases(payload) {
    return axios.put('/cases/apply-multiple', payload);
}

function getTimelineItems({ limit, page, caseId }) {
    return axios.get(`/v2/cases/${caseId}/timeline`, { params: { limit, page } });
}

function search(q) {
    return axios.get(
        `/search?status=${q.status}&limit=${q.items}&page=${q.page}&userId=${q.userId}&queues=${q.queues.join(
            ',',
        )}&channels=${q.channels}&categories=${q.categories}&showAnswered=${q.showUnansweredOnly ? 0 : 1}&sortBy=${
            q.sortBy
        }&direction=${q.direction}&query=${encodeURIComponent(q.searchQuery)}`,
    );
}

// ***** SOAP integration for quicklinks ***** //
function soapGetUserLink(q) {
    return axios.get(`/soap/getPerson/${q.personNr}`);
}

function forwardCaseComments(payload) {
    return axios.post('case/forward', payload);
}

function getCaseByMsgID(payload) {
    return axios.get(`/cases/msgid/${payload.msgId}`, payload);
}

function getActiveCasesByUser() {
    return axios({
        methods: 'get',
        url: '/cases/active/loggedInUser',
    });
}

function getSoundComments(payload) {
    return axios.get(`/cases/sound/comments/${payload}`);
}

function getBackendTime() {
    return axios.get('/cases/queue/time');
}

function getEmailAddresses(payload) {
    return axios.get(`/cases/email/adresses/${payload}`);
}

function searchPhoneNumbers(search) {
    return axios.get('/cases/phone/numbers', { params: { search } });
}

function sendMultiCommuncation(payload) {
    return axios.post('/cases/multi/communication', payload);
}

const getUnreadMessagesByStatusDedupe = new DedupeRequest((payload) => {
    return axios.get(`/cases/unread?status=${payload.status}&page=${payload.page}&search=${payload.search}`);
}, 1000);

function getUnreadMessagesByStatus(payload) {
    return getUnreadMessagesByStatusDedupe.send(payload);
}

function getUnreadMessagesAmountByStatus(status) {
    return axios.get(`/cases/unread?status=${status}&$count`);
}

function createCaseCategory(payload) {
    return axios.post('cases/categories', payload);
}

const getCaseCategoriesDedupe = new DedupeRequest(() => {
    return axios.get('cases/categories');
}, 1000);

function getCaseCategories() {
    return getCaseCategoriesDedupe.send();
}

function editCaseCategory(payload) {
    return axios.put(`cases/categories/${payload.id}`, payload);
}

function deleteCaseCategory(payload) {
    return axios.delete(`cases/categories/${payload.categoryId}`, { data: payload });
}

function replaceCaseCategory(payload) {
    return axios.put('cases/categories', payload);
}

function getIframe({ type, query }) {
    return axios.get(`integrations/${type}/iframe`, { params: query });
}

function getIntegrationIframes() {
    return axios.get('integrations/iframes');
}

function isCaseInQueue(caseId) {
    return axios.get(`/cases/${caseId}/queue`);
}

function updateCaseCategories({ caseId, categoryIds }) {
    return axios.put(`cases/${caseId}/categories`, { categoryIds });
}

function giveWriteAccessToCaseOwner(caseId) {
    return axios.put(`cases/${caseId}/write-access`);
}

function resetUnreadMessages(caseId) {
    return axios.put(`/cases/${caseId}/unread/clear`);
}

function getExternalRelations(caseId) {
    return axios.get(`/cases/${caseId}/external-relations`);
}

/**
 * @returns {import("axios").AxiosResponse<{caseId: string}>}
 */
function getNextCase() {
    return axios.post('/cases/get-next-case');
}

function getOrderIntegrations({ type, caseId }) {
    return axios.get(`integrations/${type}/orders`, { params: { caseId } });
}
function getCallbackNumbersFromCase(caseId) {
    return axios.get(`/cases/${caseId}/callbacks`);
}

function calculateAndUpdateSla(caseId, method) {
    return axios.put(`/cases/${caseId}/sla/${method}`);
}

export default {
    updateCaseCategories,
    getSystemEmails,
    getSystemPhoneNumbers,
    getCaseByMsgID,
    getSpecificCase,
    clientCard,
    getAllClients,
    getClientContactPersons,
    deleteContactPerson,
    changeCaseOwnerAndClientConnection,
    changeCaseOwner,
    updateCase,
    deleteCase,
    deleteCases,
    applyActionToMultipleCases,
    sendComment,
    search,
    soapGetUserLink,
    attempt,
    updateClientInfo,
    forwardCaseComments,
    getActiveCasesByUser,
    getSoundComments,
    moveCase,
    getEmailAddresses,
    searchPhoneNumbers,
    setDivert,
    sendMultiCommuncation,
    getCases,
    getCasesCount,
    getCaseCategories,
    getCaseTravel,
    updateCaseFields,
    getBackendTime,
    getUnreadMessagesByStatus,
    getUnreadMessagesAmountByStatus,
    startVideoMeeting,
    createCaseCategory,
    editCaseCategory,
    deleteCaseCategory,
    replaceCaseCategory,
    getIframe,
    getIntegrationIframes,
    isCaseInQueue,
    giveWriteAccessToCaseOwner,
    resetUnreadMessages,
    getCaseAttachments,
    getExternalRelations,
    getNextCase,
    getOrderIntegrations,
    getCallbackNumbersFromCase,
    getTimelineItems,
    calculateAndUpdateSla,
};
