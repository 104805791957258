import * as Sentry from '@sentry/vue';

import { target as serverName, dev, customerFirstUrl } from '@/app/config';

export const feedback = Sentry.feedbackAsyncIntegration({
    autoInject: false,
    // Additional SDK configuration goes in here, for example:
    colorScheme: 'light',
    showBranding: false,
    isEmailRequired: true,
    isNameRequired: true,
});

function initSentry(Vue, router) {
    let environment = window.location.hostname.replace('.customerfirst.ai', '');

    if (dev) {
        environment = 'local_development';
    }

    const enabled = process.env.NODE_ENV === 'production';

    Sentry.init({
        Vue,
        // sadly this has to be exposed publicly, This is taken from sentry's documentation
        dsn:
            process.env.VUE_SENTRY_DSN ||
            'https://9e8ed91fbae673f040bcfe7fd52beff4@o4506037829500928.ingest.sentry.io/4506038028664832',
        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration(), feedback],
        environment,
        enabled,
        release: 'c1@' + process.env.VUE_APP_VERSION,

        tunnel: customerFirstUrl + 'api/monitor', // This should be changed to a relative path in the future when we move away from the customerFirstUrl
        tracePropagationTargets: ['localhost', /^\//],
        // Performance Monitoring
        tracesSampleRate: 0.05, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

export default initSentry;
