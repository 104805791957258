<template>
    <section class="user-container" :class="userContainerClass">
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x left>
            <template #activator="{ on, attrs }">
                <section class="user-card-container flex-start" v-bind="attrs" v-on="on" @click="menu = !menu">
                    <Avatar :userId="userId" :presenceId="presenceId" />

                    <div class="name-container">
                        <h4>
                            <truncate>{{ user.userName }}</truncate>
                        </h4>
                        <span>
                            <truncate>{{ user.email }}</truncate>
                        </span>
                    </div>
                </section>
            </template>
            <UserMenu :menu="menu" :userQueues="[]" />
        </v-menu>

        <section
            v-if="showActivity"
            class="user-card-container flex-end user-card-container-activity"
            @click="openModal"
        >
            <MyActivity :sidebarOpen="sidebarOpen" />
            <div class="user-card-activity-header">{{ $t('userContainer.myActivities') }}</div>
            <div class="user-card-activity-container">
                <div v-if="unreadMessages > 0" class="user-card-activity" :class="activityClass">
                    {{ formattedUnreadMessages }}
                </div>
            </div>
        </section>
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    import MyActivity from './MyActivity.vue';
    import Avatar from './Avatar.vue';
    import UserMenu from '@/components/Users/UserMenu/UserMenu.vue';

    export default {
        name: 'UserContainer',

        components: {
            Avatar,
            MyActivity,
            UserMenu,
        },

        props: {
            sidebarOpen: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                menu: false,
            };
        },

        computed: {
            ...mapState({
                user: (state) => state.Auth.userObject,
                widgetBuilderState: (state) => state.Widget.widgetBuilderState,
                globalWallboardState: (state) => state.Wallboard.globalWallboardState,
            }),

            ...mapState('ActiveCases', ['unreadMessages']),

            showActivity() {
                return !this.widgetBuilderState && !this.globalWallboardState;
            },

            userId() {
                return this.user.userId;
            },

            presenceId() {
                return this.user.PresenceId;
            },

            formattedUnreadMessages() {
                return this.unreadMessages > 99 ? '99+' : this.unreadMessages;
            },

            activityClass() {
                return {
                    'user-card-fixed-width': this.unreadMessages <= 9,
                };
            },

            userContainerClass() {
                return {
                    'grid-template-rows': this.showActivity ? 'repeat(2, 48px)' : '1fr',
                };
            },
        },

        methods: {
            ...mapActions({
                toggleActionModule: 'Users/toggleActionModule',
            }),
            openModal() {
                this.toggleActionModule();
            },
        },
    };
</script>

<style scoped>
    .user-container {
        background-color: var(--v-gray3-base);
        border-radius: 28px;
        padding: 4px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 4px;
        flex: 1;
        min-width: 56px;
        overflow: hidden;
        user-select: none;
    }

    .user-card-container {
        display: grid;
        grid-template-columns: 40px 1fr;
        gap: 8px;
        transition: background-color 0.2s;
        padding: 4px;
        border-radius: 24px;
    }

    .user-card-container:hover {
        background-color: var(--v-gray3-darken1);
        cursor: pointer;
    }

    .user-card-container:active {
        background-color: var(--v-gray5-darken3);
    }

    .user-card-container > * {
        align-self: center;
    }

    .circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--v-primary-base);
    }

    .flex-start {
        align-self: flex-start;
    }

    .flex-end {
        align-self: flex-end;
    }

    .name-container {
        display: flex;
        flex-direction: column;
    }

    .name-container > h4 {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
    }

    .name-container > span {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: var(--v-gray2-darken1);
    }

    .user-card-container-activity {
        grid-template-columns: 40px 1fr 48px;
    }

    .user-card-fixed-width {
        width: 16px;
    }

    .user-card-activity {
        height: 16px;
        font-size: 12px;
        font-weight: 700;
        color: white;
        border-radius: 16px;
        background-color: var(--v-error-base);
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .user-card-activity-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0px 12px;
    }

    .user-card-activity-header {
        white-space: nowrap;
    }
</style>

<i18n lang="json">
{
    "en": {
        "userContainer": {
            "myActivities": "My Activities"
        }
    },
    "sv": {
        "userContainer": {
            "myActivities": "Mina aktiviteter"
        }
    }
}
</i18n>
