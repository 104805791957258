import router from '@/router';
import api from '@/api/widgets/widgets';

const state = {
    options: Object.freeze({
        colors: 1,
        fonts: 2,
        texts: 3,
        media: 4,
        properties: 5,
        pages: 6,
        misc: 7,
    }),
    objectToEdit: {},
    menuOptions: [],
    fontOptions: [],
    taxi: {
        passengerOptions: [],
        iconOptions: [],
        icons: {},
    },
    mapbox: {
        data: null,
    },
    integrations: {
        mapbox: false,
    },
    widgetSettings: null,
    widgetSettingsDialog: false,
};

const actions = {
    initMenu({ commit, state }) {
        commit('SET_MENU_OPTIONS', [
            {
                id: state.options.colors,
                name: 'Colors',
                icon: 'mdi-palette-outline',
                iconSelected: 'mdi-palette',
                active: false,
            },
            {
                id: state.options.fonts,
                name: 'Fonts',
                icon: 'mdi-format-text-variant',
                iconSelected: 'mdi-format-text-variant',
                active: false,
            },
            {
                id: state.options.texts,
                name: 'Texts',
                icon: 'mdi-text-long',
                iconSelected: 'mdi-text-long',
                active: false,
            },
            {
                id: state.options.media,
                name: 'Media',
                icon: 'mdi-image-outline',
                iconSelected: 'mdi-image',
                active: false,
            },
            {
                id: state.options.properties,
                name: 'Properties',
                icon: 'mdi-text-box-check-outline',
                iconSelected: 'mdi-text-box-check',
                active: false,
            },
            {
                id: state.options.pages,
                name: 'Pages',
                icon: 'mdi-file-document-outline',
                iconSelected: 'mdi-file-document',
                active: false,
            },
            {
                id: state.options.misc,
                name: 'Miscellaneous',
                icon: 'mdi-dots-horizontal',
                iconSelected: 'mdi-dots-horizontal',
                active: false,
            },
        ]);

        commit('SET_FONT_OPTIONS', [
            {
                value: 1,
                name: "'Roboto', sans-serif",
                url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap',
            },
            {
                value: 2,
                name: "'HedvigLetters', sans-serif",
                url: '',
            },
            {
                value: 3,
                name: "'SKRegular', sans-serif",
                url: '',
            },
            {
                value: 4,
                name: "'OpenSans', sans-serif",
                url: '',
            },
        ]);
    },
    async setTaxiOptions({ commit }) {
        const data = {};

        const passengerOptionsMax = 10;
        // Create array with an object that contains a value key for selecting options, goes from 1 to max defined above
        data.passengerOptions = Array.from({ length: passengerOptionsMax })
            .fill(0)
            .reduce((acc, _, i) => {
                acc[i] = {
                    value: i + 1,
                };
                return acc;
            }, []);

        data.iconOptions = [{ value: 'medium-car' }, { value: 'large-car' }];
        data.icons = {
            'medium-car': await api.getBookingIcon('car1'),
            'large-car': await api.getBookingIcon('car2'),
        };

        for (const icon in data.icons) {
            data.icons[icon] = data.icons[icon].data;
        }

        commit('SET_TAXI_OPTIONS', data);
    },
    setObjectToEdit({ dispatch, commit }, object) {
        commit('SET_OBJECT_TO_EDIT', object);
        dispatch('initMenu');
    },
    setIntegration({ commit }, payload) {
        commit('SET_INTEGRATION', payload);
    },
    setWidgetSettings({ commit }, payload) {
        commit('SET_WIDGET_SETTINGS', payload);
    },
    setColor({ commit }, payload) {
        commit('SET_COLOR', {
            group: payload.group,
            display: payload.display,
            value: payload.value,
            name: payload.name,
        });
    },

    setActive({ dispatch, commit, state }, id) {
        switch (id) {
            case -2: {
                router.push('/widgets');
                dispatch('BuilderMenu/setActive', -1, { root: true });
                break;
            }
            case -3: {
                commit('SET_RELATION_ID', '');
                break;
            }

            case -4: {
                commit('TOGGLE_WIDGET_SETTINGS_DIALOG');
                break;
            }

            default: {
                const options = state.menuOptions;
                for (const option of options) {
                    if (option.id === id) {
                        option.active = true;
                    } else {
                        option.active = false;
                    }
                }
                commit('SET_MENU_OPTIONS', options);
            }
        }
    },
    setSelectedFont({ commit }, payload) {
        commit('SET_FONT', {
            group: payload.group,
            keyName: payload.keyName,
            url: payload.url,
            name: payload.name,
        });
    },

    setSelectedImage({ commit }, payload) {
        commit('SET_IMAGE', {
            group: payload.group,
            name: payload.name,
            url: payload.imageUrl,
            imageName: payload.imageName,
        });
    },

    setSelectedIcon({ commit }, payload) {
        commit('SET_ICON', {
            group: payload.group,
            name: payload.name,
            value: payload.value,
        });
    },

    setSelectedText({ commit }, payload) {
        commit('SET_TEXT', {
            group: payload.group,
            name: payload.name,
            text: payload.text,
        });
    },

    setSelectedProperty({ commit }, payload) {
        commit('SET_PROPERTY', {
            group: payload.group,
            name: payload.name,
            value: payload.value,
        });
    },

    setSelectedPage({ commit }, payload) {
        commit('SET_PAGE_SELECTION', payload);
    },

    setSelectedUrl({ commit }, payload) {
        commit('SET_SELECTED_URL', {
            group: payload.group,
            name: payload.name,
            value: payload.value,
        });
    },

    setSelectedArrayProperty({ commit }, payload) {
        commit('SET_SELECTED_ARRAY_PROPERTY', {
            group: payload.group,
            name: payload.name,
            value: payload.value,
        });
    },

    setSelectedIntegerProperty({ commit }, payload) {
        commit('SET_SELECTED_INTEGER_PROPERTY', {
            group: payload.group,
            name: payload.name,
            value: payload.value,
        });
    },

    setRelationId({ commit }, relationId) {
        commit('SET_RELATION_ID', {
            relationId,
        });
    },

    setBookingOptions({ commit }, payload) {
        commit('SET_BOOKING_OPTIONS', payload);
    },

    removeTaxiOption({ state, commit }, index) {
        const optionsArr = state.objectToEdit.misc.booking.options;
        if (index < 0 || index > optionsArr.length - 1) return;
        if (!optionsArr[index]) return;

        commit('REMOVE_TAXI_OPTION', index);
    },

    addTaxiOption({ commit }) {
        const randName = Math.random().toString(36).slice(7);
        const dummy = {
            option: `Taxi ${randName}`,
            passengers: 2,
            icon: 'medium-car',
            price: {
                base: 30,
                perKm: 10,
                perHr: 100,
            },
        };

        commit('ADD_TAXI_OPTION', dummy);
    },

    newTaxiOptions({ commit }, payload) {
        commit('NEW_TAXI_OPTIONS', payload);
    },

    updatePageOrder({ state, commit }, payload) {
        const { value, group } = payload;
        const pages = state.objectToEdit.pages[group];
        const { display } = pages;
        const newPages = {};
        for (const obj of value) {
            newPages[obj.name] = pages[obj.name];
        }
        // Set the display again, since it's not included in the payload
        newPages.display = display;

        commit('UPDATE_PAGE_ORDER', {
            value: newPages,
            group,
        });
    },

    async getBookingBbox({ commit }, data) {
        if (!data || !data.target) return;

        const response = await api.getBookingBbox(data);
        if (response.status !== 200) return;

        const { features } = response.data.data;
        const results = [];
        // TODO: Remove the snake_case from the response
        for (const feature of features) {
            const { place_type, text, bbox } = feature;
            results.push({
                place_type,
                text,
                bbox,
            });
        }

        commit('SET_MAPBOX_DATA', results);
    },
    resetBookingBbox({ commit }) {
        commit('SET_MAPBOX_DATA', null);
    },
    setSelectedBbox({ commit }, payload) {
        commit('SET_SELECTED_BBOX', payload);
    },
    async setEmailConversationOnClose({ commit, state }, payload) {
        await api.updateEmailSettings({
            id: state.widgetSettings.ID,
            emailSender: state.widgetSettings.EmailSender,
            emailConversationOnClose: payload,
        });

        commit('SET_WIDGET_SETTINGS_PROPERTY', {
            property: 'EmailConversationOnClose',
            value: payload,
        });
    },

    async setEmailSender({ commit, state }, payload) {
        await api.updateEmailSettings({
            id: state.widgetSettings.ID,
            emailSender: payload,
            emailConversationOnClose: state.widgetSettings.EmailConversationOnClose,
        });

        commit('SET_WIDGET_SETTINGS_PROPERTY', {
            property: 'EmailSender',
            value: payload,
        });
    },
};

const mutations = {
    SET_RELATION_ID(state, payload) {
        state.objectToEdit.properties.main.relationId = payload.relationId;
    },
    SET_PROPERTY(state, payload) {
        state.objectToEdit.properties[payload.group][payload.name] = payload.value;
    },
    SET_OBJECT_TO_EDIT(state, object) {
        state.objectToEdit = object;
    },
    SET_INTEGRATION(state, payload) {
        state.integrations = payload;
    },
    SET_WIDGET_SETTINGS(state, payload) {
        state.widgetSettings = payload;
    },
    TOGGLE_WIDGET_SETTINGS_DIALOG(state) {
        state.widgetSettingsDialog = !state.widgetSettingsDialog;
    },
    SET_MENU_OPTIONS(state, options) {
        state.menuOptions = options;
    },
    SET_TAXI_OPTIONS(state, options) {
        state.taxi = options;
    },
    SET_COLOR(state, payload) {
        // Somehow, it sets a duplicate of the item.value passed to the color picker, this prevents it from doing that.
        const value = typeof payload.value === 'object' ? payload.value.value : payload.value;
        if (typeof value === 'object') return;

        state.objectToEdit.colors[payload.group][payload.name] = {
            value,
            display: payload.display,
            type: 'color',
        };
    },
    SET_FONT_OPTIONS(state, options) {
        state.fontOptions = options;
    },
    SET_FONT(state, payload) {
        if (payload.keyName === 'undefined') return;
        state.objectToEdit.fonts[payload.group][payload.keyName] = { name: payload.name, url: payload.url };
    },
    SET_IMAGE(state, payload) {
        state.objectToEdit.media.images[payload.group][payload.name] = {
            name: payload.imageName,
            url: payload.url,
        };
    },
    SET_ICON(state, payload) {
        state.objectToEdit.media.icons[payload.group][payload.name] = payload.value;
    },
    SET_TEXT(state, payload) {
        state.objectToEdit.texts[payload.group][payload.name].text = payload.text;
    },
    SET_PAGE_SELECTION(state, payload) {
        state.objectToEdit.pages[payload.group][payload.name] = payload.value;
    },
    SET_SELECTED_URL(state, payload) {
        state.objectToEdit.properties[payload.group][payload.name] = payload.value;
    },
    SET_SELECTED_ARRAY_PROPERTY(state, payload) {
        state.objectToEdit.properties[payload.group][payload.name].find((item) => {
            if (item.type === payload.value) {
                item.selected = true;
                return null;
            }
            item.selected = false;
            return null;
        });
    },
    SET_SELECTED_INTEGER_PROPERTY(state, payload) {
        state.objectToEdit.properties[payload.group][payload.name] = payload.value;
    },
    /**
     *
     * @param {object} payload
     * @param {string} payload.option - String of what key to change on an object, i.e 'passengers'
     * @param {any} payload.data - New value of said key
     * @param {number} payload.id - Index of the option we're mutating
     */
    SET_BOOKING_OPTIONS(state, payload) {
        const { option, data, id } = payload;
        state.objectToEdit.misc.booking.options[id][option] = data;
    },
    REMOVE_TAXI_OPTION(state, index) {
        state.objectToEdit.misc.booking.options.splice(index, 1);
    },
    ADD_TAXI_OPTION(state, payload) {
        state.objectToEdit.misc.booking.options.push(payload);
    },
    NEW_TAXI_OPTIONS(state, payload) {
        state.objectToEdit.misc.booking.options = payload;
    },
    UPDATE_PAGE_ORDER(state, payload) {
        const { group, value } = payload;
        state.objectToEdit.pages[group] = value;
    },
    SET_MAPBOX_DATA(state, payload) {
        state.mapbox.data = payload;
    },
    SET_SELECTED_BBOX(state, payload) {
        state.objectToEdit.misc.mapbox.bbox = payload;
    },

    SET_WIDGET_SETTINGS_PROPERTY(state, payload) {
        state.widgetSettings[payload.property] = payload.value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
