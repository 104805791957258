import chatgpt from '@/api/chatgpt/chatgpt';
import { getSourceContext } from '@/helpers/chatgpt.helper';
import { httpStatusCode } from '@/enums/response.enums';
import { caseTypes } from '@/enums/cases.enums';

import i18n from '../../i18n';

const state = {
    availableContexts: [],
    availablePrompts: [],
    availableInstructions: [],
    promptToEdit: {
        id: 0,
        summary: '',
        prompt: '',
        language: 'sv',
        context: 'cases',
        instructionId: 1,
        dataSourceRef: [],
        functionSourceRef: [],
        includeClientContext: 0,
    },
    isOpen: false,
    mode: 'edit',
    isLoading: false,
    sourceText: '',
    menuItems: [],
    instructions: [],
    instructionToEdit: {
        instructions: 'Write instructions here',
        engine: 'gpt-3.5-turbo',
        id: 0,
        name: 'New instruction',
    },
    instructionsCreateMode: false,
    autoCategorization: {},

    dataSources: [],
    dataSourceFile: [],

    allDataSources: [],

    // Auto Dialog Settings
    autoDialogSettings: {
        active: false,
        promptId: null,
        queueId: 0,
        channel: caseTypes.EMAIL,
    },

    // Functions
    functions: [],
    selectedFunctionToEdit: {},
    property: {
        id: 0,
        name: '',
        value: 'string',
        description: '',
        isRequired: 0,
    },
    availableIntegrations: [],
};

const actions = {
    //* Administration of functions */

    // Properties management in function object
    addProperty({ commit, state }) {
        commit('ADD_PROPERTY', structuredClone(state.property));
    },
    changeProperty({ commit }, payload) {
        commit('CHANGE_PROPERTY', payload);
    },
    removeProperty({ commit }, index) {
        commit('REMOVE_PROPERTY', index);
    },

    // Function administration
    updateFunctionInEditing({ commit }, payload) {
        commit('SET_VALUES_FUNCTION', payload);
    },

    async getAvailableIntegrations({ commit }) {
        try {
            const res = await chatgpt.getAvailableIntegrations();
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }
            commit('SET_AVAILABLE_INTEGRATIONS', res.data.result);
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
            return null;
        }
    },

    async removeFunction({ dispatch }, id) {
        try {
            const res = await chatgpt.removeFunction(id);
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }
            this._vm.$toasted.show(i18n.t('adminStore.deleted'), {
                icon: 'info',
                type: 'success',
            });
            dispatch('getFunctions');
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error, {
                icon: 'info',
                type: 'warn',
            });
            return null;
        }
    },

    /**
     * Retrieves a function by its ID.
     *
     * @param {Object} context - The Vuex store context.
     * @param {number} id - The ID of the function to retrieve.
     * @returns {Promise<Object>} - A promise that resolves to the retrieved function.
     * @throws {Error} - If an error occurs during the retrieval process.
     */
    async getFunction({ commit, dispatch }, id) {
        try {
            const res = await chatgpt.getFunction(id);
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }
            commit('SET_FUNCTION_TO_EDIT', res.data.result);
            dispatch('getAvailableIntegrations');
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
            return null;
        }
    },

    async getFunctions({ commit }) {
        try {
            const res = await chatgpt.getFunctions();
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }
            commit('SET_FUNCTIONS', res.data.result);
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
            return null;
        }
    },
    async saveFunction({ commit, state, dispatch }) {
        try {
            const res = await chatgpt.saveFunction(state.selectedFunctionToEdit);
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }
            this._vm.$toasted.show(i18n.t('adminStore.saved'), {
                icon: 'info',
                type: 'success',
            });

            commit('RESET_SELECTED_FUNCTION');
            dispatch('getFunctions');

            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
            return null;
        }
    },
    //* Queue Channel Auto Answer administration */
    setAiDialogSettings({ commit, state, dispatch }, { key, value }) {
        if (key === 'channel' && value !== state.autoDialogSettings.channel) {
            commit('SET_KEY_SETTINGS', { key: 'promptId', value: null });
            commit('SET_KEY_SETTINGS', { key: 'active', value: false });
            commit('SET_KEY_SETTINGS', { key: 'channel', value });
            // get ai settings from queue channel
            dispatch('getAutoDialogSettings');
            return;
        }
        commit('SET_KEY_SETTINGS', { key, value });
    },

    async activateAIOnChannel({ state }) {
        try {
            const res = await chatgpt.activateAIOnChannel(state.autoDialogSettings);
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }
            this._vm.$toasted.show(i18n.t('adminStore.saved'));
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
            return null;
        }
    },

    async getAutoDialogSettings({ commit, state }) {
        try {
            const { queueId, channel } = state.autoDialogSettings;
            const res = await chatgpt.getAutoDialogSettings(queueId, channel);
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }
            commit('SET_AUTO_DIALOG_SETTINGS', res.data.result);
            return res.data.result;
        } catch (_error) {
            commit('SET_AUTO_DIALOG_SETTINGS', null); // We got an error
            return null;
        }
    },
    //* END Queue Channel Auto Answer administration */
    async getAllDataSources({ commit }) {
        try {
            const res = await chatgpt.getAllDataSources();
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.result);
            }
            commit('SET_ALL_DATA_SOURCES', res.data.result);
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
            throw error;
        }
    },

    async getSources({ commit }) {
        try {
            const res = await chatgpt.getSources();
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }
            commit('SET_DATA_SOURCES', res.data.result);
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
            throw error;
        }
    },

    async saveDataSource({ dispatch, state }) {
        try {
            const formData = new FormData();
            for (const file of state.dataSourceFile) {
                formData.append('file', file);
            }
            await chatgpt.saveSource(formData);
            dispatch('getSources');
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
        }
    },

    async removeDataSource({ dispatch }, payload) {
        try {
            const TIMEOUT_MS = 250;
            await chatgpt.deleteSources(payload.id, payload.fileId);
            await new Promise((resolve) => setTimeout(resolve, TIMEOUT_MS)); // Not sure if correct done, but the list we get back from the server is not updated directly, maybe i should remove it from the dom and not get the truth from backend?
            dispatch('getSources');
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
        }
    },

    setDataSourceFile({ commit, dispatch }, payload) {
        commit('SET_DATA_SOURCE_FILE', payload);
        dispatch('saveDataSource');
    },

    async getAutoCategorization({ commit }) {
        try {
            const res = await chatgpt.getAutoCategorization();
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }
            commit('SET_AUTO_CATEGORIZATION', res.data.result);
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
            throw error;
        }
    },

    async setAutoCategorization({ dispatch, state }) {
        try {
            const res = await chatgpt.saveAutoCategorization(state.autoCategorization);
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }
            this._vm.$toasted.show(i18n.t('adminStore.saved'), {
                icon: 'info',
                type: 'success',
            });
            dispatch('getAutoCategorization');
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
            throw error;
        }
    },

    findInstruction({ commit }, index) {
        commit('SET_INSTRUCTION_TO_EDIT', index);
    },

    createInstruction({ commit }) {
        const createDefault = {
            instructions: 'Write instructions here',
            engine: 'gpt-3.5-turbo',
            id: 0,
            name: 'New instruction',
        };

        commit('PUSH_INSTRUCTION_TO_EDIT', createDefault);
        commit('SET_INSTRUCTION_CREATE_MODE', true);
    },

    async getInstructions({ commit }, payload) {
        try {
            const res = await chatgpt.getInstructions(payload);
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }
            commit('SET_INSTRUCTIONS', res.data.result);
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
            throw error;
        }
    },

    async saveInstruction({ dispatch, commit, state }) {
        try {
            const res = await chatgpt.saveInstruction(state.instructionToEdit);
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }
            this._vm.$toasted.show(i18n.t('adminStore.saved'), {
                icon: 'info',
                type: 'success',
            });
            dispatch('getInstructions');
            commit('SET_INSTRUCTION_CREATE_MODE', false);
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
            throw error;
        }
    },

    async deleteInstruction({ dispatch, commit }, id) {
        try {
            const res = await chatgpt.deleteInstruction(id);
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }
            this._vm.$toasted.show(i18n.t('adminStore.deleted'), {
                icon: 'info',
                type: 'success',
            });
            commit('SET_INSTRUCTION_CREATE_MODE', false);
            dispatch('getInstructions');
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error?.response?.data || error, {
                icon: 'info',
                type: 'warn',
            });
            dispatch('getInstructions');
            commit('SET_INSTRUCTION_CREATE_MODE', false);
            throw error;
        }
    },
    //* * Manage instructions */

    getSource({ rootState, commit }, payload) {
        const source = getSourceContext(payload.context, rootState, payload.commentId, payload.textInput);
        commit('SET_SOURCE_TEXT', source);
    },

    async closePromptDialog({ commit }) {
        const TIMEOUT_MS = 300;
        commit('SET_MODAL_STATE', false);
        await new Promise((resolve) => setTimeout(resolve, TIMEOUT_MS));
        commit('RESET_PROMPT_TO_EDIT');
    },

    editCommandDialog({ dispatch, commit }, id) {
        if (!id) {
            commit('SET_MODE', 'create');
            commit('RESET_PROMPT_TO_EDIT');
            commit('SET_MODAL_STATE', true);
        } else {
            commit('SET_MODE', 'edit');
            dispatch('getSpecificPrompt', id);
        }
    },
    async deletePrompt({ dispatch, state }) {
        try {
            const res = await chatgpt.deletePrompt(state.promptToEdit.id);

            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }

            this._vm.$toasted.show(i18n.t('adminStore.deleted'), {
                icon: 'info',
                type: 'success',
            });
            dispatch('getAvailablePrompts', state.promptToEdit.context);
            dispatch('closePromptDialog');
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error, {
                icon: 'info',
                type: 'warn',
            });
            throw error;
        }
    },

    async savePrompt({ dispatch, state, commit }) {
        try {
            const res = await chatgpt.savePrompt(state.promptToEdit);

            if (res.status !== httpStatusCode.CREATED) {
                throw new Error(res.data.message);
            }

            this._vm.$toasted.show(i18n.t('adminStore.saved'), {
                icon: 'info',
                type: 'success',
            });

            dispatch('getAvailablePrompts', state.promptToEdit.context);
            commit('RESET_PROMPT_TO_EDIT');
            commit('SET_MODAL_STATE', false);
            return res.data.result;
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data && error.response.data.message) {
                this._vm.$toasted.show(error.response.data.message, {
                    icon: 'info',
                    type: 'warn',
                });
            } else {
                this._vm.$toasted.show(error?.response?.data || error, {
                    icon: 'info',
                    type: 'warn',
                });
            }
            throw error;
        }
    },

    async getSpecificPrompt({ commit, dispatch }, payload) {
        try {
            const res = await chatgpt.getSpecificPrompt(payload);

            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }

            commit('EDIT_COMMAND_DIALOG', res.data.result);
            commit('SET_MODAL_STATE', true);
            dispatch('getSources');
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error, {
                icon: 'info',
                type: 'warn',
            });
            throw error;
        }
    },

    async getAllContexts({ commit }) {
        try {
            const res = await chatgpt.getAllContexts();

            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }

            commit('SET_ALL_CONTEXTS', res.data.result);
            return res.data.result;
        } catch (error) {
            this._vm.$toasted.show(error, {
                icon: 'info',
                type: 'warn',
            });
            throw error;
        }
    },

    async getAvailablePrompts({ commit }, context) {
        try {
            const res = await chatgpt.getAvailablePrompts(context);
            if (res.status !== httpStatusCode.OK) {
                throw new Error(res.data.message);
            }

            commit('SET_AVAILABLE_PROMPTS', res.data.result);
            return res.data.result;
        } catch (error) {
            if (error.response.status === 403) {
                return 403;
            }
            this._vm.$toasted.show(error, {
                icon: 'info',
                type: 'warn',
            });
            throw error;
        }
    },

    async command({ dispatch, commit, state }, payload) {
        try {
            commit('TOGGLE_PROMPT_LOADING');

            // Will look into a cleaner solution fir this.
            await dispatch('getSource', payload);
            payload.text = state.sourceText;

            // Workaorund because of the code above (will refactor later)
            delete payload.textInput;

            const res = await chatgpt.command(payload);

            if (res.status !== httpStatusCode.OK) {
                // Here will make the ai icon change instead of toast
                this._vm.$toasted.show(res.data.message, {
                    icon: 'info',
                    type: 'warn',
                });
                commit('TOGGLE_PROMPT_LOADING');
                throw new Error(res.data.message);
            }

            commit('TOGGLE_PROMPT_LOADING');

            // If the context is cases or comment, we want to set the content of the tiptap editor
            if (payload.context === ('cases' || 'comment')) {
                dispatch('setCommentInputField', res.data.result);
            }

            return res.data.result;
        } catch (error) {
            commit('TOGGLE_PROMPT_LOADING');
            throw error;
        }
    },
    setCommentInputField({ dispatch, rootState }, payload) {
        if (rootState.Comments.tiptapState === 'closed') {
            dispatch('Comments/openTiptap', null, { root: true });
        }
        dispatch('Comments/setContentOfTipTapEditor', payload, { root: true });
    },
    SOCKET_GPTpartialTextResponse({ dispatch }, payload) {
        dispatch('Comments/setContentOfTipTapEditorPartial', payload, { root: true });
    },

    setModalState({ commit }, val) {
        commit('SET_MODAL_STATE', val);
    },
};
// Mutations
const mutations = {
    // * Functions
    SET_AVAILABLE_INTEGRATIONS(state, integrations) {
        state.availableIntegrations = integrations;
    },
    SET_VALUES_FUNCTION(state, payload) {
        state.selectedFunctionToEdit[payload.key] = payload.value;
    },
    SET_FUNCTIONS(state, functions) {
        state.functions = functions;
    },
    SET_FUNCTION_TO_EDIT(state, payload) {
        state.selectedFunctionToEdit = payload;
    },
    RESET_SELECTED_FUNCTION(state) {
        state.selectedFunctionToEdit = {
            name: '',
            type: '',
            description: '',
            properties: [],
            integrations: [],
        };
    },

    // Properties management if function object
    CHANGE_PROPERTY(state, { key, value, index }) {
        state.selectedFunctionToEdit.properties[index][key] = value;
    },
    ADD_PROPERTY(state, value) {
        state.selectedFunctionToEdit.properties.push(value);
    },
    REMOVE_PROPERTY(state, index) {
        state.selectedFunctionToEdit.properties.splice(index, 1);
    },

    /* Queue Channel Auto Answer administration */
    SET_AUTO_DIALOG_SETTINGS(state, settings) {
        state.autoDialogSettings = settings;
    },
    SET_KEY_SETTINGS(state, { key, value }) {
        state.autoDialogSettings[key] = value;
    },

    SET_ALL_DATA_SOURCES(state, dataSources) {
        state.allDataSources = dataSources;
    },
    SET_DATA_SOURCE_FILE(state, file) {
        state.dataSourceFile = file;
    },
    SET_DATA_SOURCES(state, dataSources) {
        state.dataSources = dataSources;
    },

    SET_AUTO_CATEGORIZATION(state, value) {
        state.autoCategorization = value;
    },

    SET_INSTRUCTION_CREATE_MODE(state, value) {
        state.instructionsCreateMode = value;
    },

    PUSH_INSTRUCTION_TO_EDIT(state, instruction) {
        instruction.id = state.instructions.at(-1).id + 1; // Get lastvalue and add 1
        state.instructions.push(instruction);
        state.instructionToEdit = instruction;
    },

    SET_INSTRUCTION_TO_EDIT(state, instruction) {
        state.instructionToEdit = state.instructions.find((_, index) => index === instruction);
    },

    SET_INSTRUCTIONS(state, instructions) {
        state.instructions = instructions;
        state.availableInstructions = instructions;
    },

    SET_SOURCE_TEXT(state, text) {
        state.sourceText = text;
    },

    TOGGLE_PROMPT_LOADING(state) {
        state.isLoading = !state.isLoading;
    },

    SET_AVAILABLE_PROMPTS(state, prompts) {
        state.availablePrompts = prompts;
        state.menuItems = prompts.map((item) => {
            return {
                value: item.id,
                ...item,
            };
        });
    },

    SET_MODE(state, mode) {
        state.mode = mode;
    },

    RESET_PROMPT_TO_EDIT(state) {
        state.promptToEdit = {
            id: 0,
            summary: '',
            prompt: '',
            language: 'sv',
            context: 'cases',
            instructionId: 1,
            dataSourceRef: [],
            functionSourceRef: [],
            includeClientContext: 0,
        };
    },

    SET_MODAL_STATE(state, isOpen) {
        state.isOpen = isOpen;
    },

    SET_ALL_CONTEXTS(state, contexts) {
        state.availableContexts = contexts;
    },

    EDIT_COMMAND_DIALOG(state, payload) {
        state.promptToEdit = payload;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
