<template>
    <section class="avatar-container">
        <CommonAvatar :size="40" :userId="userId" />
        <div class="presence-icon">
            <PresenceIcon :size="12" :presenceId="presenceId" />
        </div>
    </section>
</template>

<script>
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    import PresenceIcon from '@/components/Global/PresenceIcon.vue';

    export default {
        name: 'Avatar',

        components: {
            CommonAvatar,
            PresenceIcon,
        },

        props: {
            userId: {
                type: Number,
                default: 0,
            },

            presenceId: {
                type: Number,
                default: 0,
            },
        },
    };
</script>

<style scoped>
    .avatar-container {
        position: relative;
        width: 40px;
        height: 40px;
    }

    .presence-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 12px;
        height: 12px;

        border: 1px solid var(--v-gray3-base);
        border-radius: 50%;
    }
</style>
