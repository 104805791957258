<template>
    <section class="my-activity-container">
        <v-progress-circular :rotate="270" :size="40" :width="4" :value="getItemsCapacity()" color="black" />
        <v-progress-circular
            :rotate="180"
            :size="40"
            :width="4"
            :value="100"
            color="black"
            class="my-activity-progress"
        />

        <div class="icon-container">
            <div v-if="showUnreadMessages && !sidebarOpen" class="my-activity-unreadmessages"></div>
            <i class="icon mdi mdi-format-list-bulleted"></i>
        </div>
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    export default {
        name: 'MyActivity',

        props: {
            sidebarOpen: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isTabActive: true,
            };
        },

        computed: {
            ...mapState('ActiveCases', ['activeCapacity', 'unreadMessages']),
            ...mapState('Users', ['userActionModuleOpen']),

            totalPercentage() {
                return this.activeCapacity?.totalPercentage;
            },

            items() {
                return this.activeCapacity?.items;
            },

            numberOfItems() {
                return this.activeCapacity?.items?.length;
            },

            showUnreadMessages() {
                return this.unreadMessages > 0;
            },
        },

        sockets: {
            connect() {
                this.getActiveCapacity();
            },
        },

        created() {
            this.getActiveCapacity();
            this.getActiveUnreadMessages();
            document.addEventListener('visibilitychange', this.handleVisibilityChange);
        },

        beforeDestroy() {
            document.removeEventListener('visibilitychange', this.handleVisibilityChange);
            this.unsubscribeFromActiveDialogs();
        },

        methods: {
            ...mapActions({
                getActiveUnreadMessages: 'ActiveCases/getActiveUnreadMessages',
                getActiveCapacity: 'ActiveCases/getActiveCapacity',
                unsubscribeFromActiveDialogs: 'ActiveCases/unsubscribeFromActiveDialogs',
            }),

            getRotation(item) {
                const currentItemIndex = this.items.indexOf(item);
                const previousItems = this.items.slice(0, currentItemIndex);
                const previousItemsCapacity = previousItems.reduce((acc, item) => acc + item.capacityPercentage, 0);
                return previousItemsCapacity * 3.6;
            },

            getItemsCapacity() {
                if (!this.items) {
                    return 0;
                }
                return this.items.reduce((acc, item) => acc + item.capacityPercentage, 0);
            },

            getCapacity(item) {
                return item.capacityPercentage;
            },

            handleVisibilityChange() {
                this.isTabActive = !document.hidden;

                if (this.isTabActive) {
                    this.getActiveCapacity();
                }
            },
        },
    };
</script>

<style scoped>
    .v-progress-circular {
        position: absolute;
        top: 0;
        left: 0;
    }

    .my-activity-container {
        position: relative;
        display: flex;
        width: 40px;
        height: 40px;
    }

    .my-activity-progress {
        opacity: 0.1;
        z-index: 0;
    }

    .icon-container {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon {
        font-size: 20px;
        display: flex;
        align-items: center;
    }

    .my-activity-unreadmessages {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--v-error-base);
        top: 22px;
        right: 10px;
        outline: 2px solid var(--v-primary-lighten5);
    }
</style>
