export const customerURL = 'madhat.customerfirst.se';
export const tapiURL = 'madhat.customerfirst.se';
export const PORT = '443';
export const params = {
    integrationLinks: {
        active: false,
        links: [
            {
                name: 'M3',
                color: 'blue',
                link: 'M3',
                icon: 'mdi-link',
            },
        ],
    },
};
