import zendesk from '@/assets/General/Integrations/zendesk.svg';
import salesforce from '@/assets/General/Integrations/salesforce.svg';
import dynamics365 from '@/assets/General/Integrations/dynamics365.svg';
import fortnox from '@/assets/General/Integrations/fortnox.svg';
import servicenow from '@/assets/General/Integrations/servicenow.svg';
import lime from '@/assets/General/Integrations/lime.svg';
import outlook from '@/assets/General/outlook_icon_img.png';
import gmail from '@/assets/General/gmail_icon_img.png';
import exchange from '@/assets/General/exchange_icon_img.png';
import chatGPT from '@/assets/General/ChatGPT_logo.png';
import whisper from '@/assets/General/chat-gpt-open-ai.png';
import compodium from '@/assets/General/compodium_logo.jpeg';
import brilliant from '@/assets/General/brilliant_logo.svg';
import bankid from '@/assets/General/BankID_logo.svg';
import dedu from '@/assets/General/dedu_logo.png';
import microsoft from '@/assets/FederatedLogin/Icons/microsoft.svg';
import microsoftTTS from '@/assets/General/Azure-Speech.png';
import s2 from '@/assets/General/Integrations/S2_logo.png';
import freeswitch from '@/assets/General/FreeSWITCH_logo.png';
import sinch from '@/assets/General/Sinch_logo.svg';
import swyx from '@/assets/General/Swyx_logo.png';
import inet from '@/assets/General/inet_icon_logo.svg';
import intersport from '@/assets/General/intersport_logo.png';
import pindeliver from '@/assets/General/Integrations/pindeliver_logo.png';
import c1Icon from '@/assets/General/C1_icon.png';
import algolia from '@/assets/General/Integrations/algolia.jpg';

const getImage = (integrationName) => {
    switch (integrationName.toLowerCase()) {
        case 'zendesk': {
            return zendesk;
        }
        case 'salesforce': {
            return salesforce;
        }
        case 'dynamics365': {
            return dynamics365;
        }
        case 'fortnox': {
            return fortnox;
        }
        case 'servicenow': {
            return servicenow;
        }
        case 'lime': {
            return lime;
        }
        case 'outlook': {
            return outlook;
        }
        case 'gmail': {
            return gmail;
        }
        case 'exchange': {
            return exchange;
        }

        case 'chatgpt': {
            return chatGPT;
        }
        case 'whisper': {
            return whisper;
        }
        case 'compodium': {
            return compodium;
        }
        case 'brilliant': {
            return brilliant;
        }
        case 'bankid': {
            return bankid;
        }
        case 'dedu': {
            return dedu;
        }
        case 'microsoft': {
            return microsoft;
        }
        case 'microsoft-tts': {
            return microsoftTTS;
        }
        case 's2': {
            return s2;
        }
        case 'freeswitch': {
            return freeswitch;
        }
        case 'sinch': {
            return sinch;
        }
        case 'swyx': {
            return swyx;
        }
        case 'inet': {
            return inet;
        }
        case 'inet order search': {
            return inet;
        }
        case 'algolia search': {
            return algolia;
        }
        case 'intersport': {
            return intersport;
        }
        case 'pindeliver': {
            return pindeliver;
        }
        default: {
            return c1Icon;
        }
    }
};

const copyToClipboard = async (str) => {
    try {
        str = str.slice(2);
        const b = '-';
        str = [str.slice(0, 6), b, str.slice(6)].join('');
        const isIE = !!document.documentMode; /* @cc_on!@ */
        if (isIE) {
            copyForIE(str);
        } else {
            await normalCopy(str);
        }
    } catch (error) {
        console.error('Failed to copy to clipboard', error);
    }
};

const normalCopy = (str) =>
    new Promise((resolve, reject) => {
        navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
            if (result.state === 'granted' || result.state === 'prompt') {
                navigator.clipboard.writeText(str).then(
                    () => {
                        resolve();
                    },
                    () => {
                        reject();
                    },
                );
            }
        });
    });

const copyForIE = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.append(el);
    el.select();
    document.execCommand('copy');
    el.remove();
};

export { copyToClipboard, getImage };
