module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "clientCard": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not load client card, please try to reload the page"])},
          "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client not found"])},
          "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide client card"])},
          "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bind case to another client"])}
        },
        "sidebarExpander": {
          "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
          "webRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web recording"])},
          "mobPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile preview"])},
          "pdfExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF export"])},
          "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other cases"])},
          "fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields"])},
          "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline"])},
          "iframe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External system"])},
          "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order list"])}
        },
        "actionButtonLabels": {
          "cases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cases"])},
          "clientFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client fields"])}
        }
      },
      "sv": {
        "clientCard": {
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde inte ladda klientkortet, vänligen försök att ladda om sidan"])},
          "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klienten kunde inte hittas"])},
          "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj klientkort"])},
          "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppla ärende till annan klient"])}
        },
        "sidebarExpander": {
          "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallar"])},
          "webRecording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webinspelning"])},
          "mobPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil förhandsvisning"])},
          "pdfExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF Export"])},
          "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatur"])},
          "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andra ärenden"])},
          "fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fält"])},
          "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidslinje"])},
          "iframe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extern system"])},
          "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orderlista"])}
        },
        "actionButtonLabels": {
          "cases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ärenden"])},
          "clientFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klientfält"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"clientCard":{"error":"Could not load client card, please try to reload the page","notFound":"Client not found","hide":"Hide client card","connect":"Bind case to another client"},"sidebarExpander":{"templates":"Templates","webRecording":"Web recording","mobPreview":"Mobile preview","pdfExport":"PDF export","signature":"Signature","default":"Other cases","fields":"Fields","timeline":"Timeline","iframe":"External system","orders":"Order list"},"actionButtonLabels":{"cases":"Cases","clientFields":"Client fields"}},"sv":{"clientCard":{"error":"Kunde inte ladda klientkortet, vänligen försök att ladda om sidan","notFound":"Klienten kunde inte hittas","hide":"Dölj klientkort","connect":"Koppla ärende till annan klient"},"sidebarExpander":{"templates":"Mallar","webRecording":"Webinspelning","mobPreview":"Mobil förhandsvisning","pdfExport":"PDF Export","signature":"Signatur","default":"Andra ärenden","fields":"Fält","timeline":"Tidslinje","iframe":"Extern system","orders":"Orderlista"},"actionButtonLabels":{"cases":"Ärenden","clientFields":"Klientfält"}}}')
  delete Component.options._Ctor
  
}
