export const params = {
    integrationLinks: {
        active: false,
        links: [],
    },
    sideBarExternal: {
        active: true,
        links: [
            {
                href: 'https://tm3.s2crm.com/net/login/default.aspx?redirect=1&q=390&lang=SE',
                icon: 'mdi-link-variant',
                title: {
                    sv: 'S2',
                    en: 'S2',
                },
            },
        ],
    },
};
