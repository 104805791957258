export const emailRegEx = /^[^ @]+@[^ @]+\.[^ @]+$/; // (no space or @)@(no spcae or @).(no space or @)
export const domainRegEx = /^[^ @]+\.[^ @]+$/; // (no space or @).(no space or @)

export function validateEmail(email) {
    return emailRegEx.test(email);
}

export function validateDomain(domain) {
    return domainRegEx.test(domain);
}
