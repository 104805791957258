module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "userMenu": {
          "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands"])},
          "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
          "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
          "showNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show notifications"])},
          "chooseTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose time"])},
          "changePictureTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to change your profile picture"])},
          "divertError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while fetching divert options. Please try again later."])}
        }
      },
      "sv": {
        "userMenu": {
          "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands"])},
          "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hänvisning"])},
          "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])},
          "showNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa notiser"])},
          "chooseTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj tid"])},
          "changePictureTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicka för att ändra din profilbild"])},
          "divertError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi lyckades inte hämta hänsvisningar just nu. Försök igen senare."])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"userMenu":{"brands":"Brands","reference":"Reference","logIn":"Log in","showNotifications":"Show notifications","chooseTime":"Choose time","changePictureTooltip":"Click to change your profile picture","divertError":"An error occurred while fetching divert options. Please try again later."}},"sv":{"userMenu":{"brands":"Brands","reference":"Hänvisning","logIn":"Logga in","showNotifications":"Visa notiser","chooseTime":"Välj tid","changePictureTooltip":"Klicka för att ändra din profilbild","divertError":"Vi lyckades inte hämta hänsvisningar just nu. Försök igen senare."}}}')
  delete Component.options._Ctor
  
}
