<template>
    <PopupMenu
        v-model="newLang"
        :state="currentSelectedLang"
        :heading="$t('chooseLanguage')"
        :items="langs"
        displayText="lang"
        closeOnSelect
        width="24"
    >
        <template #button>
            <v-tooltip top>
                <template #activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        small
                        color="white"
                        elevation="0"
                        class="pa-2 mx-0 mb-1 softShadow d-flex align-center justify-center no-uppercase"
                        style="cursor: pointer"
                        v-on="on"
                    >
                        <v-img :style="{ maxHeight: '16px', maxWidth: '24px' }" :src="getIcon($root.$i18n.locale)" />
                        <p class="pa-0 ma-0 ml-2" style="font-size: 12px">{{ $t($root.$i18n.locale) }}</p>
                    </v-btn>
                </template>
                <span>{{ $t('chooseLanguage') }}</span>
            </v-tooltip>
        </template>
        <template #default="{ item }">
            <div class="d-flex flex-row align-center">
                <v-img :style="{ maxHeight: '16px', maxWidth: '24px' }" class="mr-2" :src="getIcon(item.value)" />
                <span style="font-size: 12px">{{ $t(item.value) }}</span>
            </div>
        </template>
    </PopupMenu>
</template>
<script>
    import { mapState } from 'vuex';
    import { getFlag } from '@/utils/flags';

    export default {
        components: {
            PopupMenu: () => import('@/components/Cases/Navigation/PopupMenu.vue'),
        },

        data() {
            return {
                langs: [{ value: 'sv' }, { value: 'en' }],
                newLang: '',
            };
        },
        watch: {
            async newLang(newVal) {
                this.$root.$i18n.locale = newVal;
                await this.updateUserLang(newVal);
                window.location.reload();
            },
        },

        computed: {
            ...mapState({
                loggedInUser: (state) => state.Auth.userObject,
            }),
            currentSelectedLang() {
                return [this.langs.find((lang) => lang.value === this.$root.$i18n.locale)];
            },
        },
        methods: {
            getIcon(lang) {
                return getFlag(lang);
            },
            async updateUserLang(lang) {
                if (lang == null) return;
                const payload = {
                    userId: this.loggedInUser.userId,
                    data: { table: 'Lang', value: lang },
                };
                await this.$store.dispatch('Users/updateUserPresence', payload);
                this.loggedInUser.Lang = lang;
            },
        },
    };
</script>
<style scoped lang="scss">
    .no-uppercase {
        text-transform: unset !important;
    }
</style>
<i18n lang="json">
{
    "en": {
        "sv": "Swedish",
        "en": "English",
        "chooseLanguage": "Select language"
    },
    "sv": {
        "sv": "Svenska",
        "en": "Engelska",
        "chooseLanguage": "Välj språk"
    }
}
</i18n>
