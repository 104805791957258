import { render, staticRenderFns } from "./IntegrationPindeliveryButton.vue?vue&type=template&id=d11db232"
import script from "./IntegrationPindeliveryButton.vue?vue&type=script&lang=js"
export * from "./IntegrationPindeliveryButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.96.1__ejs@3.1.10_hand_ebxcklwljnzhsqmemdellbdqly/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports