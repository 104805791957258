module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "activeInExternalSystem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Handled in ", _interpolate(_named("v"))])}
      },
      "sv": {
        "activeInExternalSystem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hanteras i ", _interpolate(_named("v"))])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"activeInExternalSystem":"Handled in {v}"},"sv":{"activeInExternalSystem":"Hanteras i {v}"}}')
  delete Component.options._Ctor
  
}
