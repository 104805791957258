module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "externalTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External System"])},
        "externalQueueForwardBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External forward back to C1"])},
        "externalQueueForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External forward to external queue"])}
      },
      "sv": {
        "externalTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externt system"])},
        "externalQueueForwardBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extern vidarebefordran tillbaka i C1"])},
        "externalQueueForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extern vidarebefordran till externt kö"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"externalTopic":"External System","externalQueueForwardBack":"External forward back to C1","externalQueueForward":"External forward to external queue"},"sv":{"externalTopic":"Externt system","externalQueueForwardBack":"Extern vidarebefordran tillbaka i C1","externalQueueForward":"Extern vidarebefordran till externt kö"}}')
  delete Component.options._Ctor
  
}
