export const params = {
    integrationLinks: {
        active: true,
        links: [
            {
                name: 'M3',
                color: 'blue',
                link: 'M3',
                icon: 'mdi-link',
            },
        ],
    },
};
