<template>
    <div v-if="clientId" class="virtual-container">
        <infinite-list
            :key="clientId"
            :headers="[]"
            :fetchItems="getTimelineClients"
            :items="clientTimeline"
            :fetchProps="{ id: clientId }"
            :limit="20"
            :emptyHeader="$t('timeLine.emptyListHeader')"
            :emptyMessage="$t('timeLine.emptyList')"
            emptyIcon="mdi-account-group"
        >
            <template #item="{ item }">
                <TimelineItem :item="item" />
            </template>

            <template #divider="{ item }">
                <div v-if="itemIsFirstOfMonth(item)" class="month-container">
                    <div class="month-text">{{ getMonthByNumber(new Date(item.dateTime).getMonth() + 1) }}</div>
                </div>
            </template>
        </infinite-list>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import TimelineItem from '@/components/Cases/TimelineItem.vue';
    import { getMonthByNumber } from '@/utils/DateFormatter';

    export default {
        components: {
            TimelineItem,
        },
        props: {
            headers: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                colors: this.$vuetify.theme.themes.light,
                verticalHeight: '480px',
                numberOfItems: 7,
                itemHeight: 80,
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight,
                selectedItem: '',
                clientId: null,
                caseId: null,
            };
        },

        computed: {
            ...mapState({
                selectedCase: (state) => state.Cases.selectedCase,
                timeline: (state) => state.Cases.caseTimeline,
                caseToLoad: (state) => state.Cases.caseToLoad,
                currentSelectedClient: (state) => state.Client.currentSelectedClient,
                timelineItems: (state) => state.Cases.timelineItems,
                clientTimeline: (state) => state.Client.clientTimeline,
            }),
        },

        watch: {
            currentSelectedClient() {
                this.clientId = this.currentSelectedClient.clientId;
            },
        },

        mounted() {
            this.clientId = this.currentSelectedClient?.clientId;
        },

        methods: {
            ...mapActions({
                getTimelineClients: 'Client/getTimeline',
                getTimelineItems: 'Cases/getTimelineItems',
            }),
            getMonthByNumber,
            itemIsFirstOfMonth(item) {
                const itemDate = new Date(item.dateTime);
                const currentDate = new Date();

                if (
                    itemDate.getMonth() >= currentDate.getMonth() &&
                    itemDate.getFullYear() === currentDate.getFullYear()
                ) {
                    return false;
                }

                const firstOfMonthItem = this.clientTimeline.find(
                    (i) =>
                        new Date(i.dateTime).getMonth() === itemDate.getMonth() &&
                        new Date(i.dateTime).getFullYear() === itemDate.getFullYear()
                );

                return item.id === firstOfMonthItem.id;
            },
        },
    };
</script>
<style scoped lang="scss">
    .month-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid rgb(0, 0, 0, 0.15);
        height: 30px;
    }
    .month-text {
        font-size: 12px;
        font-weight: 400;
        color: var(--v-gray1-base);
    }
    .loader-container {
        background-color: white;
        height: 100%;
    }
    .virtual-container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1;
    }
</style>
<i18n lang="json">
{
    "en": {
        "timeLine": {
            "dateTime": "Date / Time",
            "emptyList": "No cases",
            "emptyListHeader": "No cases"
        }
    },
    "sv": {
        "timeLine": {
            "dateTime": "Datum / Tid",
            "emptyList": "Inga ärenden",
            "emptyListHeader": "Inga ärenden"
        }
    }
}
</i18n>
