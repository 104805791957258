module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "assignExternally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign externally"])},
        "noQueuesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No queues available"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "somethingWentWrong": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Something went wrong when fetching ", _interpolate(_named("source")), " queues. Please try again later."])},
        "assignExternallyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign externally"])},
        "assignExternallyText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to assign the case to the queue: ", _interpolate(_named("queue")), "? Doing this will disable the case in C1 and must be handled in ", _interpolate(_named("source")), "."])},
        "assignExternallyConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
        "assignExternallyDecline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "notExistingCase": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This case is not related to ", _interpolate(_named("source"))])},
        "createCase": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create case in ", _interpolate(_named("source"))])}
      },
      "sv": {
        "assignExternally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilldela externt"])},
        "noQueuesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga köer tillgängliga"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara"])},
        "somethingWentWrong": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Något gick fel vid hämtning av ", _interpolate(_named("source")), "-köerna. Försök igen senare."])},
        "assignExternallyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilldela externt"])},
        "assignExternallyText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Är du säker på att du vill tilldela ärendet till kön: ", _interpolate(_named("queue")), "? Detta kommer att inaktivera ärendet i C1 och måste hanteras i ", _interpolate(_named("source")), "."])},
        "assignExternallyConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilldela"])},
        "assignExternallyDecline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
        "notExistingCase": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Detta ärende är inte relaterat till ", _interpolate(_named("source"))])},
        "createCase": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skapa ärende i ", _interpolate(_named("source"))])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"assignExternally":"Assign externally","noQueuesAvailable":"No queues available","save":"Save","somethingWentWrong":"Something went wrong when fetching {source} queues. Please try again later.","assignExternallyTitle":"Assign externally","assignExternallyText":"Are you sure you want to assign the case to the queue: {queue}? Doing this will disable the case in C1 and must be handled in {source}.","assignExternallyConfirm":"Assign","assignExternallyDecline":"Cancel","notExistingCase":"This case is not related to {source}","createCase":"Create case in {source}"},"sv":{"assignExternally":"Tilldela externt","noQueuesAvailable":"Inga köer tillgängliga","save":"Spara","somethingWentWrong":"Något gick fel vid hämtning av {source}-köerna. Försök igen senare.","assignExternallyTitle":"Tilldela externt","assignExternallyText":"Är du säker på att du vill tilldela ärendet till kön: {queue}? Detta kommer att inaktivera ärendet i C1 och måste hanteras i {source}.","assignExternallyConfirm":"Tilldela","assignExternallyDecline":"Avbryt","notExistingCase":"Detta ärende är inte relaterat till {source}","createCase":"Skapa ärende i {source}"}}')
  delete Component.options._Ctor
  
}
