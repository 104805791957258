import AdminUsersStore from './administration/users.store.js';
import activeCasesStore from './activeCases.store.js';
import activityStore from './activity.store.js';
import adminStore from './admin.store.js';
import appStore from './app.store.js';
import audienceManagerStore from './audienceManager.store.js';
import authStore from './auth.store.js';
import billingStore from './billing.store.js';
import boardStore from './board.store.js';
import brandsStore from './brands.store.js';
import builderMenuStore from './builderMenu.store.js';
import c1settingsStore from './c1settings.store.js';
import calendarStore from './calendar.store.js';
import callbackAdminStore from './callbackAdmin.store.js';
import callDivertStore from './callDivert.store.js';
import callStore from './call.store.js';
import casesStore from './cases.store.js';
import categoriesStore from './categories.store.js';
import chatgptStore from './chatgpt.store.js';
import chatStore from './chat.store.js';
import clientStore from './client.store.js';
import commentsStore from './comments.store.js';
import companyGroupsStore from './companyGroups.store.js';
import creatorStore from './creator.store.js';
import customerManagementStore from './customerManagement.store.js';
import dynamicStatisticsStore from './dynamicStatistics.store.js';
import emailsStore from './emails.store.js';
import faqStore from './faq.store.js';
import favoritesStore from './favorites.store.js';
import favouritesStore from './favourites.store.js';
import filesStore from './files.store.js';
import filterStore from './filter.store.js';
import forwarderStore from './forwarder.store.js';
import headerStore from './header.store.js';
import integrationsHandlerStore from './integrations.handler.store.js';
import integrationsStore from './integrations.store.js';
import invoiceDocumentsStore from './invoiceDocuments.store.js';
import layoutStore from './layout.store.js';
import leaderboardStore from './leaderboard.store.js';
import loaderStore from './loader.store.js';
import marketingStore from './marketing.store.js';
import meetingsStore from './meetings.store.js';
import notificationStore from './notification.store.js';
import openinghoursAccessStore from './openinghours-access.store.js';
import openinghoursStore from './openinghours.store.js';
import overflowStore from './overflow.store.js';
import promptsStore from './prompts.store.js';
import queueManagerStore from './queueManager.store.js';
import queuesStore from './queues.store.js';
import recordingsStore from './recordings.store.js';
import rolesStore from './roles.store.js';
import rulesStore from './rules.store.js';
import searchStore from './search.store.js';
import sipAuthStore from './sip.auth.store.js';
import sipStore from './sip.store.js';
import smartSearchStore from './smartSearch.store.js';
import smsManagerStore from './smsManager.store.js';
import socialStore from './social.store.js';
import statisticsStore from './statistics.store.js';
import surveysStore from './surveys.store.js';
import systemStore from './system.store.js';
import systemtemplatesStore from './systemtemplates.store.js';
import templatesStore from './templates.store.js';
import topInformationBarStore from './topInformationBar.store.js';
import usersStore from './users.store.js';
import videoMeetingStore from './videoMeeting.store.js';
import wallboardStore from './wallboard.store.js';
import widgetStore from './widget.store.js';

const requireModule = {
    'administration/users.store.js': AdminUsersStore,
    'activeCases.store.js': activeCasesStore,
    'activity.store.js': activityStore,
    'admin.store.js': adminStore,
    'app.store.js': appStore,
    'audienceManager.store.js': audienceManagerStore,
    'auth.store.js': authStore,
    'billing.store.js': billingStore,
    'board.store.js': boardStore,
    'brands.store.js': brandsStore,
    'builderMenu.store.js': builderMenuStore,
    'c1settings.store.js': c1settingsStore,
    'calendar.store.js': calendarStore,
    'callbackAdmin.store.js': callbackAdminStore,
    'callDivert.store.js': callDivertStore,
    'call.store.js': callStore,
    'cases.store.js': casesStore,
    'categories.store.js': categoriesStore,
    'chatgpt.store.js': chatgptStore,
    'chat.store.js': chatStore,
    'client.store.js': clientStore,
    'comments.store.js': commentsStore,
    'companyGroups.store.js': companyGroupsStore,
    'creator.store.js': creatorStore,
    'customerManagement.store.js': customerManagementStore,
    'dynamicStatistics.store.js': dynamicStatisticsStore,
    'emails.store.js': emailsStore,
    'faq.store.js': faqStore,
    'favorites.store.js': favoritesStore,
    'favourites.store.js': favouritesStore,
    'files.store.js': filesStore,
    'filter.store.js': filterStore,
    'forwarder.store.js': forwarderStore,
    'header.store.js': headerStore,
    'integrations.handler.store.js': integrationsHandlerStore,
    'integrations.store.js': integrationsStore,
    'invoiceDocuments.store.js': invoiceDocumentsStore,
    'layout.store.js': layoutStore,
    'leaderboard.store.js': leaderboardStore,
    'loader.store.js': loaderStore,
    'marketing.store.js': marketingStore,
    'meetings.store.js': meetingsStore,
    'notification.store.js': notificationStore,
    'openinghours-access.store.js': openinghoursAccessStore,
    'openinghours.store.js': openinghoursStore,
    'overflow.store.js': overflowStore,
    'prompts.store.js': promptsStore,
    'queueManager.store.js': queueManagerStore,
    'queues.store.js': queuesStore,
    'recordings.store.js': recordingsStore,
    'roles.store.js': rolesStore,
    'rules.store.js': rulesStore,
    'search.store.js': searchStore,
    'sip.auth.store.js': sipAuthStore,
    'sip.store.js': sipStore,
    'smartSearch.store.js': smartSearchStore,
    'smsManager.store.js': smsManagerStore,
    'social.store.js': socialStore,
    'statistics.store.js': statisticsStore,
    'surveys.store.js': surveysStore,
    'system.store.js': systemStore,
    'systemtemplates.store.js': systemtemplatesStore,
    'templates.store.js': templatesStore,
    'topInformationBar.store.js': topInformationBarStore,
    'users.store.js': usersStore,
    'videoMeeting.store.js': videoMeetingStore,
    'wallboard.store.js': wallboardStore,
    'widget.store.js': widgetStore,
};
const modules = {};

function createModulesFromContext(context) {
    for (const filename in context) {
        // Ignore root index file
        if (filename === './index.js') continue;

        // create the module path from filename
        const modulePath = filename.replaceAll(/(\.\/|\.store\.js)/g, '');

        // Recursively get the namespace of the module, considering any subdirectories
        const moduleNamePath = modulePath.split('/').map((name) => name.replace(/^\w/, (c) => c.toUpperCase()));

        // Get module name and module configuration
        const moduleName = moduleNamePath.pop().replace(/^\w/, (c) => c.toUpperCase());
        const moduleConfig = context[filename].default || context[filename];

        // Apply module configuration to the correct namespace, creating namespaces as necessary
        let parentModule = modules;
        for (const name of moduleNamePath) {
            if (!parentModule[name]) {
                parentModule[name] = { namespaced: true, modules: {} };
            }
            parentModule = parentModule[name].modules;
        }
        parentModule[moduleName] = moduleConfig;
    }

    return modules;
}
export default createModulesFromContext(requireModule);
