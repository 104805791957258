import * as aea from './cParams/aea.js';
import * as demo from './cParams/demo.js';
import * as development from './cParams/development.js';
import * as development_lab from './cParams/development_lab.js';
import * as finja from './cParams/finja.js';
import * as hedvig from './cParams/hedvig.js';
import * as husväxeln from './cParams/husväxeln.js';
import * as inet from './cParams/inet.js';
import * as intersport from './cParams/intersport.js';
import * as knugen from './cParams/knugen.js';
import * as labb from './cParams/labb.js';
import * as madhat from './cParams/madhat.js';
import * as nackaenergi from './cParams/nackaenergi.js';
import * as nibe from './cParams/nibe.js';
import * as original from './cParams/original.js';
import * as responda from './cParams/responda.js';
import * as saft from './cParams/saft.js';
import * as skandikon from './cParams/skandikon.js';
import * as skellefteåkraft from './cParams/skellefteåkraft.js';
import * as skellefteataxi from './cParams/skellefteataxi.js';
import * as sophiahemmet from './cParams/sophiahemmet.js';
import * as testdemoserver from './cParams/testdemoserver.js';
import * as vallmo from './cParams/vallmo.js';
import * as vasakronan from './cParams/vasakronan.js';
import * as veteranpoolen from './cParams/veteranpoolen.js';
import * as wecall from './cParams/wecall.js';

const targets = {
    aea: aea,
    demo: demo,
    development: development,
    development_lab: development_lab,
    finja: finja,
    hedvig: hedvig,
    husväxeln: husväxeln,
    inet: inet,
    intersport: intersport,
    knugen: knugen,
    labb: labb,
    madhat: madhat,
    nackaenergi: nackaenergi,
    nibe: nibe,
    original: original,
    responda: responda,
    saft: saft,
    skandikon: skandikon,
    skellefteåkraft: skellefteåkraft,
    skellefteataxi: skellefteataxi,
    sophiahemmet: sophiahemmet,
    testdemoserver: testdemoserver,
    vallmo: vallmo,
    vasakronan: vasakronan,
    veteranpoolen: veteranpoolen,
    wecall: wecall,
};

// target server/customer
export const target = 'husväxeln'; // what configuration to use
export const dev = process.env.NODE_ENV !== 'production'; // Automatically set to production when building for production
const finalTarget = dev ? (typeof dev === 'string' ? dev : 'development') : target.toLowerCase();

const { params } = targets[finalTarget];

// Set the customer URL to the current host, which is hostname + port
const customerURL = window.location.host;

// Set the customer first URL to use the current protocol and the customer URL
export const customerFirstUrl = `${window.location.protocol}//${customerURL}/`; // Must end with / AKA slash;

const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
export const freeSWITCHUrl = `${protocol}://${customerURL}/sip`;

// Same as customerFirstUrl
export const mediaUrl = customerFirstUrl;

export const revision = process.env.VUE_APP_VERSION;

export const swyxTimeOffsetInHours = 4; // hours !OBS -- THIS IS USED TO GET CORRECT TIME FROM SWYX, THIS IS PROBABLY DUE TO A BUG IN SWYX/WRONG IMPLEMENTATION OF TIMEZONE
// the real solution is to fix the offset from swyx, but until then this will have to exist.

export const vuexStrictMode = Boolean(dev); // if dev = 1 use strict mode in vuex, in production this is not recommended

export default {
    customerParams: params,
    customerFirstUrl,
    revision,
    mediaUrl,
    swyxTimeOffsetInHours,
    vuexStrictMode,
    freeSWITCHUrl,
};

export const customerParams = params;
