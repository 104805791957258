<template>
    <section ref="toolbar-container" class="editor-area-inner-container" :style="editorAreaStyle">
        <!-- Show font styles -->
        <div class="file-input">
            <v-file-input
                ref="fileInput"
                v-model="files"
                hide-input
                truncate-length="15"
                multiple
                append-icon="mdi-paperclip"
            />
        </div>
        <section v-if="editor && showFontStyles" class="editor-area-list">
            <article v-for="(item, key) in filteredItems.slice(0, buttonsToShow)" :key="key">
                <v-tooltip bottom>
                    <template #activator="tooltip">
                        <v-menu
                            v-if="item.title == 'Link'"
                            v-model="menuState"
                            :close-on-content-click="false"
                            :nudge-width="300"
                            offset-x
                        >
                            <template #activator="menu">
                                <span v-on="menu.on">
                                    <v-btn
                                        dark
                                        v-bind="tooltip.attrs"
                                        icon
                                        :style="{
                                            color:
                                                selectedText.text &&
                                                selectedText.text.length &&
                                                content.includes(selectedText.text)
                                                    ? 'var(--v-color4-base)'
                                                    : 'var(--v-gray1-base)',
                                        }"
                                        v-on="tooltip.on"
                                    >
                                        <v-icon size="20">{{ item.icon }}</v-icon>
                                    </v-btn>
                                </span>
                            </template>
                            <v-card>
                                <v-text-field
                                    v-model="url"
                                    label="URL"
                                    filled
                                    flat
                                    autofocus
                                    dense
                                    class="pa-1"
                                    hide-details
                                    append-icon="mdi-chevron-right"
                                    @click:append="setLink(url)"
                                    @keypress.prevent.enter="setLink()"
                                />
                            </v-card>
                        </v-menu>
                        <v-menu v-else-if="item.title == 'Size' || item.title == 'FontFamily'" offset-x>
                            <template #activator="menu">
                                <span v-on="menu.on">
                                    <v-btn
                                        icon
                                        v-bind="tooltip.attrs"
                                        :style="{
                                            color: menu.value ? 'var(--v-gray4-base)' : 'var(--v-gray1-base)',
                                        }"
                                        v-on="tooltip.on"
                                    >
                                        <v-icon size="20">{{ item.icon }}</v-icon>
                                    </v-btn>
                                </span>
                            </template>
                            <v-list class="tiptapBtnList">
                                <v-list-item
                                    v-for="(option, index) in item.options"
                                    :key="index"
                                    class="tiptap-button"
                                    @click="item.action(option)"
                                >
                                    {{ option == null ? 'Default' : option }}
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-menu v-else-if="item.title == 'Color'" offset-y top :close-on-content-click="false">
                            <template #activator="menu">
                                <span v-on="menu.on">
                                    <v-btn
                                        icon
                                        v-bind="tooltip.attrs"
                                        :style="{
                                            color: menu.value ? 'var(--v-gray4-base)' : 'var(--v-gray1-base)',
                                        }"
                                        v-on="tooltip.on"
                                    >
                                        <v-icon size="20">{{ item.icon }}</v-icon>
                                    </v-btn>
                                </span>
                            </template>
                            <v-color-picker
                                v-model="colorPickerValue"
                                hide-canvas
                                show-swatches
                                :swatches="item.options"
                            />
                        </v-menu>
                        <v-menu v-else-if="item.title === 'Table options'" offset-y nudge-bottom="20px" bottom>
                            <template #activator="menu">
                                <span v-on="menu.on">
                                    <v-btn icon v-bind="tooltip.attrs" :style="getButtonColor(item)" v-on="tooltip.on">
                                        <v-icon size="20">{{ item.icon }}</v-icon>
                                    </v-btn>
                                </span>
                            </template>

                            <v-card class="d-flex pa-2">
                                <v-tooltip v-for="(option, index) in item.options" :key="index" top>
                                    <template #activator="{ on }">
                                        <v-btn icon v-on="on" @click="option.action">
                                            <v-icon size="20">{{ option.icon }}</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ getTranslatedValue(option.title) }}</span>
                                </v-tooltip>
                            </v-card>
                        </v-menu>
                        <v-btn
                            v-else
                            v-bind="tooltip.attrs"
                            icon
                            :style="
                                item.isActive
                                    ? item.isActive('strike') == true
                                        ? 'color:' + 'var(--v-gray4-base)'
                                        : 'color:' + 'var(--v-gray1-base)'
                                    : 'color:' + 'var(--v-gray4-base)'
                            "
                            v-on="tooltip.on"
                            @click="item.action"
                        >
                            <v-icon size="20">{{ item.icon }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ getTranslatedValue(item.title) }}</span>
                </v-tooltip>
            </article>
            <!-- More items -->
            <article v-if="showDots">
                <v-menu offset-x>
                    <template #activator="{ on }">
                        <v-btn icon color="var(--v-gray1-base)" v-on="on">
                            <v-icon size="20">mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list class="editor-area-list-hidden">
                        <article v-for="(item, key) in hiddenItems" :key="key">
                            <v-tooltip bottom>
                                <template #activator="tooltip">
                                    <v-menu
                                        v-if="item.title == 'Image'"
                                        v-model="toggleUrlInput"
                                        :close-on-content-click="false"
                                        offset-x
                                    >
                                        <template #activator="menu">
                                            <span v-on="menu.on">
                                                <v-btn
                                                    icon
                                                    v-bind="tooltip.attrs"
                                                    :style="{
                                                        color: menu.value
                                                            ? 'var(--v-color4-base)'
                                                            : 'var(--v-gray1-base)',
                                                    }"
                                                    v-on="tooltip.on"
                                                >
                                                    <v-icon size="20">{{ item.icon }}</v-icon>
                                                </v-btn>
                                            </span>
                                        </template>
                                        <v-card>
                                            <v-row
                                                class="imageInput"
                                                no-gutters
                                                @keypress.prevent.enter="item.action(image.url, image.width)"
                                            >
                                                <v-col>
                                                    <v-text-field
                                                        v-model="image.url"
                                                        label="Image URL"
                                                        autofocus
                                                        hide-details
                                                    />
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        v-model="image.width"
                                                        label="Width"
                                                        suffix="px"
                                                        placeholder="Auto"
                                                        hide-details
                                                        class="ma-1"
                                                    />
                                                </v-col>
                                                <v-col cols="1" align-self="center">
                                                    <v-icon size="20" @click="item.action(image.url, image.width)">
                                                        mdi-chevron-right
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-menu>
                                    <v-menu
                                        v-else-if="item.title == 'Link'"
                                        v-model="menuState"
                                        :close-on-content-click="false"
                                        :nudge-width="300"
                                        offset-x
                                    >
                                        <template #activator="menu">
                                            <span v-on="menu.on">
                                                <v-btn
                                                    dark
                                                    v-bind="tooltip.attrs"
                                                    icon
                                                    :style="{
                                                        color:
                                                            selectedText.text &&
                                                            selectedText.text.length &&
                                                            content.includes(selectedText.text)
                                                                ? 'var(--v-color4-base)'
                                                                : 'var(--v-gray1-base)',
                                                    }"
                                                    v-on="tooltip.on"
                                                >
                                                    <v-icon size="20">{{ item.icon }}</v-icon>
                                                </v-btn>
                                            </span>
                                        </template>
                                        <v-card>
                                            <v-text-field
                                                v-model="url"
                                                label="URL"
                                                filled
                                                flat
                                                autofocus
                                                dense
                                                class="pa-1"
                                                hide-details
                                                append-icon="mdi-chevron-right"
                                                @click:append="setLink(url)"
                                                @keypress.prevent.enter="setLink()"
                                            />
                                        </v-card>
                                    </v-menu>
                                    <v-menu v-else-if="item.title == 'Size' || item.title == 'FontFamily'" offset-x>
                                        <template #activator="menu">
                                            <span v-on="menu.on">
                                                <v-btn
                                                    icon
                                                    v-bind="tooltip.attrs"
                                                    :style="{
                                                        color: menu.value
                                                            ? 'var(--v-gray4-base)'
                                                            : 'var(--v-gray1-base)',
                                                    }"
                                                    v-on="tooltip.on"
                                                >
                                                    <v-icon size="20">{{ item.icon }}</v-icon>
                                                </v-btn>
                                            </span>
                                        </template>
                                        <v-list class="tiptapBtnList">
                                            <v-list-item
                                                v-for="(option, index) in item.options"
                                                :key="index"
                                                class="tiptap-button"
                                                @click="item.action(option)"
                                            >
                                                {{ option == null ? 'Default' : option }}
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-menu
                                        v-else-if="item.title == 'Color'"
                                        offset-y
                                        top
                                        :close-on-content-click="false"
                                    >
                                        <template #activator="menu">
                                            <span v-on="menu.on">
                                                <v-btn
                                                    icon
                                                    v-bind="tooltip.attrs"
                                                    :style="{
                                                        color: menu.value
                                                            ? 'var(--v-gray4-base)'
                                                            : 'var(--v-gray1-base)',
                                                    }"
                                                    v-on="tooltip.on"
                                                >
                                                    <v-icon size="20">{{ item.icon }}</v-icon>
                                                </v-btn>
                                            </span>
                                        </template>
                                        <v-color-picker
                                            v-model="colorPickerValue"
                                            hide-canvas
                                            show-swatches
                                            :swatches="item.options"
                                        />
                                    </v-menu>
                                    <v-menu v-else-if="item.title === 'Table options'" offset-y offset-x bottom>
                                        <template #activator="menu">
                                            <span v-on="menu.on">
                                                <v-btn
                                                    icon
                                                    v-bind="tooltip.attrs"
                                                    :style="
                                                        item.isActive
                                                            ? 'color:' + 'var(--v-gray1-base)'
                                                            : 'color:' + 'var(--v-gray4-base)'
                                                    "
                                                    v-on="tooltip.on"
                                                >
                                                    <v-icon size="20">{{ item.icon }}</v-icon>
                                                </v-btn>
                                            </span>
                                        </template>

                                        <v-card class="d-flex pa-2">
                                            <v-tooltip v-for="(option, index) in item.options" :key="index" top>
                                                <template #activator="{ on }">
                                                    <v-btn icon v-on="on" @click="option.action">
                                                        <v-icon size="20">{{ option.icon }}</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ getTranslatedValue(option.title) }}</span>
                                            </v-tooltip>
                                        </v-card>
                                    </v-menu>
                                    <v-btn
                                        v-else
                                        v-bind="tooltip.attrs"
                                        icon
                                        :style="
                                            item.isActive
                                                ? item.isActive('strike') == true
                                                    ? 'color:' + 'var(--v-gray4-base)'
                                                    : 'color:' + 'var(--v-gray1-base)'
                                                : 'color:' + 'var(--v-gray4-base)'
                                        "
                                        v-on="tooltip.on"
                                        @click="item.action"
                                    >
                                        <v-icon size="20">{{ item.icon }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ getTranslatedValue(item.title) }}</span>
                            </v-tooltip>
                        </article>
                    </v-list>
                </v-menu>
            </article>
        </section>

        <!-- Show hide font styles -->
        <section class="toggle-tiptap-toolbar">
            <v-btn icon @click="showFontStyles = !showFontStyles">
                <v-icon size="20">{{ showFontStyles ? 'mdi-minus' : 'mdi-menu' }}</v-icon>
            </v-btn>
        </section>
    </section>
</template>

<script>
    import { useTextSelection } from '@vueuse/core';

    export default {
        props: {
            editor: {
                type: Object,
                default: () => {},
            },

            signatureHasContentAndShowChip: {
                type: Boolean,
                default: false,
            },

            tiptapOptions: {
                type: Object,
                default: () => {},
            },

            content: {
                type: String,
                default: '',
            },

            caseId: {
                type: String,
                default: '',
            },
            activeMenuChoices: {
                type: Array,
                default: () => [],
                required: false,
            },
            enableAttachment: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                selectedText: useTextSelection,

                showFontStyles: false,
                toggleUrlInput: false,
                url: '',
                menuState: false,
                image: {
                    url: '',
                    width: null,
                },
                colorPickerValue: '',
                textColor: 'blue',
                buttonsToShow: 0,
                files: [],

                items: Object.freeze([
                    // Spread in the attachment item only if enabled
                    ...(this.enableAttachment
                        ? [
                              {
                                  icon: 'mdi-attachment',
                                  title: 'Attachment',
                                  action: () => this.triggerFileInput(),
                              },
                          ]
                        : []),
                    {
                        icon: 'mdi-undo',
                        title: 'Undo',
                        action: () => this.editor.chain().focus().undo().run(),
                    },
                    {
                        icon: 'mdi-redo',
                        title: 'Redo',
                        action: () => this.editor.chain().focus().redo().run(),
                    },
                    {
                        icon: 'mdi-link',
                        title: 'Link',
                        action: () => this.openLinkMenu(),
                    },
                    {
                        icon: 'mdi-close',
                        title: 'Remove link',
                        action: () => this.editor.chain().focus().unsetLink().run(),
                        isActive: () => this.editor.isActive('link'),
                    },
                    {
                        icon: 'mdi-format-strikethrough',
                        title: 'Strike',
                        action: () => this.editor.chain().focus().toggleStrike().run(),
                        isActive: () => this.editor.isActive('strike'),
                    },
                    {
                        icon: 'mdi-format-underline',
                        title: 'Underline',
                        action: () => this.editor.chain().focus().toggleUnderline().run(),
                        isActive: () => this.editor.isActive('underline'),
                    },
                    {
                        icon: 'mdi-format-italic',
                        title: 'Italic',
                        action: () => this.editor.chain().focus().toggleItalic().run(),
                        isActive: () => this.editor.isActive('italic'),
                    },
                    {
                        icon: 'mdi-format-list-bulleted',
                        title: 'Bullet List',
                        action: () => this.editor.chain().focus().toggleBulletList().run(),
                        isActive: () => this.editor.isActive('bulletList'),
                    },
                    {
                        icon: 'mdi-format-list-numbered',
                        title: 'Ordered List',
                        action: () => this.editor.chain().focus().toggleOrderedList().run(),
                        isActive: () => this.editor.isActive('orderedList'),
                    },
                    {
                        icon: 'mdi-format-header-1',
                        title: 'Heading 1',
                        action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
                        isActive: () => this.editor.isActive('heading', { level: 1 }),
                    },
                    {
                        icon: 'mdi-format-header-2',
                        title: 'Heading 2',
                        action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
                        isActive: () => this.editor.isActive('heading', { level: 2 }),
                    },
                    {
                        icon: 'mdi-format-header-3',
                        title: 'Heading 3',
                        action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
                        isActive: () => this.editor.isActive('heading', { level: 3 }),
                    },
                    {
                        icon: 'mdi-format-bold',
                        title: 'Bold',
                        action: () => this.editor.chain().focus().toggleBold().run(),
                        isActive: () => this.editor.isActive('bold'),
                    },
                    {
                        icon: 'mdi-code-tags',
                        title: 'Code Block',
                        action: () => this.editor.chain().focus().toggleCodeBlock().run(),
                        isActive: () => this.editor.isActive('codeBlock'),
                    },
                    {
                        icon: 'mdi-minus',
                        title: 'Horizontal Rule',
                        action: () => this.editor.chain().focus().setHorizontalRule().run(),
                    },
                    {
                        icon: 'mdi-table',
                        title: 'Insert table',
                        action: () => {
                            this.editor.chain().focus().insertTable({ rows: 3, cols: 3 }).run();
                        },
                    },
                    {
                        icon: 'mdi-table-edit',
                        title: 'Table options',
                        isActive: () => this.editor.isActive('table'),
                        options: [
                            {
                                icon: 'mdi-table-row-plus-after',
                                title: 'Add row after',
                                action: () => {
                                    this.editor.chain().focus().addRowAfter().run();
                                },
                            },
                            {
                                icon: 'mdi-table-row-plus-before',
                                title: 'Add row before',
                                action: () => {
                                    this.editor.chain().focus().addRowBefore().run();
                                },
                            },
                            {
                                icon: 'mdi-table-row-remove',
                                title: 'Remove row',
                                action: () => {
                                    this.editor.chain().focus().deleteRow().run();
                                },
                            },
                            {
                                icon: 'mdi-table-column-plus-after',
                                title: 'Add column after',
                                action: () => {
                                    this.editor.chain().focus().addColumnAfter().run();
                                },
                            },
                            {
                                icon: 'mdi-table-column-plus-before',
                                title: 'Add column before',
                                action: () => {
                                    this.editor.chain().focus().addColumnBefore().run();
                                },
                            },
                            {
                                icon: 'mdi-table-column-remove',
                                title: 'Remove column',
                                action: () => {
                                    this.editor.chain().focus().deleteColumn().run();
                                },
                            },
                            {
                                title: 'Merge or split cells',
                                icon: 'mdi-table-merge-cells',
                                action: () => {
                                    this.editor.chain().focus().mergeOrSplit().run();
                                },
                            },
                            {
                                icon: 'mdi-table-remove',
                                title: 'Delete table',
                                action: () => {
                                    this.editor.chain().focus().deleteTable().run();
                                },
                            },
                        ],
                    },
                    {
                        icon: 'mdi-format-subscript',
                        title: 'Subscript',
                        action: () => {
                            if (this.editor.isActive('superscript')) {
                                this.editor.chain().focus().toggleSuperscript().run();
                            }
                            this.editor.chain().focus().toggleSubscript().run();
                        },
                        isActive: () => this.editor.isActive('subscript'),
                    },
                    {
                        icon: 'mdi-format-superscript',
                        title: 'Superscript',
                        action: () => {
                            if (this.editor.isActive('subscript')) {
                                this.editor.chain().focus().toggleSubscript().run();
                            }
                            this.editor.chain().focus().toggleSuperscript().run();
                        },
                        isActive: () => this.editor.isActive('superscript'),
                    },
                    {
                        icon: 'mdi-format-size',
                        title: 'Size',
                        action: (size) => {
                            size = size == null ? null : `${size}px`;
                            this.editor.chain().focus().setFontSize(size).run();
                        },
                        options: this.tiptapOptions.size,
                    },
                    {
                        icon: 'mdi-format-font',
                        title: 'FontFamily',
                        action: (family) => {
                            this.editor.chain().focus().setFontFamily(family).run();
                        },
                        options: this.tiptapOptions.fontFamily,
                    },
                    {
                        icon: 'mdi-palette',
                        title: 'Color',
                        action: (color) => {
                            this.editor.commands.setColor(color);
                        },
                        options: this.tiptapOptions.colorPalette,
                    },
                ]),
            };
        },

        computed: {
            editorAreaStyle() {
                if (!this.showFontStyles) {
                    return {
                        position: 'absolute',
                        right: '8px',
                        top: '8px',
                    };
                }
                return {
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                    marginBottom: '4px',
                };
            },

            showDots() {
                return this.filteredItems.length > this.buttonsToShow;
            },

            hiddenItems() {
                return this.filteredItems.slice(this.buttonsToShow);
            },

            itemsSliced() {
                return this.filteredItems.slice(0, this.buttonsToShow);
            },
            // Filter items based on activeMenuChoices, adds the possibility to filter out tools from the toolbar.
            filteredItems() {
                if (this.activeMenuChoices.length === 0) {
                    return this.items;
                }

                return this.items.filter((item) => {
                    return this.activeMenuChoices.includes(item.title);
                });
            },
        },

        watch: {
            colorPickerValue(value) {
                this.editor.commands.setColor(value);
            },

            files(newData) {
                if (!newData.length) {
                    return;
                }
                this.$emit('files', newData);
                // * we clear the files array after emitting the files
                // * this is to be able to upload the same file again
                this.files = [];
            },
        },

        mounted() {
            this.selectedText = useTextSelection();
            this.$nextTick(() => {
                this.createWidthObserver();
            });
        },

        methods: {
            getButtonColor(item) {
                if (item.isActive) {
                    return item.isActive('strike') ? 'color: var(--v-gray4-base)' : 'color: var(--v-gray1-base)';
                }
                return 'color: var(--v-gray4-base)';
            },
            createWidthObserver() {
                const GAP = 8;
                const ITEM_WIDTH = 36;
                const resizeObserver = new ResizeObserver((entries) => {
                    const { width } = entries[0].contentRect;
                    const buttonsToShow = Math.floor((width - GAP) / (ITEM_WIDTH + GAP));
                    this.buttonsToShow = buttonsToShow;
                });
                resizeObserver.observe(this.$refs['toolbar-container']);
            },

            getTranslatedValue(value) {
                return this.$t(`toolbar.${value}`);
            },

            triggerFileInput() {
                this.$refs.fileInput.$refs.input.click();
            },
            setLink() {
                // cancelled
                if (this.url === null) {
                    return;
                }

                // empty
                if (this.url === '') {
                    this.editor.chain().focus().extendMarkRange('link').unsetLink().run();

                    return;
                }
                // update link
                const pattern = /^((http|https|ftp):\/\/)/;

                if (!pattern.test(this.url)) {
                    this.url = 'https://' + this.url;
                }
                this.editor.chain().focus().extendMarkRange('link').setLink({ href: this.url }).run();
                this.url = '';
                this.menu = false;
            },
        },
    };
</script>

<style scoped>
    .toggle-tiptap-toolbar {
        justify-self: flex-end;
    }
    .editor-area-inner-container {
        min-height: 40px;
        display: flex;
        border-radius: 8px;
        padding: 4px;
        background-color: transparent;
        z-index: 99;
        justify-content: space-between;
    }

    .editor-area-list-hidden {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 4px;
        padding: 4px;
    }

    .tiptap-button {
        color: var(--v-gray4-base);
    }

    .editor-area-list {
        display: flex;
        flex-direction: row;
        gap: 4px;
    }

    .signature-content {
        border: 1px solid #eee;
    }

    .signature-chips {
        display: flex;
        align-items: center;
    }

    .file-input {
        display: none;
    }
</style>

<i18n lang="json">
{
    "sv": {
        "toolbar": {
            "Undo": "Ångra",
            "Redo": "Gör om",
            "Link": "Länk",
            "Remove link": "Ta bort länk",
            "Strike": "Genomstruken",
            "Underline": "Understruken",
            "Italic": "Kursiv",
            "Bullet List": "Punktlista",
            "Ordered List": "Numrerad lista",
            "Paragraph": "Paragraf",
            "Heading 1": "Rubrik 1",
            "Heading 2": "Rubrik 2",
            "Heading 3": "Rubrik 3",
            "Bold": "Fetstil",
            "Code Block": "Kodblock",
            "Horizontal Rule": "Horisontell linje",
            "Subscript": "Nedsänkt",
            "Superscript": "Upphöjd",
            "Image": "Bild",
            "Size": "Storlek",
            "FontFamily": "Typsnitt",
            "Color": "Färg",
            "Attachment": "Bifoga fil",
            "Table options": "Tabell",
            "Insert table": "Infoga tabell",
            "Add row after": "Lägg till rad efter",
            "Add row before": "Lägg till rad före",
            "Remove row": "Ta bort rad",
            "Add column after": "Lägg till kolumn efter",
            "Add column before": "Lägg till kolumn före",
            "Remove column": "Ta bort kolumn",
            "Delete table": "Ta bort tabell",
            "Merge or split cells": "Sammanfoga eller dela celler"
        }
    },

    "en": {
        "toolbar": {
            "Undo": "Undo",
            "Redo": "Redo",
            "Link": "Link",
            "Remove link": "Remove link",
            "Strike": "Strike",
            "Underline": "Underline",
            "Italic": "Italic",
            "Bullet List": "Bullet List",
            "Ordered List": "Ordered List",
            "Paragraph": "Paragraph",
            "Heading 1": "Heading 1",
            "Heading 2": "Heading 2",
            "Heading 3": "Heading 3",
            "Bold": "Bold",
            "Code Block": "Code Block",
            "Horizontal Rule": "Horizontal Rule",
            "Subscript": "Subscript",
            "Superscript": "Superscript",
            "Image": "Image",
            "Size": "Size",
            "FontFamily": "FontFamily",
            "Color": "Color",
            "Attachment": "Attachment",
            "Table options": "Table options",
            "Insert table": "Insert table",
            "Add row after": "Add row after",
            "Add row before": "Add row before",
            "Remove row": "Remove row",
            "Add column after": "Add column after",
            "Add column before": "Add column before",
            "Remove column": "Remove column",
            "Delete table": "Delete table",
            "Merge or split cells": "Merge or split cells"
        }
    }
}
</i18n>
