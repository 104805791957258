<template>
    <div>
        <LimeModal ref="limeModal" />
        <IntegrationButton :active="active" :integration-name="integration.name" @click="handleClick" />
    </div>
</template>
<script>
    import IntegrationButton from './IntegrationButton.vue';
    import LimeModal from './LimeModal.vue';

    export default {
        components: {
            IntegrationButton,
            LimeModal,
        },
        props: {
            integration: {
                type: Object,
                required: true,
            },
        },

        computed: {
            active() {
                const caseDisabled = this.$store.state.Cases.selectedCase.case.disabled;
                return Boolean(this.integration.active) && !caseDisabled;
            },
        },
        methods: {
            handleClick() {
                this.$refs.limeModal.open();
            },
        },
    };
</script>
<style scoped lang="scss"></style>
