import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ConfirmationPromiseDialog',{ref:"confirmationDialog"}),_c(VDivider),(_vm.errored)?_c(VSheet,{staticClass:"d-flex align-center justify-center",attrs:{"height":"200px"}},[_vm._v(" "+_vm._s(_vm.$t('somethingWentWrong', { source: _vm.source }))+" ")]):(!_vm.hasExternalRelation)?_c(VSheet,{staticClass:"d-flex flex-column align-center justify-center",attrs:{"height":"200px"}},[_c('div',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('notExistingCase', { source: _vm.source })))]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.createCaseInIntegration}},[_vm._v(_vm._s(_vm.$t('createCase', { source: _vm.source })))])],1):(_vm.loading)?_c(VSheet,{staticClass:"d-flex align-center justify-center",attrs:{"height":"200px"}},[_c(VProgressCircular,{attrs:{"size":"50","indeterminate":"","color":"primary"}})],1):_c(VList,{staticClass:"scroll align-center justify-center",attrs:{"height":"200px"}},[(!_vm.loading)?_c(VListItemGroup,[(!_vm.queues.length)?[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('noQueuesAvailable')))])],1)],1)]:_vm._l((_vm.queues),function(queue,i){return _c(VListItem,{key:i,attrs:{"prepend-icon":queue.id === _vm.selectedQueue?.id ? 'mdi-check' : ''},on:{"click":function($event){return _vm.selectQueue(queue)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(queue.name))])],1)],1)})],2):_vm._e()],1),_c(VDivider),_c('div',{staticClass:"d-flex justify-end align-center pa-2"},[(_vm.selectedQueue)?_c('span',{staticClass:"mr-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t('assignExternally'))+":")]),_vm._v(" "+_vm._s(_vm.selectedQueue.name)+" ")]):_vm._e(),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.selectedQueue},on:{"click":_vm.save}},[_c('span',[_vm._v(_vm._s(_vm.$t('save')))]),_c(VIcon,[_vm._v("mdi-content-save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }