module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "userContainer": {
          "myActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Activities"])}
        }
      },
      "sv": {
        "userContainer": {
          "myActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mina aktiviteter"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"userContainer":{"myActivities":"My Activities"}},"sv":{"userContainer":{"myActivities":"Mina aktiviteter"}}}')
  delete Component.options._Ctor
  
}
