<template>
    <div class="case-with-parent-container">
        <div
            class="case-with-parent-inner text-truncate"
            :class="{ 'case-with-parent-inner-selected': isSelectedCase }"
        >
            <SimpleTooltip :tooltipText="title" top>
                <article class="text-truncate item-heading">{{ title }}</article>
            </SimpleTooltip>
            <article class="item-date">{{ formatDateToText(item.dateTime, locale) }}</article>
        </div>
        <v-icon v-if="isSelectedCase" size="12" color="black" class="arrow-icon">mdi-arrow-right</v-icon>

        <div v-if="isSelectedCase" class="case-with-parent-inner-parent" @click.stop @click="navigateToParentCase">
            <div class="header-date-container text-truncate">
                <article class="chip-timeline">{{ $t('timeLine.parentCase') }}</article>
            </div>
        </div>
    </div>
</template>

<script>
    import { formatDateToText } from '@/utils/DateFormatter';
    import { mapState, mapActions } from 'vuex';

    export default {
        components: {
            SimpleTooltip: () => import('@/components/Global/SimpleTooltip.vue'),
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        computed: {
            ...mapState({
                clientTimeline: (state) => state.Client.clientTimeline,
                selectedCaseId: (state) => state.Cases.selectedCaseId,
            }),
            timelineData() {
                return this.clientTimeline;
            },
            title() {
                return this.item.topic || this.$t('timeLine.noTopic');
            },
            locale() {
                return this.$i18n.locale;
            },
            parentTitle() {
                const parentCase = this.timelineData.find((caseItem) => caseItem.caseId === this.item.parent);
                return parentCase?.topic || this.$t('timeLine.noTopic');
            },
            parentDate() {
                const parentCase = this.timelineData.find((caseItem) => caseItem.caseId === this.item.parent);
                return parentCase?.created ? formatDateToText(new Date(parentCase.created), this.locale) : '';
            },
            isSelectedCase() {
                return this.selectedCaseId === this.item.caseId;
            },
        },
        methods: {
            ...mapActions({
                goToCase: 'Cases/goToCase',
            }),
            formatDateToText,
            goTo(caseId) {
                this.goToCase({ caseId });
            },
            navigateToParentCase() {
                const parentCaseId = this.item.parent || null;

                if (parentCaseId) {
                    this.goToCase({ caseId: parentCaseId });
                } else {
                    console.log('No parent case available.');
                }
            },
        },
    };
</script>
<style>
    .case-with-parent-container {
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        grid-template-rows: 1fr;
        align-items: center;
    }
    .case-with-parent-inner {
        padding: 0 8px;
        grid-area: 1 / 1 / 2 / 12;
    }
    .case-with-parent-inner-parent {
        padding: 0 8px;
        grid-area: 1 / 7 / 2 / 12;
    }

    .case-with-parent-inner-selected {
        grid-area: 1 / 1 / 2 / 6;
    }
    .arrow-icon {
        grid-area: 1 / 6 / 2 / 7;
        align-self: center;
        padding-top: 2px;
        margin: 0 5px;
    }
    .header-date-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .item-heading {
        color: var(--v-gray4-base);
        font-size: 12px;
    }
    .item-date {
        font-size: 10px;
        color: var(--v-gray1-base);
    }

    .chip-timeline {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        padding: 4px 8px;
        margin: 0px 4px;
        border-radius: 4px;
        background-color: var(--v-primary-base);
        color: white;
        border: 1px solid var(--v-primary-base);
        border-radius: 16px;
        cursor: pointer;

        &:hover {
            background-color: white;
            color: var(--v-primary-base);
        }
    }
</style>
<i18n lang="json">
{
    "en": {
        "timeLine": {
            "noTopic": "No topic",
            "parentCase": "Parent case"
        }
    },
    "sv": {
        "timeLine": {
            "noTopic": "Inget ämne",
            "parentCase": "Huvudärende"
        }
    }
}
</i18n>
