import dynamicStatistics from '@/api/dynamicStatistics/dynamicStatistics';
import { mapFilterPayload } from '@/utils/Cases';
import { formatFilters } from '@/utils/DynamicStatistics';
import { keysAreEmpty } from '@/helpers/cases/cases.helper';
import { localStoragePlugin } from '../plugins';
import i18n from '../../i18n';

const state = {
    showComponent: true,
    data: null,
    currentState: 'idle',
    currentStateTimeout: null,
    subState: { value: 'idle' },
    choices: [],
    activeChoice: 0,
    hoveredItem: null,
    curveStatistics: [],
    global: true,
    visible: true,
    currentSubscription: null,
};

const getters = {
    dataItemsEmpty(state) {
        if (!state.data) {
            return false;
        }

        return state.data.filter((item) => item.status !== 'add').length === 0;
    },
};

const actions = {
    SOCKET_changeInDynamicStatistics({ dispatch }, payload) {
        if (payload.change.change !== 0) {
            dispatch('applyChangesToDynamicStatistics', payload);
        }
    },

    applyChangesToDynamicStatistics({ commit, state }, payload) {
        const { change } = payload.change;
        const { dataPoint } = payload.change;

        const newData = structuredClone(state.data);
        newData
            .filter((item) => item.dataPoint === dataPoint)
            .map((item) => {
                item.data += change;
            });

        commit('SET_DATA', newData);
    },

    clearCurveStatistics({ commit }) {
        commit('SET_CURVE_STATISTICS', []);
    },

    async setGlobal({ commit, dispatch }, payload) {
        await dispatch('unSubscribeToWallboard');
        commit('SET_GLOBAL', payload);
        commit('SET_SUB_STATE', { value: 'idle' });
        await dispatch('initDynamicStatistics');
    },

    async initDynamicStatistics({ commit, dispatch }) {
        commit('SET_STATE', { value: 'idle' });
        await dispatch('getDynamicStatistics');
    },
    toggleShowComponent({ commit }) {
        commit('SET_SHOW_COMPONENT');
    },

    async getCurveStatistics({ commit, rootState }, dataPoint) {
        const filter = mapFilterPayload(
            rootState.Cases.caseFiltersState,
            rootState.Cases.searchType,
            rootState.Cases.caseFiltersState,
            rootState.Brands.active,
        );

        const payload = {
            filter,
            dataPoint,
        };

        try {
            const { data } = await dynamicStatistics.getCurveStatistics(payload);
            commit('SET_CURVE_STATISTICS', data);
            return data;
        } catch (error) {
            console.log('Dynamic Statistics -> Get Curve Statistics -> Error:', error);
            return null;
        }
    },

    async getDynamicQueues({ commit, rootState }, dataPoint) {
        const filter = mapFilterPayload(
            rootState.Cases.caseFiltersState,
            rootState.Cases.searchType,
            rootState.Cases.caseFiltersState,
            rootState.Brands.active,
        );

        const payload = {
            filter,
            dataPoint,
            userIds: state.global ? 0 : rootState.Auth.userObject.userId,
        };

        try {
            const result = await dynamicStatistics.getDynamicQueues(payload);
            return result.data;
        } catch (error) {
            console.log('Dynamic Statistics -> Get Queues -> Error:', error);
            return null;
        }
    },

    async getDynamicChoices({ commit, rootState }, payload) {
        const id = global ? 0 : rootState.Auth.userObject.userId;

        try {
            const result = await dynamicStatistics.getDynamicChoices(id);
            const choices = result.data;

            choices.unshift({
                Id: -1,
                Name: 'removeStatistics',
                Topic: 'removeStatistics',
                Icon: 'mdi-close',
                Color: 'var(--v-gray2-base)',
                selectable: payload.type !== 'add',
            });

            commit('SET_CHOICES', choices);
            commit('SET_ACTIVE_CHOICE', payload.id);
            commit('SET_SELECTABLE_CHOICES');

            return choices;
        } catch (error) {
            console.log('Dynamic Statistics -> Get Dynamic Statistics -> Error:', error);
            return null;
        }
    },

    async getDynamicStatistics({ commit, dispatch, state, rootState }) {
        if (state.currentState !== 'finished') {
            if (state.currentStateTimeout) {
                clearTimeout(state.currentStateTimeout);
                commit('SET_CURRENT_STATE_TIMEOUT', null);
            }
            const timeout = setTimeout(() => {
                commit('SET_STATE', { value: 'loading' });
            }, 200);

            commit('SET_CURRENT_STATE_TIMEOUT', timeout);
        }
        const filter = mapFilterPayload(
            rootState.Cases.caseFiltersState,
            rootState.Cases.searchType,
            rootState.Cases.caseFiltersState,
            rootState.Brands.active,
        );

        const isEmpty = keysAreEmpty(rootState.Cases.caseFiltersState);

        if (isEmpty) {
            commit('SET_STATE', { value: 'error' });
            return;
        }

        const payload = {
            filter,
            userId: state.global ? 0 : rootState.Auth.userObject.userId,
        };

        try {
            const { data } = await dynamicStatistics.getDynamicStatistics(payload);
            commit('SET_STATE', { value: 'finished' });
            dispatch('setData', data);
            dispatch('subscribeToWallboard');
            return data;
        } catch (error) {
            commit('SET_STATE', { value: 'error' });
            console.log('Dynamic Statistics -> Get Dynamic Statistics -> Error:', error);
            return null;
        }
    },

    async getItem({ commit }, payload) {
        try {
            const { data } = await dynamicStatistics.getDynamicStatisticsItem(payload);
            commit('SET_HOVERED_ITEM', data);

            return data;
        } catch (error) {
            commit('SET_STATE', { value: 'error' });
            console.log('Dynamic Statistics -> Get Item Dynamic Statistics -> Error:', error);
        }
    },

    updateData({ commit }, payload) {
        commit('SET_DATA', payload);
    },

    activateEdit({ commit, state }, payload) {
        const value = payload.state;
        const { isAdmin } = payload;

        if (!isAdmin && state.global === true) {
            commit('SET_SUB_STATE', { value: 'idle' });
            return;
        }

        if (value) {
            commit('SET_SUB_STATE', { value: 'edit' });
        } else {
            commit('SET_SUB_STATE', { value: 'idle' });
        }
    },

    async updateOrder({ commit, rootState, state, dispatch }) {
        const id = state.global ? 0 : rootState.Auth.userObject.userId;
        setTimeout(() => {
            commit('UPDATE_STATE_ORDER');

            const payload = state.data.map((item) => {
                return {
                    id: item.id,
                    order: item.order,
                    userId: id,
                };
            });

            const newPayload = payload.filter((item) => {
                return item.id !== undefined;
            });

            dynamicStatistics
                .updateDynamicOrder(newPayload, id)
                .then((res) => {
                    dispatch('getDynamicStatistics');
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('dynamicStatistics.errorOrder'), {
                        icon: 'mdi-alert-circle',
                        type: 'error',
                    });

                    console.log('Dynamic Statistics -> Order -> Error:', error);
                });
        }, 300);
    },

    async addItem({ dispatch, state, rootState }, type) {
        dispatch('unSubscribeToWallboard');
        const id = state.global ? 0 : rootState.Auth.userObject.userId;
        const indexLength = state.data.filter((item) => {
            return item.status !== 'add';
        }).length;

        const payload = {
            userId: id,
            dataPoint: state.choices[type.value.ID].ID,
            listOrder: indexLength,
        };

        dynamicStatistics
            .addDynamicStatistics(payload)
            .then((res) => {
                dispatch('getDynamicStatistics');
                dispatch('updateOrder');
            })
            .catch((error) => {
                this._vm.$toasted.show(i18n.t('dynamicStatistics.errorAdd'), {
                    icon: 'mdi-alert-circle',
                    type: 'error',
                });

                console.log('Dynamic Statistics -> Add -> Error:', error);
            });
    },

    async updateItem({ dispatch, state, rootState }, type) {
        dispatch('unSubscribeToWallboard');
        const id = state.global ? 0 : rootState.Auth.userObject.userId;
        if (type.value.Id === -1) {
            // DELETE ITEM
            const payload = { id: type.index, userId: id };
            dynamicStatistics
                .deleteDynamicStatistics(payload)
                .then((res) => {
                    dispatch('getDynamicStatistics');

                    this._vm.$toasted.show(i18n.t('dynamicStatistics.deleted'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('dynamicStatistics.errorDelete'), {
                        icon: 'mdi-alert-circle',
                        type: 'error',
                    });

                    console.log('Dynamic Statistics -> Deleted -> Error:', error);
                });
        } else {
            // UPDATE ITEM
            const payload = {
                userId: id,
                dataPoint: state.choices[type.value.ID].ID,
                listOrder: type.index,
            };

            dynamicStatistics
                .updateDynamicStatistics(payload)
                .then((res) => {
                    dispatch('getDynamicStatistics');

                    this._vm.$toasted.show(i18n.t('dynamicStatistics.updated'), {
                        icon: 'mdi-content-save',
                        type: 'success',
                    });
                })
                .catch((error) => {
                    this._vm.$toasted.show(i18n.t('dynamicStatistics.errorUpdate'), {
                        icon: 'mdi-alert-circle',
                        type: 'error',
                    });

                    console.log('Dynamic Statistics -> Updated -> Error:', error);
                });
        }
        dispatch('updateOrder');
    },

    async setData({ commit }, payload) {
        const data = payload.sort((a, b) => a.order - b.order);
        commit('SET_DATA', data);
    },

    async subscribeToWallboard({ rootState, dispatch }) {
        const filters = rootState.Cases.caseFiltersState;

        const mappedFilters = formatFilters(filters);

        const subscription = state.data
            .map((item) => {
                return item.dataPoint;
            })
            .sort()
            .filter((item) => {
                return item != null;
            });
        if (state.currentSubscription) {
            const { subscription: oldSubscription, mappedFilters: oldMappedFilters } = state.currentSubscription;

            this._vm.$socket.emit('wallboardDSUnsubscription', {
                subscription: oldSubscription,
                mappedFilters: oldMappedFilters,
            });
        }
        dispatch('saveCurrentSubscription');

        this._vm.$socket.emit('wallboardDSSubscription', { subscription, mappedFilters });
    },

    saveCurrentSubscription({ rootState, commit }) {
        const newState = structuredClone(rootState.Cases.caseFiltersState);
        const mappedFilters = formatFilters(newState);
        const subscription = state.data
            .map((item) => {
                return item.dataPoint;
            })
            .sort()
            .filter((item) => {
                return item != null;
            });
        commit('SET_CURRENT_SUBSCRIPTION', { subscription, mappedFilters });
    },

    async subscribeToWallboardFilterChange({ dispatch, state }) {
        const { subscription, mappedFilters } = state.currentSubscription;
        dispatch('saveCurrentSubscription');
        this._vm.$socket.emit('wallboardDSUnsubscription', { subscription, mappedFilters });
        dispatch('subscribeToWallboard');
    },

    unSubscribeToWallboard({ rootState, state }) {
        const filters = rootState.Cases.caseFiltersState;

        const mappedFilters = formatFilters(filters);

        if (state.data == null) return;
        const subscription = state.data
            .map((item) => {
                return item.dataPoint;
            })
            .sort()
            .filter((item) => {
                return item != null;
            });
        this._vm.$socket.emit('wallboardDSUnsubscription', { subscription, mappedFilters });
    },
    toggleVisibility({ commit, state }) {
        commit('SET_VISIBILITY', !state.visible);
    },
};

const mutations = {
    SET_CURRENT_SUBSCRIPTION(state, payload) {
        state.currentSubscription = payload;
    },
    UPDATE_STATE_ORDER(state) {
        let lastItem = false;
        let index = 0;
        for (let i = 0; i < state.data.length; i++) {
            if (state.data[i].status !== 'add') {
                state.data[i].order = index;
                index++;
            }
            if (state.data[i].status === 'add') {
                lastItem = true;
            }
            if (lastItem && state.data[i].status !== 'add') {
                this._vm.$toasted.show(i18n.t('dynamicStatistics.errorPlacement'), {
                    icon: 'hand-back-left',
                    type: 'warning',
                    duration: 1500,
                });
            }
        }
    },
    SET_DATA(state, payload) {
        for (let i = 0; i < 4; i++) {
            if (payload[i] === undefined) {
                payload[i] = { status: 'add', listOrder: i };
            }
        }
        state.data = payload;
    },
    SET_STATE(state, payload) {
        state.currentState = payload.value;
        clearTimeout(state.currentStateTimeout);
        state.currentStateTimeout = null;
    },
    SET_CURRENT_STATE_TIMEOUT(state, payload) {
        state.currentStateTimeout = payload;
    },

    SET_SUB_STATE(state, payload) {
        state.subState = payload;
    },
    SET_CHOICES(state, payload) {
        state.choices = payload;
    },
    SET_ACTIVE_CHOICE(state, payload) {
        state.activeChoice = payload;
    },
    SET_HOVERED_ITEM(state, payload) {
        state.hoveredItem = payload;
    },
    SET_CURVE_STATISTICS(state, payload) {
        state.curveStatistics = payload;
    },
    SET_SELECTABLE_CHOICES(state) {
        for (const key in state.choices) {
            state.data.find((item) => {
                if (item.dataPoint === key) {
                    state.choices[key].selectable = false;
                }
            });
        }
    },
    SET_VISIBILITY(state, payload) {
        state.visible = payload;
    },
    SET_GLOBAL(state, payload) {
        state.global = payload;
    },
};

export const plugins = [
    localStoragePlugin([
        {
            key: '__dynamicStatisticsVisible',
            stateProperty: 'visible',
            mutationName: 'DynamicStatistics/SET_VISIBILITY',
        },
        {
            key: '__dynamicStatisticsGlobal',
            stateProperty: 'global',
            mutationName: 'DynamicStatistics/SET_GLOBAL',
        },
    ]),
];

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    plugins,
};
