<template>
    <Transition name="fade">
        <main v-if="state === states.LOADED">
            <header>
                <section class="user">
                    <CommonAvatar :base64img="soMeUserImage" size="36" />
                    <section>
                        <span class="name">{{ soMeUserName }}</span>
                    </section>
                </section>
                <section>
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                fab
                                elevation="0"
                                height="40"
                                width="40"
                                v-on="on"
                                @click="() => openSoMe()"
                            >
                                <img :src="icon(selectedCase?.case?.source)" height="18" width="18" />
                            </v-btn>
                        </template>
                        <span>{{ item.meta?.SoMeLink }}</span>
                    </v-tooltip>
                </section>
            </header>
            <v-divider class="divider" />
            <header>
                <section class="type">
                    {{ type(item.meta.Type, selectedCase?.case?.source) }}
                </section>
            </header>
            <v-divider v-if="isContent" class="divider" />
            <section v-if="isContent" class="content">
                <span v-html="item.meta?.Message"></span>
            </section>
        </main>
        <main v-else-if="state === states.LOADING" class="loader">
            <v-progress-circular indeterminate color="gray1" size="24" width="3" />
        </main>
    </Transition>
</template>
<script>
    import { format } from 'date-fns';
    import { mapState } from 'vuex';
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    import { icon, type } from '@/helpers/social.helper';
    import { getDay } from '@/utils/DateFormatter';

    export default {
        name: 'SocialCase',

        components: {
            CommonAvatar,
        },
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                data: null,
                state: 'Init',
                states: Object.freeze({
                    INIT: 'Init',
                    LOADING: 'Loading',
                    LOADED: 'Loaded',
                }),
            };
        },
        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
            }),

            isContent() {
                return this.item.meta?.Message !== null;
            },
            selectedCase() {
                return this.$store.state.Cases.selectedCase;
            },
            soMeUserImage() {
                if (this.item.meta?.SoMeUserImage !== null) {
                    return this.item.meta?.SoMeUserImage;
                }
                if (this.item.meta?.SocialMediaChannelImage !== null) {
                    return this.item.meta?.SocialMediaChannelImage;
                }
                return '';
            },
            soMeUserName() {
                if (this.item.meta?.Type === 'message') {
                    return this.item.meta?.ClientName;
                }
                if (this.item.meta?.SoMeUserName === null) {
                    return this.$t('social.unknown');
                }
                return this.item.meta?.SoMeUserName;
            },
        },
        created() {
            this.init();
        },
        methods: {
            getDay,
            format,
            formatDate(dateTime) {
                if (!this.userSettings.cases.showDate.active) {
                    return getDay(dateTime);
                }

                return getDay(dateTime) + ' (' + format(new Date(dateTime), 'yyyy-MM-dd') + ')';
            },
            type,
            icon,

            init() {
                this.state = this.states.LOADING;
                this.state = this.states.LOADED;
            },
            openSoMe() {
                try {
                    const newWindow = window.open(this.item.meta?.SoMeLink, '_blank');
                    if (!newWindow) {
                        throw new Error('Failed to open URL in a new window. Please check your browser settings.');
                    }
                } catch (error) {
                    this.$toasted.show(this.$t('link.checkBrowser'), {
                        icon: 'alert',
                        type: 'error',
                        duration: 5000,
                    });
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    .type {
        padding-top: 16px;
        font-size: 1.2rem;
    }
    main {
        width: 100%;
        height: 100%;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        padding: 24px;
        margin: 10px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-width: 25%;
        max-width: 400px;
    }

    .user {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .divider {
        margin-top: 18px;
    }
    .content {
        margin-top: 24px;
        margin-bottom: 12px;
    }
    header {
        color: var(--v-gray4-base);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
    }
    header .v-avatar {
        margin-right: 16px;
    }
    header section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    header section .date {
        color: var(--v-gray1-base);
    }
    .comment-menu {
        display: grid;
        grid-template-columns: auto auto 1fr;
        grid-gap: 8px;
    }
    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
<i18n lang="json">
{
    "en": {
        "link": {
            "checkBrowser": "Failed to open URL in a new window. Please check your browser settings."
        },
        "social": {
            "unknown": "Unknown"
        }
    },
    "sv": {
        "link": {
            "checkBrowser": "Misslyckades med att öppna URL i ett nytt fönster. Vänligen kontrollera dina webbläsarinställningar."
        },
        "social": {
            "unknown": "Okänd"
        }
    }
}
</i18n>
