<template>
    <section ref="tiptap-container" class="tiptap-editor-outer" tabindex="0" :style="tiptapStyle">
        <TemplatesShortcut
            ref="templatesShortcut"
            :searchString="content"
            :hasAutoSelected="hasAutoSelected"
            :showAutoComplete="autocomplete"
            :templateCategoryId="templateTypes.EMAIL"
            context="emailMessagesRef"
            :queueId="queueId"
            @exit="exitAutocomplete"
            @applyAutoComplete="applyAutoComplete"
        />
        <!-- Start of Editor Area -->
        <section
            :style="{ minHeight: height, backgroundColor: textfieldColor, border: borderStyle }"
            class="editor-area"
            @click="shouldFocus"
            @dragover.prevent="dragOver($event)"
            @dragenter.prevent="dragEnter($event)"
            @dragleave.prevent="dragLeave($event)"
            @drop.prevent="dropFile($event)"
        >
            <div class="editor-area-content">
                <section>
                    <TiptapToolbar
                        v-if="!basic"
                        :editor="editor"
                        :signatureHasContentAndShowChip="signatureHasContentAndShowChip"
                        :tiptapOptions="tiptapOptions"
                        :activeMenuChoices="activeMenuChoices"
                        :content="content"
                        :caseId="caseId"
                        :enableAttachment="enableAttachment && enableOutlineAttachment"
                        @files="(files) => handleFiles(files)"
                    />
                </section>

                <!-- Start of Editor Content-->
                <section v-if="!htmlOnly" class="writeable-area">
                    <section v-show="dragover" id="dragoverFile" class="drag-over-file">
                        <div class="drag-over-file-overlay"></div>

                        <div id="dropArea" class="drop-area">
                            <span class="attachment-icon">
                                <v-icon>mdi-attachment</v-icon>
                                {{ $t('tiptapEditor.dropFiles') }}
                            </span>
                        </div>
                    </section>
                    <div v-show="!dragover" class="scroll-container-area" cy-data="bigTextInput">
                        <section class="editor">
                            <!-- Attachments -->
                            <section
                                v-if="tiptapFiles.length && !basic"
                                :key="caseId"
                                class="attachment-area"
                                :style="{ backgroundColor: textfieldColor }"
                            >
                                <FileAttachmentCard
                                    v-for="item of tiptapFiles"
                                    :key="item.id"
                                    :isSmall="attachSmall"
                                    :item="item.file"
                                    @removeFile="handleFileDelete(item.id)"
                                />
                            </section>

                            <!-- Content -->
                            <editor-content
                                id="emailMessagesRef"
                                ref="tip-tap"
                                tabindex="0"
                                :editor="editor"
                                class="tip-tap"
                            />
                            <!-- Signature -->
                            <section v-if="signatureHasContentAndShowChip && !basic" class="signature-content">
                                <section
                                    v-dompurify-html="replacedValuesSignature.Content"
                                    class="signature-inner-content"
                                ></section>
                            </section>
                        </section>
                    </div>
                </section>

                <section v-else class="writeable-area">
                    <textarea
                        ref="htmlOnlyEditor"
                        v-model="content"
                        :style="{ height, width }"
                        :placeholder="$t('fontStyle.writeHere')"
                        class="html-only-editor"
                        @input="emitChange"
                        @focus="emitChange"
                        @blur="emitChange"
                    ></textarea>
                </section>
            </div>
        </section>
        <section v-if="counter" class="counter">{{ contentTextLength }}</section>
    </section>
</template>
<script>
    import { mapState, mapActions } from 'vuex';

    // Helpers & utils
    import {
        fileReaderHandler,
        convertBase64ImagesToInline,
        getImageFileNameByType,
    } from '@/helpers/files/files.helper';

    import { Editor, EditorContent } from '@tiptap/vue-2';
    import StarterKit from '@tiptap/starter-kit';

    // Tiptap extensions
    import Placeholder from '@tiptap/extension-placeholder';
    import Link from '@tiptap/extension-link';
    import Underline from '@tiptap/extension-underline';
    import Subscript from '@tiptap/extension-subscript';
    import Superscript from '@tiptap/extension-superscript';
    import Dropcursor from '@tiptap/extension-dropcursor';
    import Textstyle from '@tiptap/extension-text-style';
    import Fontfamily from '@tiptap/extension-font-family';
    import Color from '@tiptap/extension-color';
    import TableRow from '@tiptap/extension-table-row';
    import {
        convertHtmlStylesToInline,
        isExcelPastedContent,
        isImageBase64PastedContent,
        imageContentIsFragmentImgHttp,
    } from '@/utils/dom.utils';
    import { replaceTemplateValues, htmlToText, containsHtmlElement } from '@/utils';
    import { templateTypes } from '@/enums/templates.enums';
    import { imageMimeTypes } from '@/enums/files.enums';
    import { v4 as uuidv4 } from 'uuid';

    import {
        getImageFromDataSource,
        setImageDataSource,
        getSrcUrl,
        getImagesInContent,
        sanitizeImageSrc,
    } from '../../directives/shadowDom';
    import {
        CustomImage,
        FontSize,
        Div,
        tiptapOptions,
        CustomTableCell,
        CustomTable,
        CustomTableHeader,
    } from '../../utils/TiptapNativeExtensions';

    // Components
    import TiptapToolbar from '../Cases/Comments/TiptapToolbar.vue';
    import TemplatesShortcut from '../Cases/TemplatesShortcut.vue';

    const MAX_ATTACHMENT_SIZE = 25000000;

    export default {
        components: {
            FileAttachmentCard: () => import('@/components/Global/FileAttachmentCard.vue'),
            EditorContent,
            TiptapToolbar,
            TemplatesShortcut,
        },
        props: {
            htmlOnly: { type: Boolean, default: false },
            queueId: { type: Number, default: null },
            showSignature: { type: Boolean, default: false },
            defaultInlineAttachments: { type: Boolean, default: false },
            textfieldColor: { type: String, default: '#f3f3f3' },
            height: { type: String, default: '300px' },
            placeholder: { type: String, default: '' },
            autocomplete: { type: Boolean, default: true },
            caseId: { type: String, default: null },
            borderStyle: { type: String, default: '1px solid #f3f3f3' },
            files: {
                type: Array,
                default() {
                    return [];
                },
            },
            attachSmall: { type: Boolean, default: false },
            id: { type: String, default: 'tippytappy1' },
            signature: {
                type: Object,
                default() {
                    return {
                        Content: '',
                    };
                },
            },
            templateLanguage: {
                type: String,
                default: null,
            },
            template: { type: Object || null, default: () => ({ content: '', attachments: [] }) },
            width: { type: String, default: '100%' },
            existingContent: { type: String, default: '' },
            fontSize: { type: String, default: '14px' },
            showChips: {
                type: Array,
                default: () => [],
            },

            chipData: {
                type: Object,
                default() {
                    return {
                        text: '',
                        backgroundColor: '',
                        textColor: '',
                        size: '',
                    };
                },
            },

            basic: { type: Boolean, default: false },
            activeMenuChoices: {
                type: Array,
                default: () => [],
            },
            msgId: {
                type: String,
                default: '',
            },
            /**
             *
             * DISCLAIMER: This context is needed due to us uploading files in the tiptap editor. An alternative approach would be to move the upload to the parent component and then pass the files down to the tiptap editor. This would be a better approach as it would make the tiptap editor more reusable and easier to test and not be reliant on the context.
             *
             * The context of where the tiptap editor is being used. This is currently only being used to connect a file with a resource. But could realistically be used for other things as well. Such as the current user, the
             * current case etc.
             *
             * @type {object | null} context - The context of where the tiptap editor is being used.
             * @property {string} context.resource - The resource (eg. template, comment) this is if you are for example editing a template or a comment. Then we know what resource the file is associated with.
             * @property {string | number | null} context.id - The id of the resource. This is used so we can associate the file with the resource.
             */
            context: {
                type: Object,
                default: () => null,
            },
            shouldAutoFocus: {
                type: Boolean,
                default: true,
            },
            enableAttachment: {
                type: Boolean,
                default: true,
            },
            enableOutlineAttachment: {
                type: Boolean,
                default: true,
            },
            counter: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                templateTypes,
                imageObserver: null,
                hasAutoSelected: false,
                editor: null,
                templateData: [],
                tiptapOptions,
                content: '',
                signatureContent: '',
                tiptapFiles: [],
                dragover: false,
                dragNdrop: null,
                selectedIndex: 0,
                searchString: '',
            };
        },

        computed: {
            ...mapState({
                systemEmails: (state) => state.Cases.systemEmails,
                contentAI: (state) => state.Comments.contentAI,
                templates: (state) => state.Admin.templates,
                userSettings: (state) => state.System.userSettings,
                clientNowOnCard: (state) => state.Cases.clientNowOnCard,
            }),
            client() {
                return this.clientNowOnCard?.data || null;
            },

            loggedInUser() {
                return this.$store.state.Auth.userObject || null;
            },

            signatureHasContentAndShowChip() {
                return this.showSignature && !!this.signature?.Content;
            },

            contentTextLength() {
                return htmlToText(this.content).length;
            },

            replacedValuesSignature() {
                return {
                    ...this.signature,
                    Content: replaceTemplateValues(this.signature.Content, {
                        client: this.client || {},
                        agent: this.loggedInUser || {},
                        sender: this.systemEmails[0] || '',
                    }),
                };
            },

            tiptapStyle() {
                return {
                    minHeight: this.height,
                };
            },
            tiptapContent() {
                return this.$refs['tip-tap']?.$el?.innerHTML;
            },
        },

        watch: {
            contentAI(data) {
                this.setContent(data);
            },

            content: {
                async handler(data) {
                    // * Q: IS THIS EVEN IN USE???
                    if (!data) return;
                    this.emitChange();
                    await this.$nextTick();
                    this.setImageObserver();

                    if (!this.templateData) {
                        this.selectedIndex = 0;
                        return;
                    }
                    this.searchResult = this.templateData
                        .filter((item) => this.templateLanguage === null || item.Language === this.templateLanguage)
                        .filter(
                            (item) =>
                                Number(item.CategoryId) === templateTypes.EMAIL &&
                                item.IsActive &&
                                ((htmlToText(item.Content).toLowerCase().includes(htmlToText(data).toLowerCase()) &&
                                    htmlToText(item.Content).toLowerCase() !== htmlToText(data).toLowerCase()) ||
                                    item.Name.toLowerCase().includes(htmlToText(data).toLowerCase()))
                        )
                        .map((item) => {
                            return {
                                ...item,
                                Content: replaceTemplateValues(item.Content, {
                                    client: this.client,
                                    agent: this.loggedInUser,
                                    sender: this.systemEmails[0] || '',
                                }),
                            };
                        });

                    this.templateData = this.templates;
                    this.hasAutoSelected = htmlToText(data).length === 0;
                },
                immediate: true,
                deep: true,
            },
            template: {
                deep: true,
                handler() {
                    if (this.$props.existingContent) {
                        const formattedExistingContent = this.$props.existingContent.split('\n').join('<br>');
                        this.content = formattedExistingContent;
                    }

                    this.applyTemplate();
                },
            },

            templates: {
                handler() {
                    this.templateData = this.templates.filter((item) => Number(item.CategoryId) === 1);
                },
            },

            signature(newVal) {
                this.removeCurrentSignature();
                this.applySignatureTemplate(newVal);
            },

            colorPickerValue(newVal) {
                this.setColor(newVal);
            },

            existingContent: {
                deep: true,
                handler(newVal) {
                    if (this.content === newVal) return;
                    const formattedExistingContent = newVal.replaceAll('\n', '<br>');
                    this.content = formattedExistingContent;
                    this.setContent(this.content);
                },
            },
            replacedValuesSignature: {
                deep: true,
                immediate: true,
                handler() {
                    this.setSignature();
                },
            },
            basic: {
                handler(newVal) {
                    if (!newVal) return;

                    // * if we change to basic, we remove images from content and remove signature
                    const div = document.createElement('div');
                    div.innerHTML = this.content;
                    const images = div.querySelectorAll('img');

                    if (!images.length) return;

                    for (const image of images) {
                        image.remove();
                    }

                    this.content = div.innerHTML;
                    this.setContent(div.innerHTML);
                    this.removeCurrentSignature();
                    this.tiptapFiles = [];
                },
            },
            files: {
                handler(newVal) {
                    // check if there are any files in newVal that are not in tiptapFiles using contentId
                    const newFiles = newVal.filter(
                        (file) => !this.tiptapFiles.some((f) => f.contentId === file.contentId)
                    );
                    if (!newFiles.length) return;
                    this.tiptapFiles = newVal;
                    this.emitChange();
                },
                immediate: true,
            },
        },
        created() {
            if (!this.files) return;
            this.tiptapFiles = this.files;
        },
        mounted() {
            this.generateEditor();

            try {
                this.setContent(this.$props.existingContent);
            } catch (error) {
                // this is to hide an error with vue-2 tiptap editor package. It's not a big deal: TypeError: Cannot read properties of undefined (reading 'eq')
            }
        },
        beforeDestroy() {
            this.editor.destroy();
        },
        methods: {
            exitAutocomplete() {
                const tiptap = document.querySelector('.tiptap');
                tiptap.focus();
            },

            getImageFromDataSource,
            setImageDataSource,
            getSrcUrl,
            getImagesInContent,
            ...mapActions({
                uploadAttachments: 'Files/uploadAttachments',
            }),
            isTable(htmlContent) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlContent, 'text/html');
                return doc.querySelector('table') !== null;
            },
            handleFileDelete(fileId) {
                this.tiptapFiles = this.tiptapFiles.filter((file) => file.id !== fileId);
                this.emitChange();
            },
            setContent(content) {
                this.editor.commands.setContent(content);
                this.editor.options.onUpdate({
                    transactions: [{}],
                });
            },

            insertContent(content) {
                this.editor.commands.insertContent(content);
                this.editor.options.onUpdate({
                    transactions: [{}],
                });
            },

            async handleFiles(files) {
                if (this.basic) {
                    this.notifyAttachmentsNotPermitted();
                    return;
                }

                for (const file of files) {
                    try {
                        const [readFile] = await fileReaderHandler([file], { maxFileSize: MAX_ATTACHMENT_SIZE });

                        const fileItem = {
                            file: readFile,
                            id: uuidv4(),
                            type: 'file',
                        };
                        this.tiptapFiles.push(fileItem);
                        this.validateTipTapFiles();
                    } catch (error) {
                        // file size too large
                    }
                }
                this.emitChange();
            },

            async handleInlineFiles(pastedFiles) {
                if (this.basic) {
                    this.notifyInlineImagesNotPermitted();
                    return;
                }

                if (!this.defaultInlineAttachments) {
                    this.handleFiles(pastedFiles);
                    return;
                }

                if (this.basic) return;

                const contentId = uuidv4();

                const validatedFiles = await this.validateFiles(pastedFiles, contentId);

                const files = validatedFiles.map((file) => {
                    file.isInline = true;
                    file.contentId = contentId;
                    file.name = getImageFileNameByType(file.name, file.contentType);

                    // Set msgId if exist in prop
                    if (this.msgId) {
                        file.msgId = this.msgId;
                    }

                    // * format the file context based on the context prop
                    if (this.context && this.context.resource) {
                        file.context = {
                            resource: this.context.resource,
                            id: this.context?.id,
                        };
                    }

                    return file;
                });

                const uploadedFilesResult = await this.uploadAttachments(files);

                if (!uploadedFilesResult) {
                    this.$toasted.show(this.$t('tiptapEditor.uploadAttachmentsError'), {
                        icon: 'cancel',
                        type: 'error',
                    });
                    return;
                }

                for (const uploadedFile of uploadedFilesResult) {
                    const cid = `cid:${uploadedFile.contentId}`;
                    const mimeType = uploadedFile.contentType || 'image/png';
                    const base64Data = uploadedFile.contentBytes;
                    const dataUrl = `data:${mimeType};base64,${base64Data}`;

                    this.editor.commands.setImage({
                        src: dataUrl,
                        'data-src': cid,
                    });
                }

                this.setContent(this.content);
            },

            async validateFiles(files) {
                const readFiles = await fileReaderHandler(files, { maxFileSize: MAX_ATTACHMENT_SIZE });

                const fileSizeTotal = readFiles.reduce((acc, file) => acc + file.size, 0);

                if (fileSizeTotal > MAX_ATTACHMENT_SIZE) {
                    this.$toasted.show(
                        this.$t('tiptapEditor.fileSizeExceeded', {
                            maxSize: this.formatFileSizeToMB(MAX_ATTACHMENT_SIZE),
                        }),
                        {
                            icon: 'cancel',
                            type: 'error',
                        }
                    );
                    return null;
                }

                return readFiles;
            },

            /**
             * Validate the total size of the files in the tiptapFiles array
             * If the total size exceeds the MAX_ATTACHMENT_SIZE, remove the last file from the array and call the function recursively
             */
            validateTipTapFiles() {
                const fileSizeTotal = this.tiptapFiles.reduce((acc, file) => acc + file.file.size, 0);

                if (fileSizeTotal > MAX_ATTACHMENT_SIZE) {
                    this.$toasted.show(
                        this.$t('tiptapEditor.fileSizeExceeded', {
                            maxSize: this.formatFileSizeToMB(MAX_ATTACHMENT_SIZE),
                        }),
                        {
                            icon: 'cancel',
                            type: 'error',
                        }
                    );
                    this.tiptapFiles.pop();
                    this.validateTipTapFiles();
                }
            },
            formatFileSizeToMB(sizeInBytes) {
                const sizeInMB = sizeInBytes / (1024 * 1024);
                return sizeInMB.toFixed(2);
            },

            replaceTemplateValues,
            openLinkMenu() {
                this.menu = true;
            },

            generateEditor() {
                this.editor = new Editor({
                    content: this.content,
                    attributes: {
                        class: 'my-editor-class',
                    },
                    extensions: [
                        Placeholder.configure({
                            placeholder: this.placeholder || this.$t('fontStyle.writeHere'),
                        }),
                        StarterKit.configure({
                            heading: {
                                levels: [1, 2, 3],
                            },
                        }),
                        Underline.configure({
                            HTMLAttributes: {},
                        }),
                        Superscript.configure({
                            HTMLAttributes: {},
                        }),
                        Subscript.configure({
                            HTMLAttributes: {},
                        }),

                        Link.configure({}),
                        Div,
                        Textstyle,
                        Fontfamily,
                        Color,
                        FontSize,
                        CustomImage.configure({
                            inline: true,
                            allowBase64: true,
                        }),

                        TableRow,
                        CustomTableHeader,
                        CustomTableCell,
                        CustomTable.configure({
                            resizable: true,
                            cellMinWidth: 50,
                        }),
                        Dropcursor.configure({
                            color: 'black',
                            width: 2,
                        }),
                    ],

                    editorProps: {
                        transformPastedHTML: (html) => {
                            if (isExcelPastedContent(html)) {
                                return convertHtmlStylesToInline(html);
                            }

                            if (isImageBase64PastedContent(html)) {
                                if (this.basic) {
                                    this.notifyInlineImagesNotPermitted();
                                    return '';
                                }
                                const { content, files } = convertBase64ImagesToInline(html);
                                this.uploadAttachments(files);
                                return content;
                            }

                            // * When copying images from email clients, the paste contains img with url AND files array in the dataTransfer object
                            // * without the following statement we will paste 2 files in the editor, where the url may or may not need authorization

                            if (imageContentIsFragmentImgHttp(html)) {
                                return '';
                            }

                            return html;
                        },
                        handlePaste: (_, event) => {
                            // Get the clipboard data
                            const htmlContent = event.clipboardData.getData('text/html');

                            // Handle the pasted content
                            if (htmlContent && containsHtmlElement(htmlContent, 'table')) {
                                return;
                            }

                            if (event.clipboardData.files.length && !isImageBase64PastedContent(htmlContent)) {
                                event.preventDefault();

                                for (const file of event.clipboardData.files) {
                                    if (Object.values(imageMimeTypes).includes(file.type)) {
                                        this.handleInlineFiles([file]);
                                    } else {
                                        this.handleFiles([file]);
                                    }
                                }
                            }
                        },
                    },
                    onUpdate: () => {
                        const commentContent = this.editor.getHTML();
                        this.content = commentContent;

                        if (!this.basic && this.content !== '<p></p>') {
                            let updatedContent = commentContent
                                .replaceAll('<p></p>', '<br>')
                                .replaceAll('<p>', `<p style='margin: 0; line-height: 1.2;'>`)
                                .replaceAll(
                                    `<p style='margin: 0; line-height: 1.2;'></p>`,
                                    `<p style='margin: 0; line-height: 1.2;'>&nbsp;</p>`
                                );

                            if (
                                updatedContent === '<p></p>' ||
                                updatedContent === `<p style='margin: 0; line-height: 1.2;'></p>` ||
                                updatedContent === `<p style='margin: 0; line-height: 1.2;'>&nbsp;</p>`
                            ) {
                                updatedContent = '';
                            }
                            this.content = updatedContent;
                        }

                        if (this.content === '<p></p>') {
                            this.content = '';
                        }

                        this.emitChange();
                        this.setImageObserver();
                    },
                });
            },
            applyAutoComplete(val) {
                this.content = val.content;
                this.editor.destroy();
                this.generateEditor();

                this.$nextTick(() => {
                    this.hasAutoSelected = true;
                });
                this.selectedIndex = 0;
                this.applyTemplateAttachments(val.attachments);
            },

            applyTemplate() {
                const { attachments } = this.template;
                this.applyTemplateAttachments(attachments);

                this.$nextTick(() => {
                    try {
                        if (this.content === '<br>') {
                            const transaction = this.setContent(this.template.content);
                            this.editor.view.dispatch(transaction);
                        } else {
                            this.editor.chain().focus().insertContent(this.template.content).run();
                        }

                        this.emitChange();
                        this.setImageObserver();
                    } catch (error) {
                        // this is to hide an error with vue-2 tiptap editor package. It's not a big deal: TypeError: Cannot read properties of undefined (reading 'eq')
                    }
                });
            },
            applyTemplateAttachments(attachments) {
                if (!attachments?.length) {
                    return;
                }

                const formattedAttachments = attachments.map((attachment) => {
                    return {
                        file: attachment,
                        type: 'reference',
                    };
                });
                this.tiptapFiles.push(...formattedAttachments);
            },

            removeCurrentSignature() {
                if (this.content.includes('<div id="signature:')) {
                    const signatureDivider = this.content.includes('<br></p><div id="signature:')
                        ? '<br></p><div id="signature:'
                        : '<div id="signature:';

                    try {
                        const str = this.content.slice(0, Math.max(0, this.content.indexOf(signatureDivider)));
                        this.setContent(str);
                    } catch (error) {
                        // this is to hide an error with vue-2 tiptap editor package. It's not a big deal: TypeError: Cannot read properties of undefined (reading 'eq')
                    }
                }
            },
            applySignatureTemplate(templateObj) {
                if (templateObj.Hidden || !this.userSettings.texteditor.showSignature.active) {
                    localStorage.setItem('__caseHiddenSignatureId_' + this.caseId, templateObj.ID);
                } else {
                    localStorage.removeItem('__caseHiddenSignatureId_' + this.caseId);
                }
            },
            setColor(color) {
                this.editor.commands.setColor(color);
            },

            setImageObserver() {
                // Check if observer already exists to prevent multiple instances
                if (this.imageObserver) {
                    // Unobserve all previously observed images
                    const images = this.$refs['tip-tap']?.$el.querySelectorAll('img');
                    if (images) {
                        for (const image of images) {
                            this.imageObserver.unobserve(image);
                        }
                    }
                } else {
                    const options = {
                        root: null,
                        rootMargin: '0px',
                        threshold: 0,
                    };
                    this.imageObserver = new IntersectionObserver(async (entries) => {
                        for (const entry of entries) {
                            if (!entry.isIntersecting) continue;
                            await setImageDataSource(entry.target);
                            this.imageObserver.unobserve(entry.target); // Stop observing this image once it's loaded
                        }
                    }, options);
                }

                // Get the latest set of images and observe them

                const images = this.$refs['tip-tap']?.$el.querySelectorAll('img');
                if (!images?.length) return;

                for (const image of images) {
                    this.imageObserver.observe(image);
                }
            },

            emitChange() {
                this.$emit('change', {
                    content: this.content,
                    files: this.tiptapFiles,
                    signature: this.replacedValuesSignature,
                });
            },
            async setSignature() {
                if (!this.signatureHasContentAndShowChip || this.basic) {
                    this.signatureContent = '';
                    return;
                }
                this.signatureContent = await this.getImagesInContent(this.replacedValuesSignature.Content);
            },
            // Used outside of component when content is saved - Therefore, check the usage of this function globally before updating/deleting
            save() {
                return sanitizeImageSrc(this.content);
            },
            dragOver(event) {
                if (event.dataTransfer.effectAllowed === 'copyMove') {
                    return;
                }
                this.dragover = true;
            },

            dragEnter(event) {
                if (event.dataTransfer.effectAllowed === 'copyMove') {
                    return;
                }
                this.dragNdrop = event.target;
                this.dragover = true;
                event.stopPropagation();
                event.preventDefault();
            },

            dragLeave(event) {
                if (this.dragNdrop === event.target) {
                    this.dragover = false;
                }
            },
            dropFile(event) {
                if (event.dataTransfer.effectAllowed === 'copyMove') {
                    return;
                }
                this.dragover = false;
                if (!event.dataTransfer.files) return;
                this.handleFiles(event.dataTransfer.files);
            },
            listArrowKeyNavigation(event, arrowKey) {
                if (!this.showAutoComplete) return;

                event.preventDefault();

                if (arrowKey === 'up' && this.selectedIndex > 0) {
                    this.selectedIndex--;
                    this.$nextTick(() => {
                        if (this.$refs.autoListItem && this.$refs.autoListItem[this.selectedIndex]) {
                            this.$refs.autoListItem[this.selectedIndex].$el.focus();
                        }
                    });
                } else if (arrowKey === 'down' && this.selectedIndex < this.searchResult.length - 1) {
                    this.selectedIndex++;
                    this.$nextTick(() => {
                        if (this.$refs.autoListItem && this.$refs.autoListItem[this.selectedIndex]) {
                            this.$refs.autoListItem[this.selectedIndex].$el.focus();
                        }
                    });
                }
            },
            shouldFocus() {
                if (this.htmlOnly) {
                    const { htmlOnlyEditor } = this.$refs;

                    if (htmlOnlyEditor) {
                        htmlOnlyEditor.focus();
                    }
                    return;
                }

                if (this.shouldAutoFocus) {
                    this.editor.commands.focus();
                }
            },
            notifyInlineImagesNotPermitted() {
                this.$toasted.show(this.$t('tiptapEditor.inlinesNotSupported'), {
                    icon: 'cancel',
                    type: 'error',
                });
            },
            notifyAttachmentsNotPermitted() {
                this.$toasted.show(this.$t('tiptapEditor.attachmentsNotSupported'), {
                    icon: 'cancel',
                    type: 'error',
                });
            },
        },
    };
</script>

<style lang="scss">
    .ProseMirror {
        > * + * {
            margin-top: 0.75em;
        }
        flex: 1;
        flex-grow: 1;
        table {
            border-collapse: collapse;
            table-layout: fixed;

            margin: 0;
            overflow: hidden;

            td,
            th {
                min-width: 1em;
                border: 2px solid #ced4da;
                padding: 3px 5px;
                vertical-align: top;
                box-sizing: border-box;
                position: relative;
                font-size: inherit;

                > * {
                    margin-bottom: 0;
                }
            }

            th {
                font-weight: bold;
                text-align: left;
                background-color: #f1f3f5;
            }

            .selectedCell:after {
                z-index: 2;
                position: absolute;
                content: '';
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(200, 200, 255, 0.4);
                pointer-events: none;
            }

            .column-resize-handle {
                position: absolute;
                right: -2px;
                top: 0;
                bottom: -2px;
                width: 4px;
                background-color: #adf;
                pointer-events: none;
            }

            p {
                margin: 0;
            }
        }
    }

    /* Placeholder (at the top) */
    .ProseMirror p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        color: #adb5bd;
        pointer-events: none;
        height: 0;
    }

    .ProseMirror:focus {
        outline: none;
    }

    .ProseMirror img {
        max-width: 100%;
    }

    .boxShadow {
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05) !important;
        border: 1px solid #efefef;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 3px solid rgba(#0d0d0d, 0.1);
    }

    :deep(.v-color-picker__color > div) {
        border: 1px solid rgba(0, 0, 0, 0.12);
    }

    .imageInput {
        padding: 4px;
        margin: 0px;
        align-content: center;
    }

    .tiptapBtnList {
        max-height: 200px;
        overflow-y: auto;
    }

    .custom-tooltip {
        opacity: 1 !important;
        background-color: transparent !important;
    }

    .tiptap-editor-outer {
        flex: 1;
        display: flex;
        justify-content: stretch;
        align-items: stretch;
        position: relative;
        flex-direction: column;
    }

    .editor-area {
        flex: 1;
        display: flex;
        border-radius: 8px;
    }
    .editor-area-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        cursor: text;
        padding: 8px;
    }

    .editor {
        height: 0px;
        flex: 1;
    }

    .scroll-container-area {
        overflow: hidden;
        overflow-y: scroll;
        flex: 1;
        padding: 8px;
        width: 0px;
    }

    .signature-content {
        margin-top: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
    }

    .signature-inner-content {
        all: initial;
        font-family: inherit;
    }

    .writeable-area {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: stretch;
        padding-right: 12px;
    }

    .auto-complete {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-100%);
        background-color: white;
        z-index: 9999;
    }

    .auto-complete-list {
        max-height: 165px;
        overflow-y: auto;
    }

    .auto-complete-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: none;
    }

    .attachment-area {
        display: flex;
        flex-direction: row;
        gap: 4px;
        flex-wrap: wrap;
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        margin-bottom: 16px;
    }

    // Somewhere in the system we affect all H1, it was not found
    h1 {
        font-size: 2em;
        font-weight: bold;
    }

    .tip-tap {
        all: initial;
        font-family: inherit;

        p {
            margin: 0.2em 0;
            font-size: 16px;
            line-height: 16px;
            min-height: 16px;
        }

        // to preserve styles on cells in pasted tables
        td > p {
            margin: inherit;
            font-size: inherit;
            line-height: inherit;
            min-height: inherit;
        }

        .tableWrapper {
            overflow-x: auto;
        }

        .resize-cursor {
            cursor: ew-resize;
            cursor: col-resize;
        }
    }

    img {
        max-width: 100%;
        height: auto;

        &.ProseMirror-selectednode {
            outline: 3px solid #68cef8;
        }
    }

    .drag-over-file {
        position: absolute;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .drop-area {
        pointer-events: none;
        z-index: 1;
        border: 2px dotted var(--v-gray1-base);
        border-spacing: 2rem;
        padding: 2rem;
    }

    .drag-over-file-overlay {
        opacity: 0.4;
        background-color: white;
        position: absolute;
        z-index: 999;
        height: 100%;
        width: 100%;
    }

    .counter {
        display: flex;
        justify-content: flex-end;
        padding: 8px;
        font-size: 12px;
        color: var(--v-gray2-base);
    }
</style>

<style lang="scss" scoped>
    .html-only-editor {
        font-size: 16px;
        resize: none;
        padding: 8px;
    }
    .html-only-editor:focus {
        outline: none;
    }
</style>

<i18n lang="json">
{
    "en": {
        "fontStyle": {
            "thickness": "Bold",
            "italic": "Italic",
            "bulletList": "Bullet list",
            "orderedList": "Ordered list",
            "paragraph": "Paragraph",
            "heading1": "Heading 1",
            "heading2": "Heading 2",
            "heading3": "Heading 3",
            "codeBlock": "Code block",
            "horizontalRule": "Horizontal rule",
            "subscript": "Subscript",
            "superscript": "Superscript",
            "link": "Link",
            "removeLink": "Remove link",
            "image": "Image",
            "quote": "Quote",
            "code": "Code",
            "fullscreen": "Fullscreen",
            "undo": "Undo",
            "redo": "Redo",
            "formatting": "Clear formatting",
            "blockquote": "Blockquote",
            "underline": "Underline",
            "strike": "Strike",
            "writeHere": "Write here...",
            "fontSize": "Font size",
            "fontFamily": "Font family",
            "textColor": "Text color"
        },
        "tiptapEditor": {
            "signature": "Signature",
            "couldNotLoadImage": "Could not load image",
            "dropFiles": "Drop files here",
            "uploadAttachmentsError": "Could not upload attachments",
            "inlinesNotSupported": "Inline images are not supported in this text editor.",
            "attachmentsNotSupported": "Attachments are not supported in this text editor.",
            "fileSizeExceeded": "File size exceeded  (max: {maxSize} MB)"
        }
    },
    "sv": {
        "fontStyle": {
            "thickness": "Fet",
            "italic": "Kursiv",
            "bulletList": "Punktlista",
            "orderedList": "Numrerad lista",
            "paragraph": "Paragraf",
            "heading1": "Rubrik 1",
            "heading2": "Rubrik 2",
            "heading3": "Rubrik 3",
            "codeBlock": "Kodblock",
            "horizontalRule": "Horisontell linje",
            "subscript": "Nedsänkt text",
            "superscript": "Upphöjd text",
            "link": "Länk",
            "removeLink": "Ta bort länk",
            "image": "Bild",
            "quote": "Citat",
            "code": "Kod",
            "fullscreen": "Fullskärm",
            "undo": "Ångra",
            "redo": "Gör om",
            "formatting": "Rensa formatering",
            "blockquote": "Blockcitat",
            "underline": "Understruken",
            "strike": "Genomstruken",
            "writeHere": "Skriv här...",
            "fontSize": "Textstorlek",
            "fontFamily": "Typsnitt",
            "textColor": "Textfärg"
        },
        "tiptapEditor": {
            "signature": "Signatur",
            "couldNotLoadImage": "Kunde inte ladda bild",
            "dropFiles": "Släpp filer här",
            "uploadAttachmentsError": "Kunde inte ladda upp bilagor",
            "inlinesNotSupported": "Inbäddade bilder stöds inte i denna textredigerare.",
            "attachmentsNotSupported": "Bilagor stöds inte i denna textredigerare.",
            "fileSizeExceeded": "Filstorleken överskreds (max: {maxSize} MB)"
        }
    }
}
</i18n>
