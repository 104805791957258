<template>
    <v-tooltip bottom>
        <template #activator="{ on, attrs }">
            <div class="avatar-wrapper">
                <v-avatar
                    v-if="getPresenceByKey(presenceId) == 'mdi-clock-time-four'"
                    :size="size"
                    v-bind="attrs"
                    :color="'white'"
                    v-on="on"
                >
                    <v-icon dark :size="1.36 * size" :color="getPresenceColor(presenceId)">
                        {{ getPresenceByKey(presenceId) }}
                    </v-icon>
                </v-avatar>

                <v-avatar v-else :color="getPresenceColor(presenceId)" :size="size" v-bind="attrs" v-on="on">
                    <v-icon dark :size="0.54 * size">
                        {{ getPresenceByKey(presenceId) }}
                    </v-icon>
                </v-avatar>
            </div>
        </template>
        <span>{{ presenceTextByPresenceId(presenceId) }}</span>
    </v-tooltip>
</template>

<script>
    import { getPresenceIconUrl, getPresenceColor } from '@/utils';

    export default {
        props: {
            presenceId: {
                type: Number,
                default: null,
            },
            size: {
                type: Number,
                default: 22,
            },
        },
        methods: {
            getPresenceColor,
            getPresenceByKey(key) {
                switch (key) {
                    case 4: {
                        return 'mdi-clock-time-four';
                    }
                    case 1: {
                        return 'mdi-check-bold';
                    }

                    case 5: {
                        return 'mdi-minus-thick';
                    }
                    case 2: {
                        return 'mdi-phone';
                    }
                    case 0:
                    case 3: {
                        return 'mdi-close-thick';
                    }
                    default: {
                        return 'mdi-close-thick';
                    }
                }
            },

            presenceTextByPresenceId(id) {
                switch (id) {
                    case 4: {
                        return this.$t('presenceIcon.away');
                    }
                    case 1: {
                        return this.$t('presenceIcon.available');
                    }
                    case 0: {
                        return this.$t('presenceIcon.offline');
                    }
                    case 5: {
                        return this.$t('presenceIcon.busyDontDisturb');
                    }
                    case 2: {
                        return this.$t('presenceIcon.busyCall');
                    }
                    case 3: {
                        return this.$t('presenceIcon.showOffline');
                    }
                    default: {
                        return 'Offline';
                    }
                }
            },
            presenceImgByPresenceText(key) {
                let id = 0;
                switch (key) {
                    case this.$t('presenceIcon.away'): {
                        id = 4;
                        break;
                    }
                    case this.$t('presenceIcon.vacation'): {
                        id = 1;
                        break;
                    }
                    case this.$t('presenceIcon.offline'): {
                        id = 0;
                        break;
                    }
                    case this.$t('presenceIcon.busyDontDisturb'): {
                        id = 5;
                        break;
                    }
                    case this.$t('presenceIcon.busyCall'): {
                        id = 2;
                        break;
                    }
                    case this.$t('presenceIcon.showOffline'): {
                        id = 3;
                        break;
                    }
                    default: {
                        id = 0;
                        break;
                    }
                }
                return getPresenceIconUrl(id);
            },
        },
    };
</script>

<style scoped lang="scss">
    .avatar-wrapper {
        display: flex;
    }
</style>
<i18n lang="json">
{
    "en": {
        "presenceIcon": {
            "available": "Available",
            "away": "Away",
            "vacation": "Vacation",
            "offline": "Offline",
            "busyDontDisturb": "Busy - Do not disturb",
            "busyCall": "Busy in conversation",
            "showOffline": "Show as Offline"
        }
    },
    "sv": {
        "presenceIcon": {
            "available": "Tillgänglig",
            "away": "Borta",
            "vacation": "Ledig",
            "offline": "Offline",
            "busyDontDisturb": "Upptagen - Stör ej",
            "busyCall": "Upptagen i samtal",
            "showOffline": "Visa som Offline"
        }
    }
}
</i18n>
