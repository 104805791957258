import i18n from '../i18n';

import facebook from '@/assets/SocialMedia/facebook.svg';
import instagram from '@/assets/SocialMedia/instagram.svg';
import twitter from '@/assets/SocialMedia/twitter.svg';
import linkedin from '@/assets/SocialMedia/linkedin.svg';
import youtube from '@/assets/SocialMedia/youtube.svg';

export function icon(source) {
    switch (source) {
        case 'facebook': {
            return facebook;
        }
        case 'instagrambusiness': {
            return instagram;
        }
        case 'twitter': {
            return twitter;
        }
        case 'linkedinbusiness': {
            return linkedin;
        }
        case 'youtube': {
            return youtube;
        }
        default: {
            return null;
        }
    }
}

export function type(type, source) {
    let header = '';
    switch (type) {
        case 'comment': {
            header += i18n.t('global.socialComment');
            break;
        }
        case 'mentioned_media': {
            header += i18n.t('global.socialMentioned');
            break;
        }
        case 'message': {
            header += i18n.t('global.socialMessage');
            break;
        }
        default: {
            header += i18n.t('global.socialPost');
            break;
        }
    }
    header += i18n.t('global.socialOn');
    switch (source) {
        case 'facebook': {
            header += 'Facebook';
            break;
        }
        case 'instagrambusiness': {
            header += 'Instagram';
            break;
        }
        case 'twitter': {
            header += 'Twitter';
            break;
        }
        case 'linkedinbusiness': {
            header += 'LinkedIn';
            break;
        }
        case 'youtube': {
            header += 'YouTube';
            break;
        }
        default: {
            header += i18n.t('global.socialUnknown');
            break;
        }
    }
    return header;
}

export default {
    type,
};
