<template>
    <div class="d-flex" :class="getPositionClass(position)">
        <v-tooltip left>
            <template #activator="{ on, attrs }">
                <v-avatar color="color1" :size="listLayout.value == 'big' ? 36 : 28" v-bind="attrs" v-on="on">
                    <span
                        class="text-color-white"
                        :style="{
                            fontSize: listLayout.value == 'big' ? '14px' : '12px',
                        }"
                    >
                        {{ item.tries }}
                    </span>
                </v-avatar>
            </template>
            <span>{{ $t('tries.nofTries') }}</span>
        </v-tooltip>
    </div>
</template>

<script>
    import { positionTypes } from '@/enums/cases.enums';
    import { getPositionClass } from '@/helpers/cases/cases.helper';

    export default {
        props: {
            item: {
                type: Object,
                default: () => {},
            },

            position: {
                type: String,
                default: positionTypes.LEFT,
                validator(value) {
                    // Shorthand for validator
                    return Object.values(positionTypes).includes(value);
                },
            },
        },
        computed: {
            listLayout() {
                return this.$store.state.Cases.listLayout;
            },
        },
        methods: {
            getPositionClass,
        },
    };
</script>

<style scoped>
    .text-color-white {
        color: white;
    }
</style>
<i18n lang="json">
{
    "en": {
        "tries": {
            "nofTries": "Number of attempts"
        }
    },
    "sv": {
        "tries": {
            "nofTries": "Antal försök"
        }
    }
}
</i18n>
