import { render, staticRenderFns } from "./SocialCaseComment.vue?vue&type=template&id=941d7c4e&scoped=true"
import script from "./SocialCaseComment.vue?vue&type=script&lang=js"
export * from "./SocialCaseComment.vue?vue&type=script&lang=js"
import style0 from "./SocialCaseComment.vue?vue&type=style&index=0&id=941d7c4e&prod&lang=scss"
import style1 from "./SocialCaseComment.vue?vue&type=style&index=1&id=941d7c4e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.96.1__ejs@3.1.10_hand_ebxcklwljnzhsqmemdellbdqly/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "941d7c4e",
  null
  
)

/* custom blocks */
import block0 from "./SocialCaseComment.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports