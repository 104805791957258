export function mapFilterPayload(filter, searchType, fixedFilterItems, brandsActive) {
    const brandIds = fixedFilterItems.brands.map((q) => q.id);

    const queues = brandsActive
        ? fixedFilterItems.queues.filter((q) => brandIds.includes(q.brand?.ID) || q.brand === null)
        : fixedFilterItems.queues;

    const newPayload = [];
    newPayload.page = filter.page;
    newPayload.items = filter.items;
    newPayload.queues = queues.map((q) => q.value).sort();
    newPayload.categories = fixedFilterItems.categories.map((q) => q.value).sort();
    newPayload.channels = fixedFilterItems.channels.map((q) => q.value).sort();

    newPayload.userIds = filter.users.map((q) => q.value).sort();
    newPayload.showUnansweredOnly = filter.showUnansweredOnly;
    newPayload.sortBy = filter.sortField;
    newPayload.direction = filter.sortDirection;
    newPayload.statuses = filter.statuses.map((q) => q.value).sort();
    newPayload.query = filter.searchQuery;

    if (newPayload.query === '') {
        newPayload.searchType = 1;
    } else {
        newPayload.searchType = searchType;
    }

    return newPayload;
}
