<template>
    <div>
        <v-tooltip top>
            <template #activator="{ on }">
                <v-img width="32" height="32" :src="getImage(integration)" v-on="on" />
            </template>
            <span>{{ $t('activeInExternalSystem', { v: capitilize(integration) }) }}</span>
        </v-tooltip>
    </div>
</template>
<script>
    import { getImage } from '@/utils/Integrations';

    export default {
        props: {
            integration: {
                type: [String, null],
                default: null,
            },
        },

        methods: {
            getImage,
            capitilize(str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            },
        },
    };
</script>
<style scoped lang="scss"></style>
<i18n lang="json">
{
    "en": {
        "activeInExternalSystem": "Handled in {v}"
    },
    "sv": {
        "activeInExternalSystem": "Hanteras i {v}"
    }
}
</i18n>
