<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<template>
    <div class="comments incoming">
        <div class="d-flex align-center">
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <div v-on="on">
                        <CommonAvatar :userId="item.userId" :size="24" />
                    </div>
                </template>
                <span>{{ item.userName }}</span>
            </v-tooltip>

            <section class="mx-2 d-flex">
                <div class="chip">{{ $t(item.comment) }}</div>
                <v-icon size="14" class="mx-1" color="var(--v-gray1-base)">mdi-arrow-right</v-icon>
                <div class="go-to-case" @click="goToCase(item)">{{ $t(item.reference) }}</div>
            </section>
        </div>
        <div class="comment-date">{{ formatDate(item.dateTime) }}</div>
    </div>
</template>
<script>
    import { format } from 'date-fns';
    import { mapState, mapActions } from 'vuex';
    import { getDay } from '@/utils/DateFormatter';

    export default {
        components: {
            CommonAvatar: () => import('@/components/Global/CommonAvatar.vue'),
        },

        props: {
            item: {
                type: Object,
                default: () => {},
            },
        },

        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
            }),
        },

        mounted() {
            const showSystem = this.userSettings?.cases?.attachConversationSettings?.attachSystemMessages?.active;

            if (this.userSettings.cases.attachConversationSettings?.attachConversation?.active && showSystem) {
                this.setAttachChecked({ commentId: this.item.id, value: true });
            }
        },

        methods: {
            ...mapActions({
                setAttachChecked: 'Comments/setAttachChecked',
                storeGoToCase: 'Cases/goToCase',
            }),
            chipStyle(value) {
                if (value === '') {
                    return {
                        backgroundColor: 'var(--v-gray5-base)',
                        color: 'var(--v-gray2-base)',
                    };
                }

                return '';
            },
            formatDate(dateTime) {
                if (!this.userSettings.cases.showDate.active) {
                    return getDay(dateTime);
                }

                return getDay(dateTime) + ' (' + format(new Date(dateTime), 'yyyy-MM-dd') + ')';
            },
            goToCase(item) {
                this.storeGoToCase({ caseId: item.reference });
            },
        },
    };
</script>
<style scoped lang="scss">
    .comments {
        display: flex;
        padding: 16px;
        border-radius: 12px;
        gap: 8px;
        font-size: 0.8rem;
        justify-self: flex-end;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

    .comment-date {
        display: flex;
        align-items: center;
        justify-self: end;
        color: var(--v-gray2-base);
        white-space: nowrap;
    }

    .chip {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        border: 1px solid #ccc;
        padding: 4px 6px;
        margin: 0px 4px;
        border-radius: 4px;
        color: var(--v-gray4-base);
    }

    .go-to-case {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        padding: 4px 8px;
        margin: 0px 4px;
        border-radius: 4px;
        background-color: var(--v-primary-base);
        color: white;
        border: 1px solid var(--v-primary-base);
        border-radius: 16px;
        cursor: pointer;

        &:hover {
            background-color: white;
            color: var(--v-primary-base);
        }
    }
</style>

<i18n lang="json">
{
    "en": {
        "goToCase": "Go to case",
        "subCaseCreatedFromCase": "Case created from case",
        "createdNewSubCase": "Created new subcase",
        "integrationTopicParentComment": "Subcase created from case topic",
        "integrationTopicChildComment": "Created from case topic"
    },
    "sv": {
        "goToCase": "Gå till ärende",
        "subCaseCreatedFromCase": "Skapat från ärende",
        "createdNewSubCase": "Vidarebefordrat som nytt underärende",
        "integrationTopicParentComment": "Underärende skapat från ämnesärende",
        "integrationTopicChildComment": "Skapat från ärendes ämnesrubrik"
    }
}
</i18n>
