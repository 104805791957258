<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<template>
    <div class="comments">
        <header class="comment-header comment-section">
            <div class="d-flex align-center">
                <section class="comment-avatar">
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <div v-on="on">
                                <v-img width="32" :src="getImage(item.source)" />
                            </div>
                        </template>
                        <span>{{ capitilizeWord(item.source || 'C1') }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <div v-on="on">
                                <CommonAvatar v-if="item.userId" :userId="item.userId" :size="32" />
                                <SystemAvatar v-else :size="32" />
                            </div>
                        </template>
                        <span>{{ item.userId ? item.userName : $t('comment.system') }}</span>
                    </v-tooltip>
                </section>
                <div>{{ item.subject ? formatSubject(item.subject) : $t('externalTopic') }}</div>
            </div>
            <section>
                <span class="comment-top-field comment-date text-truncate">{{ formatDate(item.dateTime) }}</span>
            </section>
        </header>

        <section class="comment-content">
            <div v-shadow-dom="item.comment" class="comment-inner-content"></div>
        </section>

        <section v-if="item.attachments.length" class="comment-attachment">
            <ReadMore :items="item.attachments">
                <template #default="{ item: attachment }">
                    <FilePreview
                        :file="attachment"
                        :cfg="config"
                        :miniature="userSettings && userSettings.cases.imagePreviewMiniature.active"
                        class="file-preview"
                    />
                </template>
            </ReadMore>
        </section>
    </div>
</template>
<script>
    import { getDay } from '@/utils/DateFormatter';
    import { format } from 'date-fns';
    import { mapState } from 'vuex';

    import config from '@/app/config';

    import FilePreview from '@/components/Cases/CaseComments/FilePreview.vue';
    import ReadMore from '@/components/Cases/CaseComments/ReadMore.vue';
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    import SystemAvatar from '@/components/Global/SystemAvatar.vue';

    import { getImage } from '@/utils/Integrations';

    export default {
        components: {
            FilePreview,
            ReadMore,
            // CommentSignature,
            CommonAvatar,
            SystemAvatar,
        },

        props: {
            item: {
                type: Object,
                default: () => {},
            },
        },

        data() {
            return {
                config,
            };
        },

        computed: {
            ...mapState({
                userSettings: (state) => state.System.userSettings,
            }),
        },

        methods: {
            getImage,
            capitilizeWord(word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            },
            formatSubject(subject) {
                const MAX_LENGTH = 300;
                const START_INDEX = 0;

                if (subject[0] === '$') {
                    return this.$t(subject.slice(1));
                }

                if (subject.length > MAX_LENGTH) {
                    return subject.slice(START_INDEX, MAX_LENGTH) + '...';
                }
                return subject;
            },

            formatDate(dateTime) {
                if (!this.userSettings.cases.showDate.active) {
                    return getDay(dateTime);
                }

                return getDay(dateTime) + ' (' + format(new Date(dateTime), 'yyyy-MM-dd') + ')';
            },
            getDay,
            format,
        },
    };
</script>
<style scoped lang="scss">
    .comment-top-field {
        font-size: 12px;
        gap: 4px;
        display: flex;
        justify-content: end;
    }

    .comments {
        padding: 32px;
        border-radius: 12px;
        width: 40%;
        max-width: 800px;
        gap: 4px;
        font-size: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }
    .comment-avatar {
        color: white;
        border: 0px solid var(--v-accent2-base);
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 40px;
        gap: 8px;
    }

    .comment-header {
        display: grid;
        grid-template-columns: auto 1fr;
        padding-bottom: 8px;
        gap: 8px;
    }

    .comment-date {
        justify-self: flex-end;
    }

    .comment-section {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .comment-content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 16px 0;
        font-size: 1rem;
        position: relative;
        box-sizing: border-box;
    }

    .comment-inner-content {
        position: relative;
    }
</style>

<i18n lang="json">
{
    "en": {
        "externalTopic": "External System",
        "externalQueueForwardBack": "External forward back to C1",
        "externalQueueForward": "External forward to external queue"
    },
    "sv": {
        "externalTopic": "Externt system",
        "externalQueueForwardBack": "Extern vidarebefordran tillbaka i C1",
        "externalQueueForward": "Extern vidarebefordran till externt kö"
    }
}
</i18n>
