import api from '@/api/app/app';

const toastClearTimeout = 3000;
let toastClearTimer = null;
let healthCheckInterval = null;

const appStates = {
    INIT: 'init',
    LOADING: 'loading',
    LOADED: 'loaded',
    ERROR: 'error',
};

// State object
const state = {
    appStates: Object.freeze(appStates),
    appState: appStates.INIT,
    isSocketReconnecting: false,
    showSipPhone: false,

    connection: {
        isDisconnected: false,
        isSocketDisconnected: false,
        healthCheckFailed: false,
        healthCheckTries: 0,
    },
};

// Getter functions
const getters = {};
// Actions
const actions = {
    async init({ dispatch, commit }) {
        commit('SET_APP_STATE', appStates.LOADING);
        await dispatch('Brands/getBrandsActive', null, { root: true });
        await dispatch('Brands/getAvailableBrandsByUser', null, { root: true });

        await dispatch('connectSocket');
        await dispatch('Admin/getSidebarContent', null, { root: true });
        const showSip = await dispatch('Sip.auth/init', null, { root: true });
        commit('SET_SHOW_SIP_PHONE', showSip);
        commit('SET_APP_STATE', appStates.LOADED);
        dispatch('startHealthCheck');
    },

    setIsDisconnected({ commit }, isDisconnected) {
        commit('SET_IS_DISCONNECTED', isDisconnected);
    },

    setIsSocketDisconnected({ commit }, isSocketDisconnected) {
        commit('SET_IS_SOCKET_DISCONNECTED', isSocketDisconnected);
    },

    setHeartBeatFailed({ commit }, heartBeatFailed) {
        commit('SET_HEALTH_CHECK_FAILED', heartBeatFailed);
    },

    connectSocket({ rootState }) {
        if (!rootState.Auth.accessToken) return;
        if (!this._vm.$socket) return;
        if (this._vm.$socket.connected) return;
        this._vm.$socket.open();
        this._vm.$socket.connect();
    },

    disconnectSocket() {
        if (!this._vm.$socket) return;
        this._vm.$socket.disconnect();
    },

    disconnect({ dispatch }) {
        dispatch('disconnectSocket');
    },

    setSocketReconnecting({ commit }, isReconnecting) {
        commit('SET_SOCKET_RECONNECTING', isReconnecting);
    },

    clearAllToastMessages() {
        clearTimeout(toastClearTimer);
        toastClearTimer = setTimeout(() => {
            this._vm.$toasted.clear();
        }, toastClearTimeout);
    },

    startHealthCheck({ dispatch }) {
        healthCheckInterval = setInterval(() => {
            dispatch('checkHealth');
        }, 5000);
    },

    async checkHealth({ rootState, dispatch }) {
        if (!rootState.Auth.isLoggedIn) {
            return;
        }
        const succeeded = await dispatch('getHealthCheck');

        if (succeeded) {
            // If healthCheckTries <= 0, use a 15-second interval
            clearInterval(healthCheckInterval);
            healthCheckInterval = setInterval(() => {
                dispatch('checkHealth');
            }, 15000);
            return;
        }
        // Continue with a 5-second interval
        clearInterval(healthCheckInterval);
        healthCheckInterval = setInterval(() => {
            dispatch('checkHealth');
        }, 5000);
    },

    async getHealthCheck({ state, commit }) {
        try {
            commit('SET_HEALT_CHECK_TRIES', state.connection.healthCheckTries + 1);
            await api.getHealthCheck();
            commit('SET_HEALTH_CHECK', true);
            commit('SET_HEALT_CHECK_TRIES', 0);
            return true;
        } catch (error) {
            commit('SET_HEALT_CHECK_TRIES', state.connection.healthCheckTries + 1);
        }
        if (state.connection.healthCheckTries > 1) {
            commit('SET_HEALTH_CHECK', false);
            return false;
        }
        return false;
    },
};
// Mutations
const mutations = {
    SET_APP_STATE(state, appState) {
        state.appState = appState;
    },
    SET_SOCKET_RECONNECTING(state, isReconnecting) {
        state.isSocketReconnecting = isReconnecting;
    },
    SET_SHOW_SIP_PHONE(state, showSipPhone) {
        state.showSipPhone = showSipPhone;
    },

    SET_IS_DISCONNECTED(state, isDisconnected) {
        state.connection.isDisconnected = isDisconnected;
    },

    SET_IS_SOCKET_DISCONNECTED(state, isSocketDisconnected) {
        state.connection.isSocketDisconnected = isSocketDisconnected;
    },

    SET_HEALTH_CHECK(state, health) {
        state.connection.healthCheckFailed = !health;
    },

    SET_HEALT_CHECK_TRIES(state, healthCheckTries) {
        state.connection.healthCheckTries = healthCheckTries;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
