<template>
    <main class="avatar-container">
        <CommonAvatar :key="userId" :size="evenSize" :userId="userId" />
        <div :style="presenceStyle">
            <PresenceIcon :size="iconSize" :presenceId="presenceId" />
        </div>
    </main>
</template>
<script>
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    import PresenceIcon from '@/components/Global/PresenceIcon.vue';

    export default {
        components: {
            CommonAvatar,
            PresenceIcon,
        },
        props: {
            userId: {
                type: Number,
                default: 0,
            },
            presenceId: {
                type: Number,
                default: 0,
            },
            size: {
                type: Number,
                default: 40,
            },
        },
        computed: {
            evenSize() {
                return this.makeEven(this.size);
            },
            iconSize() {
                return this.makeEven(this.size / 3);
            },
            offset() {
                return this.evenSize * 0.68;
            },
            presenceStyle() {
                return {
                    position: 'absolute',
                    top: `${this.offset}px`,
                    left: `${this.offset}px`,
                    width: `${this.iconSize}px`,
                    height: `${this.iconSize}px`,
                };
            },
        },
        methods: {
            makeEven(number) {
                const size = Math.round(number);
                if (size % 2 === 0) {
                    return size;
                }
                return size + 1;
            },
        },
    };
</script>
<style scoped lang="scss">
    .avatar-container {
        position: relative;
    }
</style>
<i18n lang="json">
{
    "en": {},
    "sv": {}
}
</i18n>
