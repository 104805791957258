export const casesHeaders = [
    {
        textTranslate: {
            sv: 'Typ',
            en: 'Type',
        },
        value: 'internalType',
        width: '100px',
        id: 'internaltype',
    },
    {
        textTranslate: {
            sv: 'Skapad',
            en: 'Created',
        },
        value: 'created',
        width: '100px',
        id: 'created',
    },
    {
        textTranslate: {
            sv: 'Datum',
            en: 'Date',
        },
        value: 'dateTime',
        width: '100px',
        id: 'datetime',
    },
    {
        textTranslate: {
            sv: 'Senast inkommande',
            en: 'Last Incoming',
        },
        value: 'lastIncoming',
        width: '150px',
        id: 'lastIncoming',
    },
    {
        textTranslate: {
            sv: 'Utfall',
            en: 'Outcome',
        },
        value: 'result',
        width: '100px',
        id: 'result',
    },
    {
        textTranslate: {
            sv: 'SLA',
            en: 'SLA',
        },
        value: 'sla',
        width: '150px',
        id: 'sla',
    },
    {
        textTranslate: {
            sv: 'Brand',
            en: 'Brand',
        },
        value: 'brand',
        width: '100px',
        id: 'brand',
    },
    {
        textTranslate: {
            sv: 'Kö',
            en: 'Queue',
        },
        value: 'group',
        width: '150px',
        id: 'group',
    },
    {
        textTranslate: {
            sv: 'Kategori',
            en: 'Category',
        },
        value: 'categoryId',
        width: '100px',
        id: 'category',
    },
    {
        textTranslate: {
            sv: 'Ämne',
            en: 'Topic',
        },
        value: 'topic',
        width: '150px',
        id: 'topic',
    },
    {
        textTranslate: {
            sv: 'Ärendenummer',
            en: 'Case ID',
        },
        value: 'caseId',
        width: '150px',
        id: 'caseId',
    },
    {
        textTranslate: {
            sv: 'Beskrivning',
            en: 'Description',
        },
        value: 'description',
        width: '200px',
        id: 'description',
    },
    {
        textTranslate: {
            sv: 'Bifogat',
            en: 'Attached',
        },
        value: 'attachment',
        width: '100px',
        id: 'attachment',
    },
    {
        textTranslate: {
            sv: 'Klientnamn',
            en: 'Client name',
        },
        value: 'clientName',
        width: '200px',
        id: 'client',
    },
    {
        textTranslate: {
            sv: 'Agentnamn',
            en: 'Agent name',
        },
        value: 'agentName',
        width: '100px',
        id: 'profilepicture',
    },
    {
        textTranslate: {
            sv: 'Status',
            en: 'Status',
        },
        value: 'status',
        width: '150px',
        id: 'status',
    },
    {
        textTranslate: {
            sv: 'Land',
            en: 'Country',
        },
        value: 'flag',
        width: '100px',
        id: 'flag',
    },
    {
        textTranslate: {
            sv: 'Senast Uppdaterad',
            en: 'Last Updated',
        },
        value: 'lastUpdated',
        width: '150px',
        id: 'lastUpdated',
    },
    {
        textTranslate: {
            sv: 'Försök',
            en: 'Attempts',
        },
        value: 'tries',
        width: '100px',
        id: 'tries',
    },
];

export const statusTypes = [
    {
        name: 'I kö',
        nameTranslate: {
            sv: 'I kö',
            en: 'In queue',
        },
        value: 'InQueue',
        icon: 'mdi-arrow-down',
        color: '#009382',
        sortField: 'LastUpdated',
        sortDirection: 'DESC',
        unsettable: true,
        isCreatable: true,
        selectable: false,
    },
    {
        name: 'Öppen',
        nameTranslate: {
            sv: 'Öppen',
            en: 'Open',
        },
        value: 'Open',
        icon: 'mdi-tray',
        color: '#cf73e0',
        sortField: 'LastUpdated',
        sortDirection: 'DESC',
        selectable: true,
    },
    {
        name: 'Pågår',
        nameTranslate: {
            sv: 'Pågår',
            en: 'Ongoing',
        },
        value: 'Ongoing',
        icon: 'mdi-tray-full',
        color: '#ef628e',
        sortField: 'LastUpdated',
        sortDirection: 'DESC',
        selectable: true,
    },
    {
        name: 'Stängd',
        nameTranslate: {
            sv: 'Stängd',
            en: 'Closed',
        },
        value: 'Closed',
        icon: 'mdi-tray-minus',
        color: '#3299b3',
        sortField: 'LastUpdated',
        sortDirection: 'DESC',
        selectable: true,
    },
    {
        name: 'Borttagen',
        nameTranslate: {
            sv: 'Borttagen',
            en: 'Deleted',
        },
        value: 'Deleted',
        icon: 'mdi-tray-remove',
        color: '#d0d0d0',
        sortField: 'LastUpdated',
        sortDirection: 'DESC',
        selectable: true,
    },
    {
        name: 'Bevakad',
        nameTranslate: {
            sv: 'Bevakad',
            en: 'Snoozed',
        },
        value: 'Snoozed',
        icon: 'mdi-timer-alert-outline',
        color: '#ffa500',
        sortField: 'LastUpdated',
        sortDirection: 'DESC',
        selectable: true,
    },
];

export const communicationTypes = [
    {
        value: 'email',
        icon: 'mdi-email',
        iconSelected: 'mdi-email',
        text: 'E-post',
        textTranslate: {
            sv: 'E-post',
            en: 'E-mail',
        },
        dialogId: 1,
    },
    {
        value: 'sms',
        icon: 'mdi-message',
        iconSelected: 'mdi-message',
        text: 'SMS',
        textTranslate: {
            sv: 'SMS',
            en: 'SMS',
        },
        dialogId: 3,
    },
    {
        value: 'call',
        icon: 'mdi-phone',
        iconSelected: 'mdi-phone',
        text: 'Samtal',
        textTranslate: {
            sv: 'Samtal',
            en: 'Call',
        },
        dialogId: 5,
    },
    {
        value: 'video',
        icon: 'mdi-video',
        iconSelected: 'mdi-video',
        text: 'Video',
        textTranslate: {
            sv: 'Video',
            en: 'Video',
        },
        dialogId: 6,
    },
];

export const templateCategories = [
    {
        categoryId: 3,
        name: 'Signatur',
        nameTranslate: {
            sv: 'Signatur',
            en: 'Signature',
        },
        value: 'signature',
    },
    {
        categoryId: 4,
        name: 'E-mail',
        nameTranslate: {
            sv: 'E-post',
            en: 'E-mail',
        },
        value: 'eMail',
    },
    {
        categoryId: 5,
        name: 'Sms',
        nameTranslate: {
            sv: 'SMS',
            en: 'SMS',
        },
        value: 'sms',
    },
    {
        categoryId: 6,
        name: 'Chat',
        nameTranslate: {
            sv: 'Chat',
            en: 'Chatt',
        },
        value: 'chatt',
    },
    {
        categoryId: 8,
        name: 'Sökmallar',
        nameTranslate: {
            sv: 'Sökmallar',
            en: 'Search templates',
        },
        value: 'searchTemplates',
    },
    {
        categoryId: 9,
        name: 'Bekräftelsemail',
        nameTranslate: {
            sv: 'Bekräftelsemail',
            en: 'Confirmation email',
        },
        value: 'searchTemplates',
    },
];

export const channelTypes = [
    {
        name: 'Samtal',
        nameTranslate: {
            sv: 'Samtal',
            en: 'Call',
        },
        value: 'call',
        icon: 'mdi-phone-in-talk',
        color: 'primary',
    },
    {
        name: 'Chatt',
        nameTranslate: {
            sv: 'Chatt',
            en: 'Chat',
        },
        value: 'chat',
        icon: 'mdi-message',
        color: 'primary',
    },
    {
        name: 'E-post',
        nameTranslate: {
            sv: 'E-post',
            en: 'E-mail',
        },
        value: 'email',
        icon: 'mdi-email',
        color: 'primary',
    },
    {
        name: 'Återuppringning',
        nameTranslate: {
            sv: 'Återuppringning',
            en: 'Callback',
        },
        value: 'callback',
        icon: 'mdi-phone-incoming',
        color: 'primary',
    },
    {
        name: 'SMS',
        nameTranslate: {
            sv: 'SMS',
            en: 'SMS',
        },
        value: 'sms',
        icon: 'mdi-cellphone-message',
        color: 'primary',
    },
    {
        name: 'SoMe',
        nameTranslate: {
            sv: 'SoMe',
            en: 'SoMe',
        },
        value: 'social',
        icon: 'mdi-cellphone-message',
        color: 'primary',
    },
    {
        name: 'Form',
        nameTranslate: {
            sv: 'Formulär',
            en: 'Form',
        },
        value: 'form',
        icon: 'mdi-form-select',
        color: 'primary',
    },
];
