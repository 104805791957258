<template>
    <v-dialog v-model="openState" width="500px">
        <v-card>
            <v-card-title>
                <span>{{ $t('externalLimeQueues') }}</span>
                <v-spacer />
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <ExternalQueuesList source="lime" @close="close" />
        </v-card>
    </v-dialog>
</template>
<script>
    import ExternalQueuesList from '@/components/Cases/Integrations/Queues/ExternalQueuesList.vue';

    export default {
        components: { ExternalQueuesList },
        data() {
            return {
                openState: false,
            };
        },

        methods: {
            open() {
                this.openState = true;
            },
            close() {
                this.openState = false;
            },
        },
    };
</script>
<style scoped lang="scss"></style>
<i18n lang="json">
{
    "en": {
        "externalLimeQueues": "External Lime Queues Assigner"
    },
    "sv": {
        "externalLimeQueues": "Tilldela externt i Lime"
    }
}
</i18n>
