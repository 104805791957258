module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "internalType": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
          "withSubcase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - Subcase"])}
        }
      },
      "sv": {
        "internalType": {
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okänd"])},
          "withSubcase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - Underärende"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"en":{"internalType":{"unknown":"Unknown","withSubcase":" - Subcase"}},"sv":{"internalType":{"unknown":"Okänd","withSubcase":" - Underärende"}}}')
  delete Component.options._Ctor
  
}
